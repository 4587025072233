import styled from "@emotion/styled"
import { useConsole } from "contexts/Console"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import Video, { cssVideo, cssContainer, cssPoster, cssFallback } from "components/media/VideoCLD"

import PageHeading from "./PageHeading"
import CTATxt from "./CTATxt"

const Header = styled.header`
  ${fullGrid}
  position: relative;
  justify-content: center;

  overflow: hidden;

  z-index: 0;

  &.multirows {
    grid-template-rows: min-content min-content 40px;

    ${getMediaQuery("m")} {
      grid-template-rows: min-content min-content 60px;
    }

    & hgroup {
      padding-block-start: 0;
      align-self: center;
    }
  }

  & video {
    position: absolute;
  }

  & .${cssContainer} {
    z-index: -1;
    grid-column: doc;
    grid-row: 1 / -1;

    .${cssVideo}, .${cssPoster}, .${cssFallback} {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .${cssVideo} {
      transform: translateY(var(--ypos)) scale(1.2);
    }
  }
`

export default function CoverVideo({ heading, heading_gradient, video_cld: video, text, video_amp_cta, className }) {
  const console = useConsole()

  const cl = text?.length && !text?.[0]?.link_404 ? " multirows" : ""

  return (
    <Header className={`${className}${cl}`}>
      <PageHeading
        animate
        heading={heading}
        heading_gradient={heading_gradient}
        link={text?.[0]?.link_404}
        video={video}
        video_amp={video_amp_cta?.length ? video_amp_cta[0].video_player_cld : null}
        isLink={text?.length && !text?.[0]?.link_404}
      />
      {video && <Video autoPlay loop noButton animate {...video} sizes='100vw' />}
      {text?.length && !text?.[0]?.link_404 ? <CTATxt blocks={text} /> : null}
    </Header>
  )
}
