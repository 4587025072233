import { breakpoint as breakpoints } from "css/breakpoints"

import { WIDTHS } from "components/media/ImageCLD"

export const MOB = 0
export const DESK = 1
const MAP = ["portrait", "landscape"]

export default class SequenceLoader extends Array {
  static async #load(path, src, i, console, origin, offset) {
    const img = new Image()
    const frame = i.toString().padStart(4, "0")

    const loadPromise = new Promise((resolve, reject) => {
      img.onload = resolve
      img.onerror = reject
    })

    img.decoding = "async"
    img.sizes = `(min-width: ${breakpoints.m}) 100vw, 100vw`
    img.srcset = WIDTHS.reduce((acc, width) => acc.concat([`${`${origin}${path.replace("%width%", width)}${src}${frame}`} ${width}w`]), []).join(", ")
    img.setAttribute("x", offset.x_offset)
    img.setAttribute("y", offset.y_offset)
    // console.silly("•> SequenceLoader#load(%s, %s)", path, i, img)
    try {
      await loadPromise
      // console.verbose("•> SequenceLoader(%s)#load(%i):success => %s", path, i, img.currentSrc)
      return img
    } catch (err) {
      // console.verbose("•> SequenceLoader(%rmc)#load(%s):failed", path, i)
      console.error(err)
      return null
    }
  }

  constructor({ number_of_frame, env, first_frame, start_frame, axis, console, origin_portrait, origin_landscape, ...rest }) {
    super()

    this[MOB] = []
    this[DESK] = []
    this.#nbf = number_of_frame
    this.#axis = axis
    this.#startFrame = start_frame
    this.#console = console
    this.#origin = `https://${env.cloudinary.secureDistribution}`
    this.#path = "/image/upload/t_v7/c_limit,w_%width%/q_auto:eco,f_auto/v1/"
    this.#origin_offset = [origin_portrait, origin_landscape]
    this.#sources = first_frame
      .map(({ metadata, width, height, public_id }) => ({ d: metadata?.v7_type?.[0] === "portrait" ? 0 : 1, width, height, srcTemp: public_id.slice(0, -4) }))
      .sort((a, b) => a.d - b.d)
    this.#loadPromises = [null, null]
  }

  #nbf
  get nbf() {
    return this.#nbf
  }

  #axis
  get axis() {
    return this.#axis
  }

  #startFrame
  get startFrame() {
    return this.#startFrame
  }

  #console
  get console() {
    return this.#console
  }

  #path
  get path() {
    return this.#path
  }

  #origin
  get origin() {
    return this.#origin
  }

  #srcTemp
  get srcTemp() {
    return this.#srcTemp
  }

  #sources
  get sources() {
    return this.#sources
  }

  #origin_offset
  get origin_offset() {
    return this.#origin_offset
  }

  #loadPromises
  loadFrames(d) {
    if (!this.#loadPromises[d]) {
      for (let i = 0; i < this.#nbf; i++) {
        if (!this[d][i]) {
          this[d][i] = SequenceLoader.#load(this.#path, this.#sources[d].srcTemp, i, this.#console, this.#origin, this.#origin_offset[d])
        }
      }
      this.#loadPromises[d] = Promise.all(this[d])
    }
    return this.#loadPromises[d]
  }

  // async loadFirstFrame() {
  //   return SequenceLoader.#load(this.#path, this.#srcTemp, this.#startFrame, this.#console, this.#origin)
  // }
}
