import { useEffect, useState, useRef, memo, useLayoutEffect, useCallback } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useTransform } from "framer-motion"
import { useFrameAnimation, STEPS, STEPS_LENGTH, FEATURES } from "./FrameAnimation"
import { rafDelay, mvSubscribe } from "./utils"
import { headline70, headline100, body30, body50, body70, bold, normal, legend100, visuallyHidden } from "css/text"
import { buttonReset, opaqueWhiteCfg } from "css/buttons"
import { useDictionary } from "contexts/Dictionary"
import { useFocusManager } from "react-aria"
import camelCase from "./../../utils/camelCase"
import { INSTALL_ECO_TRANSITION } from "./constants"
import CfgFeatures from "./features/Player"
import IButton from "components/quickviews/IButton"

const SWAP_IN = css`
  transition: opacity 400ms cubic-bezier(0.61, 1, 0.88, 1) 40ms, visibility 0ms 0ms;
  opacity: 1;
  visibility: inherit;
`

const SWAP_OUT = css`
  opacity: 0;
  visibility: hidden;
  //  will-change: opacity, visibility;
`

const Links = styled.section`
  position: relative;
  overflow: auto;
  width: 100vw;
  //  display: grid;
  //  pointer-events: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.cfg-dialfilters-hidden {
    visibility: hidden;
  }
`

const Main = styled.section`
  user-select: none;
  position: relative;
  z-index: 21;
  grid-row: 1;
  grid-column: 1/-1;
  height: fit-content;
  justify-self: center;
  align-self: end;
  display: grid;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  margin-block-end: 80px;
  //  pointer-events: none;
  color: white;
  flex: 0;
  .dark-theme & {
    color: rgb(var(--pure-white));
  }
  .light-theme & {
    color: rgb(var(--light-black));
  }

  opacity: 0;
  &.wv_reveal {
    ${SWAP_IN}
    transition: ${INSTALL_ECO_TRANSITION};
  }

  & > div {
    position: relative;
    //    pointer-events: none;
    padding: 0 var(--outer-margin) 0.5rem;
    box-sizing: border-box;

    & > h2 {
      ${body70}
      ${bold}

      & > p {
        display: inline-block;
        padding-inline: 0 10px;
      }
    }

    & > button {
      position: relative;
      //      pointer-events: auto;
      cursor: pointer;
    }

    ${SWAP_OUT}
    &.wv_reveal {
      ${SWAP_IN}
    }
    .cfg-install & {
      transition: ${INSTALL_ECO_TRANSITION};
    }
  }

  & > section {
    mask-image: linear-gradient(90deg, rgb(0 0 0 / 0.2), rgb(0 0 0 / 1) 5%, rgb(0 0 0 / 1) 95%, rgb(0 0 0 / 0.2) 100%);

    &.wv_reveal {
      fieldset {
        ${SWAP_IN}
      }
      .cfg-install & {
        transition: ${INSTALL_ECO_TRANSITION};
      }
    }
  }

  & fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    appearance: none;
    display: flex;
    flex-direction: row;
    &:before,
    &:after {
      content: "";
      flex: 0 0 calc(var(--outer-margin) - 20px);
    }

    ${SWAP_OUT}

    & legend {
      ${visuallyHidden}
    }

    & div {
      position: relative;
      padding-block: 5px;
      //        overflow: hidden;
      label {
        ${bold}
        padding-block: 3px;
        padding-inline: 20px;
        white-space: nowrap;
        cursor: pointer;
        opacity: 0.5;
        transition: color 0.3s, opacity 0.3s;
        .dark-theme & {
          color: rgb(var(--pure-white));
        }
        .light-theme & {
          color: rgb(var(--light-black));
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            opacity: 1;
          }
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          margin-block-start: -2px;
          margin-inline-start: 8px;
          width: 6px;
          height: 6px;
          border-radius: 8px;
          background: rgb(var(--pure-white));
          opacity: 0;
          transition: opacity 0.5s;
          html.prefers-contrast & {
            background: black !important;
          }
        }
      }
      input {
        position: absolute;
        top: -10px;
        left: 0px;
        opacity: 0;

        &:checked + label {
          opacity: 1;
          &::after {
            opacity: 1;
          }
        }

        &.focus-visible + label {
          outline: 2px solid rgb(var(--focus, 0 255 255) / 1);
          outline-offset: 0px !important;
        }
      }
    }
    & button {
      position: relative;
      ${buttonReset}
      font-size: 16px;
      ${bold}
      padding-block: 6px;
      padding-inline: 20px;
      white-space: nowrap;
      cursor: pointer;
      opacity: 0.5;
      transition: color 0.3s, opacity 0.3s;
      .dark-theme & {
        color: rgb(var(--pure-white));
      }
      .light-theme & {
        color: rgb(var(--light-black));
      }
      &.focus-visible {
        opacity: 1;
        outline-offset: 0px !important;
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          opacity: 1;
        }
      }
      &[aria-current="true"] {
        cursor: default !important;
        opacity: 1;
        &::after {
          opacity: 1;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        margin-block-start: -2px;
        margin-inline-start: 8px;
        width: 6px;
        height: 6px;
        border-radius: 8px;
        .dark-theme & {
          background: rgb(var(--pure-white));
        }
        .light-theme & {
          background: rgb(var(--green));
        }
        opacity: 0;
        transition: opacity 0.5s;
      }
    }
  }
`

function novars(v) {
  return !v || v.indexOf(",") < 0
}

function FeatureButton({ Ctx, ariaDescribedby }) {
  const { getModel, model, step, modellist } = useFrameAnimation(Ctx)
  const [active, setActive] = useState(false)

  function onStep(v) {
    setTimeout(() => setActive(v > 2 && v < STEPS.length - 1), 200)
  }
  useLayoutEffect(() => mvSubscribe(step, onStep), [])

  const cur = useTransform([modellist, model], ([l, rmc]) => (l && rmc ? l.split(",").indexOf(rmc) : null))

  function onApply(e) {
    const rmcToApply = modellist.get().split(",")[e.detail.index]
    model.set(rmcToApply)
  }
  useLayoutEffect(() => {
    document.addEventListener("quickviews:apply:configurator", onApply)
    return () => document.removeEventListener("quickviews:apply:configurator", onApply)
  }, [])

  return active ? (
    <IButton
      listObj={modellist
        .get()
        .split(",")
        .map(rmc => getModel(rmc).quickViews)}
      feature={STEPS[step.get()]}
      cur={cur}
      step={step}
      ariaDescribedby={ariaDescribedby}
    />
  ) : null
}

const CfgStepTitle = memo(({ Ctx }) => {
  const { getModel, model, step, swapping, settled } = useFrameAnimation(Ctx)
  const [label, setLabel] = useState(null)
  const rfmain = useRef()
  const dictionary = useDictionary()
  const _label = useTransform([model, step], update)

  function update([model, step]) {
    if (!model) return null
    const m = getModel(model)
    if (!m) return null
    step = step || m.next[0]
    const category = STEPS[step]
    let title = dictionary[camelCase(`configurator-title-${category}`)]("<span>", "</span>")
    const vars = getModel(model)?.[`variations_${category}`]
    if (novars(vars)) title = null
    return title
  }

  function render(v) {
    if (v) return display(false)
    const label = _label.get()
    setLabel(label)
    rafDelay(() => display(!!label))
  }
  useLayoutEffect(() => mvSubscribe(swapping, render, false), [])

  function onSettled(v) {
    if (v < 0) return
    render(false)
  }
  useLayoutEffect(() => mvSubscribe(settled, onSettled, false), [])

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function kill() {
    setLabel(null)
  }

  useEffect(() => kill, [])

  return (
    <div ref={rfmain} className='dark-theme'>
      <h2>
        <p dangerouslySetInnerHTML={{ __html: label }} id='optionName' />
        <FeatureButton Ctx={Ctx} ariaDescribedby='optionName' />
      </h2>
    </div>
  )
})

const CfgStepLegend = memo(({ Ctx }) => {
  const { getModel, model, step, swapping, settled } = useFrameAnimation(Ctx)
  const [label, setLabel] = useState(null)
  const rfmain = useRef()
  const dictionary = useDictionary()
  const _label = useTransform([model, step], update)

  function update([model, step]) {
    if (!model) return null
    const m = getModel(model)
    if (!m) return null
    step = step || m.next[0]
    const category = STEPS[step]
    let title = dictionary[camelCase(`configurator-title-${category}`)]("<span>", "</span>")
    const vars = getModel(model)?.[`variations_${category}`]
    if (novars(vars)) title = null
    return title
  }

  function render(v) {
    if (v) return
    const label = _label.get()
    setLabel(label)
  }
  useLayoutEffect(() => mvSubscribe(swapping, render, false), [])

  function onSettled(v) {
    if (v < 0) return
    render(false)
  }
  useLayoutEffect(() => mvSubscribe(settled, onSettled, false), [])

  function kill() {
    setLabel(null)
  }

  useEffect(() => kill, [])

  return <legend dangerouslySetInnerHTML={{ __html: label }} />
})

const CfgStepLink = memo(({ Ctx, rmc, label, index, step }) => {
  const { model, loading, fromui } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  const [chkd, setChkd] = useState(rmc === model.get())

  function onChange(e) {
    //    fromui.set(true)
    loading.set(true)
    setTimeout(() => model.set(rmc), 100)
  }

  function onModel(v) {
    setChkd(rmc === v)
  }
  useLayoutEffect(() => mvSubscribe(model, onModel), [])

  return (
    <>
      <input
        ref={rfmain}
        type='radio'
        id={`cfg-step-radio-${index}`}
        name={`cfg-step-${step}`}
        value={rmc}
        checked={rmc === model.get()}
        onChange={onChange}
        tabIndex={chkd ? 0 : -1}
      />
      <label htmlFor={`cfg-step-radio-${index}`}>{label}</label>
    </>
  )
})

const CfgStepFilter = memo(({ Ctx, uid, label, index }) => {
  const { dfilter, fromui } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  const [chkd, setChkd] = useState(uid === (dfilter.get() || "all"))

  function onChange(e) {
    //    fromui.set(true)
    dfilter.set(uid)
  }

  function onDfilter(v) {
    setChkd(uid === (v || "all"))
  }
  useLayoutEffect(() => mvSubscribe(dfilter, onDfilter), [])

  return (
    <>
      <input
        ref={rfmain}
        type='radio'
        id={`cfg-step-radio-${index}`}
        name={`cfg-step-dial`}
        value={uid}
        checked={uid === (dfilter.get() || "all")}
        onChange={onChange}
        tabIndex={chkd ? 0 : -1}
      />
      <label htmlFor={`cfg-step-radio-${index}`}>{label}</label>
    </>
  )
})

const CfgStepLinks = memo(({ Ctx }) => {
  //console.log("reload CfgStepLinks")
  const { model, getModel, step, modellist, reset, lastStep, swapping, touchEnabled, settled, feature } = useFrameAnimation(Ctx)
  const [items, setItems] = useState([])
  //  const rfstack = useRef([])
  const rfmain = useRef()
  const focusManager = useFocusManager()

  function update() {
    let stepindex = step.get()
    if (lastStep() || !modellist.get() || stepindex < 0 /* || stepindex >= STEPS_LENGTH - 1*/) return setItems(null)
    const m = getModel()
    if (!m) return
    stepindex = stepindex || m.next[0]
    let _step = STEPS[stepindex]
    setItems(
      stepindex < STEPS_LENGTH
        ? modellist
            .get()
            .split(",")
            .map(getModel)
            .map((v, i) => ({
              rmc: v.rmc,
              step: _step,
              index: i,
              label: v[_step],
            }))
        : null
    )
  }

  function render(v) {
    if (v || lastStep()) return display(false)
    rafDelay(() => display(true))
  }

  function focusActive() {
    const { current: main } = rfmain
    const R = main?.getBoundingClientRect()
    const el = main?.querySelector("input[tabIndex='0']")
    if (!el || !R) return
    const r = el?.parentNode?.getBoundingClientRect()
    if (!r) return
    const x = main.scrollLeft
    const offset = 20
    if (r.left < R.left) main.scrollLeft += r.left - R.left - offset
    else if (r.right > R.right) main.scrollLeft += r.right - R.right + offset
  }

  function display(state) {
    //        console.log("fixit display", state)
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
    rfmain.current?.setAttribute("aria-hidden", !state)
    if (!state || step.get() === STEPS_LENGTH) return
    focusActive()
    if (!touchEnabled.current)
      setTimeout(() => {
        focusManager.focusNext({ from: rfmain.current, wrap: true, tabbable: true })
      }, 40)
  }

  function onFeature(v) {
    if (v) return
    rafDelay(() => display(true))
  }

  useEffect(() => mvSubscribe(step, update, false), [])
  useEffect(() => mvSubscribe(modellist, update, false), [])
  useEffect(() => mvSubscribe(feature, onFeature, false), [])
  useEffect(() => mvSubscribe(swapping, render, false), [])

  function onSettled(v) {
    if (v < 0) return
    render(false)
  }
  useLayoutEffect(() => mvSubscribe(settled, onSettled, false), [])

  function kill() {
    setItems(null)
  }
  useEffect(() => kill, [])

  return (
    <Links ref={rfmain}>
      <fieldset>
        <CfgStepLegend Ctx={Ctx} />
        {items?.map(item => (
          <div key={item.rmc}>
            <CfgStepLink Ctx={Ctx} {...item} />
          </div>
        ))}
      </fieldset>
    </Links>
  )
})

const CfgStepFilters = memo(({ Ctx }) => {
  //console.log("reload CfgStepFilters")
  const { step, modellist, getModel, reset, lastStep, swapping, catalog, dfilter, dialfilters, touchEnabled } = useFrameAnimation(Ctx)
  const [items, setItems] = useState(null)
  //  const rfstack = useRef([])
  const rfmain = useRef()
  const focusManager = useFocusManager()
  //  const FILTERS = ["all", "light", "coloured", "dark", "gem-set"]
  const rfreload = useRef(false)

  function update() {
    if (!lastStep()) {
      setItems(null)
      dfilter.set(null)
      return
    }
  }

  function render(v) {
    if (v || !lastStep()) return display(false)
    rafDelay(() => display(true))
  }

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
    rfmain.current?.setAttribute("aria-hidden", !state)
    if (!state) return
    const R = rfmain.current?.getBoundingClientRect()
    const el = rfmain.current?.querySelector("input[tabIndex='0']")
    if (!el) return
    const r = el?.parentNode?.getBoundingClientRect()
    if (R && r && r.right > R.right) rfmain.current.scrollLeft = r.right - R.right + 20
    if (!touchEnabled.current)
      setTimeout(() => {
        focusManager.focusNext({ from: rfmain.current, wrap: true, tabbable: true })
      }, 40)
  }

  function onModellist(v) {
    if (!v || !lastStep() || v?.split(",")?.length < 2) return
    let remain = [
      ...new Set(
        v
          .split(",")
          .map(getModel)
          .reduce((a, v) => a.concat(v.dial_filter), [])
      ),
    ]
    const FILTERS = dialfilters.current
    remain = [FILTERS[0]].concat(FILTERS.filter(v => remain.includes(v.value)))
    rfmain.current?.classList[remain.length <= 2 ? "add" : "remove"]("cfg-dialfilters-hidden")

    setItems(
      remain.map((v, i) => ({
        index: i,
        uid: v.value,
        label: v.label,
      }))
    )
  }

  useEffect(() => mvSubscribe(step, update, false), [])
  useEffect(() => mvSubscribe(modellist, onModellist, false), [])
  useEffect(() => mvSubscribe(swapping, render, false), [])

  function kill() {
    setItems(null)
  }
  useEffect(() => kill, [])

  return (
    <Links ref={rfmain}>
      <fieldset>
        <CfgStepLegend Ctx={Ctx} />
        {items?.map(item => (
          <div key={item.uid}>
            <CfgStepFilter Ctx={Ctx} {...item} />
          </div>
        ))}
      </fieldset>
    </Links>
  )
})

export const CfgSteps = memo(({ Ctx }) => {
  //console.log("reload CfgSteps")
  const { settled } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function onSettled(v) {
    if (v > 0) display(true)
  }
  useLayoutEffect(() => mvSubscribe(settled, onSettled), [])

  return (
    <Main ref={rfmain}>
      <CfgStepTitle Ctx={Ctx} />
      <CfgStepLinks Ctx={Ctx} />
      <CfgStepFilters Ctx={Ctx} />
      <CfgFeatures Ctx={Ctx} startX='0' startY='100' />
    </Main>
  )
})
