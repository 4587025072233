import styled from "@emotion/styled"
import { css } from "@emotion/css"
import { motion } from "framer-motion"
import getMediaQuery from "css/breakpoints"
import parse from "html-react-parser"
import { quote100, legend110, body50 } from "css/text"
import { buttonFilled, opaqueGreen } from "css/buttons"
import PopinButton from "components/popin-edito/PopinButton"

const TextDiv = styled(motion.div)`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  width: 100%;
  max-width: 600px;
  pointer-events: all;
  align-items: center;
  text-align: center;
  user-select: none;

  ${getMediaQuery("m", { max: true })} {
    width: 80vw;
    max-width: 300px;
    align-items: center;
    text-align: center;
  }
`
const TextGroup = css`
  span {
    display: block;
  }
`

const DiciplineKicker = css`
  ${legend110}
  font-weight: 700;
  margin-bottom: 18px;
  pointer-events: none;
  ${getMediaQuery("m", { max: true })} {
    margin-bottom: 10px;
  }
`
export const DiciplineTitle = css`
  ${quote100}
  margin-bottom: 26px;
  font-family: var(--quote-font-family);
  font-style: var(--quote-font-style);
  font-weight: var(--quote-font-weight);
  pointer-events: none;
  ${getMediaQuery("m", { max: true })} {
    margin-bottom: 14px;
    line-height: 1.3;
  }
  line-height: 1.1;
  text-wrap: pretty;
`
const P = styled.p`
  ${body50}
  ${getMediaQuery("m", { max: true })} {
    font-size: 1rem;
  }
  pointer-events: none;
  text-wrap: pretty;
  font-weight: light;
  color: ${({ color }) => color};
`
const PopinBtnWrapper = styled.div`
  > button {
    ${opaqueGreen}
  }
  margin-top: 20px;
  user-select: none;
`

const HubText = props => {
  const { disciplineText, textControls, popin } = props
  const { kicker, title, subtitle } = disciplineText.heading
  const { block } = disciplineText.paragraph
  const isAll = disciplineText.slug === ""

  return (
    <TextDiv isAll={isAll} initial={{ opacity: 0 }} animate={textControls} exit={{ opacity: 0 }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {isAll ? (
          <h1 className={TextGroup}>
            <span className={DiciplineKicker} style={{ color: disciplineText.color }}>
              {parse(kicker)}
            </span>
            <span className={DiciplineTitle} style={{ color: disciplineText.color }}>
              {parse(title)}
            </span>
          </h1>
        ) : kicker ? (
          <>
            <h1 className={DiciplineKicker} style={{ color: disciplineText.color }}>
              {parse(kicker)}
            </h1>
            <h2 className={DiciplineTitle} style={{ color: disciplineText.color }}>
              {parse(title)}
            </h2>
          </>
        ) : (
          <h1 className={DiciplineTitle} style={{ color: disciplineText.color }}>
            {parse(title)}
          </h1>
        )}
      </div>
      {block.map((p, index) => {
        return (
          <P color={disciplineText.color} key={`${disciplineText.slug}-${index}`}>
            {parse(p.paragraph)}
          </P>
        )
      })}
      {isAll && (
        <PopinBtnWrapper>
          <PopinButton {...popin} isHubGrid={true} />
        </PopinBtnWrapper>
      )}
    </TextDiv>
  )
}

export default HubText
