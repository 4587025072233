import styled from "@emotion/styled"
import { useLink } from "@react-aria/link"
import { useRef } from "react"

import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"
import { useNavigation } from "contexts/Navigation"

import { waTriggerEvent } from "components/analytics/DigitalDataLayer"
import { Icon } from "components/icon/Icon"

import { usePage } from "contexts/Page"
import * as button from "css/buttons"
import { legend100, normal } from "css/text"

const LinkStyled = styled.a`
  /* Types */
  &.text {
    ${button.buttonText}
  }
  &.filled {
    ${button.buttonFilled}
  }
  &.icon {
    ${button.buttonIcon}
  }
  &.inline {
    ${button.buttonInline}
  }

  /* Styles */
  &.green {
    ${button.green}
  }
  &.opaque-green {
    ${button.opaqueGreen}
  }
  &.opaque-white {
    ${button.opaqueWhite}
  }
  &.translucent-dark {
    ${button.translucentDark}
  }
  &.translucent-light {
    ${button.translucentLight}
  }

  /* Sizes */
  &.small {
    ${legend100}
    padding-inline: 1.5625rem;
  }
  &.smallnormal {
    ${legend100}
    ${normal}
  }
`

export default function Link(props) {
  const {
    tabIndex,
    aria_hidden,
    aria_label,
    aria_describedby,
    children,
    className: c,
    disabled,
    external,
    href,
    icon,
    iconColor,
    onClick,
    style,
    download,
    download_position_name,
    outlink_name: outlinkName,
  } = props
  const page = usePage()

  const console = useConsole()
  const navigation = useNavigation()
  const rlink = useRef()
  const env = useEnv()
  const { linkProps } = useLink(
    {
      isDisabled: disabled,
      ...props,
    },
    rlink
  )

  const navigate = e => {
    e.preventDefault()
    navigation.navigate(e.currentTarget.getAttribute("href"))
  }

  const DEFAULT = "inline green"

  const i = icon ?? "chevron"
  const className = [
    c ?? style ?? DEFAULT,
    (i === "chevron" || i === "externalLink") && "reverseIcon",
    disabled && "disabled",
    (!href || href === "") && !external && "fake-link",
  ]
    .filter(Boolean)
    .join(" ")

  const isInline = className => className && className.includes("inline")

  console.verbose("Link(%o)", props)

  // Should we remove this?
  if (download) {
    return (
      <LinkStyled
        {...linkProps}
        as={"a"}
        tabIndex={tabIndex}
        aria-hidden={aria_hidden}
        aria-label={aria_label}
        aria-describedby={aria_describedby}
        className={className}
        href={download}
        download
        target='_blank'
        ref={rlink}
      >
        {icon && icon !== "none" && <Icon type={icon ?? "chevron"} fill={iconColor} />}
        {children}
      </LinkStyled>
    )
  }

  if (external) {
    // Replace .org global domain w/ CN domain when applicable
    // Add origin on relative paths (ie. PDFs etc.)
    const href = new URL(external.replaceAll(env.orgGlobalDomain, env.org), `https://${env.cloudinary.secureDistribution}`)

    const triggerEvent = () => {
      if (icon === "download") {
        waTriggerEvent({
          eventName: "download",
          eventType: "Click",
          downloadInfo: {
            position: download_position_name || "",
            type: "pdf",
            fileName: href.href,
            size: "",
          },
        })
      } else {
        waTriggerEvent({
          eventName: "outlink",
          eventType: "Click",
          outlinkInfo: {
            linkURL: href.href,
            linkName: outlinkName,
          },
        })
      }
    }

    return (
      <LinkStyled
        {...linkProps}
        aria-hidden={aria_hidden}
        aria-label={aria_label}
        aria-describedby={aria_describedby}
        as={external ? "a" : "span"}
        className={className}
        href={external ? href : null}
        onClick={onClick || triggerEvent}
        ref={rlink}
        rel='noopener noreferrer'
        tabIndex={tabIndex}
        target='_blank'
      >
        {icon !== "none" && <Icon type={icon ?? "externalLink"} fill={iconColor} />}
        {children}
      </LinkStyled>
    )
  }

  return (
    <LinkStyled
      {...linkProps}
      aria-hidden={aria_hidden}
      aria-label={aria_label}
      aria-describedby={aria_describedby}
      as={href ? "a" : "span"}
      className={className}
      href={href ? navigation.localize(href) : null}
      onClick={onClick || navigate}
      ref={rlink}
      tabIndex={tabIndex}
    >
      {!isInline(className) && icon !== "none" && <Icon type={icon ?? "chevron"} fill={iconColor} />}
      {children}
      {isInline(className) && icon !== "none" && <Icon type={icon ?? "chevron"} fill={iconColor} />}
    </LinkStyled>
  )
}
