import styled from "@emotion/styled"
import { motion } from "framer-motion"

import getMediaQuery from "css/breakpoints"
import { headline100, surtitle100 } from "css/text"
import { contrastMode } from "css/a11y"

import { kickerCN, subtitleCN } from "components/page-heading/style"
import Link from "components/link/Link"
import VideoLauncher from "components/video-launcher/VideoLauncher"

const Hgroup = styled(motion.hgroup)`
  position: relative;
  grid-column: col 1 / span 6;
  grid-row: 1;
  height: min-content;
  align-self: start;
  text-align: center;
  z-index: 1;
  margin-block-end: -0.8rem;
  padding-block-start: 15vh;

  .multirows & {
    align-self: center;
    padding-block-start: 20vh;
  }

  &.hgroupVideo {
    padding-block-start: 0;
    align-self: center;
    .multirows & {
      padding-block-start: 15vh;
    }
  }

  &.coverWithLink {
    align-self: start !important;
    padding-block-start: 20vh !important;
  }

  ${getMediaQuery("m")} {
    align-self: center;
    grid-column: col 2 / span 10;
  }
  ${getMediaQuery("l")} {
    align-self: start;
    grid-column: col 3 / span 8;
  }

  & h1 {
    ${headline100}
    grid-row: mid-line / title;
    padding-block-end: 0.8rem;
  }

  & .${kickerCN} {
    ${surtitle100}
    grid-row: kicker / mid-line;
  }

  & .${subtitleCN} {
    ${surtitle100}
    grid-row: subtitle / mid-line;
  }

  ${contrastMode}

  @media (prefers-contrast: no-preference) {
    &.gradient {
      background: var(--gradient);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  > a,
  > button {
    margin-block-start: 1.075rem;
  }
`

export default function PageHeading({ heading, heading_gradient, link, video_amp, video, isLink, isRftc }) {
  const { kicker, title, subtitle } = heading
  const colors = heading_gradient?.colors ? heading_gradient?.colors.map(({ color, opacity }) => `rgb(${color} / ${opacity})`) : []
  const gradient = colors.length ? `linear-gradient(${heading_gradient.angle ? `${heading_gradient.angle}deg, ` : ""}${colors})` : ""

  return (
    <Hgroup className={`${gradient ? "gradient" : ""} ${video ? "hgroupVideo" : ""} ${isLink ? "coverWithLink" : ""}`} style={{ "--gradient": gradient }}>
      {isRftc ? (
        <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 207.1 224.8'>
          <path
            class='st0'
            d='M62.8,164.5L19.3,71.4c4.2-2.2,7.1-6.6,7.1-11.7c0-7.3-5.9-13.2-13.2-13.2S0,52.4,0,59.7s5.9,13.2,13.2,13.2
	s2.2-0.2,3.3-0.4L54,199.8l0,0c4.2,14.2,24.9,25,49.8,25s45.6-10.8,49.8-25l0,0L191,72.6c0.9,0.2,1.9,0.3,2.9,0.3
	c7.3,0,13.2-5.9,13.2-13.2s-5.9-13.2-13.2-13.2s-13.2,5.9-13.2,13.2s3.1,9.7,7.5,11.9l-43.4,93l7-125.3h0.3
	c7.3,0,13.2-5.9,13.2-13.2s-5.9-13.2-13.2-13.2s-13.2,5.9-13.2,13.2s4,11,9.5,12.6l-28.9,122.5l-13.9-135c6.4-0.9,11.3-6.4,11.3-13
	S111,0,103.7,0S90.5,5.9,90.5,13.2s5,12.2,11.4,13L88,161L59.2,38.8c5.5-1.6,9.6-6.6,9.6-12.7s-5.9-13.2-13.2-13.2
	s-13.2,5.9-13.2,13.2s5.9,13.2,13.2,13.2h0.2L62.8,164.5L62.8,164.5L62.8,164.5z M103.6,189c22.8,0,41.3,5.8,41.3,13
	s-18.5,13-41.3,13s-41.3-5.8-41.3-13S80.8,189,103.6,189'
          />
        </svg>
      ) : null}
      {kicker ? <motion.p className={kickerCN}>{kicker}</motion.p> : null}
      {title ? <h1 dangerouslySetInnerHTML={{ __html: title }} /> : null}
      {subtitle ? <p className={subtitleCN}>{subtitle}</p> : null}
      {link?.href ? <Link {...link}>{link?.label}</Link> : video_amp ? <VideoLauncher video_player_cld={video_amp} /> : null}
    </Hgroup>
  )
}
