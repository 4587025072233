import { useState } from "react"
import { motion, useMotionValueEvent, useMotionValue, AnimatePresence, animate, clamp } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import Button from "components/button/Button"
import { useExp, THE_WATCH_HUB, THE_MOVEMENT, THE_WATCH_HUB_FEAT_1, THE_WATCH_HUB_FEAT_3, FEATURES, THE_WATCH, PAGES, THE_MOVEMENT_CLOSED } from "../expcontext"
import { useSequence, SequenceProvider } from "./seqcontext"
import Sequence from "./Sequence"
import Feature from "./Feature"
// import Debug from "./debug/Debug" ////////  TO KEEP commented
import { HubProvider, useHub } from "./hubcontext"
import { FeatureProvider, useFeature } from "./featurecontext"
import { HIDDEN_PHASE, SWITCH_PHASE, useSwitcher, VOID_PHASE } from "../switchcontext"
import BackgroundImg from "./BackgroundImg"
import TheWatchIndic from "../ui/TheWatchIndic"
import BlockSwitcher from "./BlockSwitcher"
import Previous from "./Previous"
import Next from "./Next"
// import DebugSVG from "./debug/DebugSVG"  ////////  TO KEEP commented
import { Icon } from "components/icon/Icon"

const Zone = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  z-index: 1;
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`

const BackBtn = styled(motion(Button))`
  position: absolute;
  inset-block-end: 5%;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  z-index: 10;

  background: rgba(33 33 33 / 0.2) !important;

  backdrop-filter: blur(8px) !important;

  transition: background 0.3s !important;

  &:hover {
    background: rgba(33 33 33 / 0.35) !important;
  }
`

function FocusUi() {
  const console = useConsole()

  const { pagisible, focus_back, currentInteraction } = useExp()
  const { isFocused, targetFeatFocus } = useFeature()

  function onPress() {
    targetFeatFocus.set(-1)
    isFocused.set(false)
    pagisible.set(true)
    currentInteraction.set("restore")
  }

  function onCurrentInteraction(v) {
    if (v === "escape") onPress()
  }
  useMotionValueEvent(currentInteraction, "change", onCurrentInteraction)

  return (
    <BackBtn type="button" className='filled translucent-light' icon='none' onPress={onPress} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Icon type='close' />
      {focus_back}
    </BackBtn>
  )
}

function Features({ isDragging, background, progress, curFeature, targetFeatStep }) {
  const console = useConsole()

  const { isFocused } = useFeature()
  const { features } = useHub()

  const [isOpen, setIsOpen] = useState(isFocused.get())

  function onTarget(b) {
    setIsOpen(b)
  }
  useMotionValueEvent(isFocused, "change", onTarget)

  return (
    <>
      {features.map(({ feature_id, ...rest }, i) => (
        <Feature
          key={feature_id}
          feature_id={feature_id}
          {...rest}
          index={i}
          isDragging={isDragging}
          progress={progress}
          curFeature={curFeature}
          targetFeatStep={targetFeatStep}
        />
      ))}
      <AnimatePresence>{isOpen ? <FocusUi key='openedfocus' background={background} /> : null}</AnimatePresence>
    </>
  )
}

function Content({ data }) {
  const console = useConsole()

  const { currentStep, seenSteps, frameToDraw, targetChapter, targetChapterTransi, device, hubFra, scenario } = useExp()
  const { curFrameNum } = useSequence()
  const { isFocused } = useFeature()
  const { resetPhase } = useSwitcher()
  const { max, hubframes, kpts, kpt } = useHub()

  const curFeature = useMotionValue(0)
  const targetFeatStep = useMotionValue(0)
  const progress = useMotionValue(0)
  const percent = useMotionValue(0)
  const isDragging = useMotionValue(false)
  const dragHasStarted = useMotionValue(false)

  const [showUi, setShowUi] = useState(false)

  function onPhaseChange(p) {
    if (p === VOID_PHASE) {
      if (currentStep.get() >= THE_WATCH_HUB && currentStep.get() <= THE_WATCH_HUB_FEAT_3) {
        if (!isFocused.get()) {
          if (resetPhase.prev === SWITCH_PHASE) {
            hubFra[scenario.get()][device.get()][curFrameNum.get()]?.then(im => frameToDraw.set(im))
          } else {
            hubframes[device.get()][curFrameNum.get()]?.then(im => frameToDraw.set(im))
          }
        }
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  function onPerc(p) {
    curFrameNum.set(Math.round(clamp(0, 1, p) * (hubframes[device.get()].length - 1)))
  }
  useMotionValueEvent(percent, "change", onPerc)

  function onProg(p) {
    percent.set(Math.max(Math.min(p, max), 0) / max)
  }
  useMotionValueEvent(progress, "change", onProg)

  function onPanStart() {
    dragHasStarted.set(true)
  }

  function onPan(e, info) {
    isDragging.set(true)
    const p = (progress.get() + Math.round(info.delta.x) + max) % max
    progress.set(p)
  }

  function onPanEnd(e, info) {
    isDragging.set(false)
    if (info.velocity.x > 0) {
      auSuivant()
    } else {
      auPrevious()
    }
  }

  function goToMvmt() {
    if (currentStep.get() !== THE_WATCH_HUB_FEAT_3) {
      const target = kpts[FEATURES.indexOf(THE_WATCH_HUB_FEAT_3)][0]
      animate(progress, target, { type: "tween", ease: "linear", duration: 0.3 }).then(() => {
        currentStep.set(THE_MOVEMENT)
      })
    } else {
      currentStep.set(THE_MOVEMENT)
    }
  }

  function onTargFeat(c) {
    let target = kpt[c]
    if (c === 0 && targetFeatStep.prev === kpt.length - 1) {
      target = max
    }
    const dur = Math.abs((progress.get() - target) / 15) / 120

    seenSteps[c] = true

    animate(progress, target, { type: "tween", ease: "linear", duration: dur }).then(() => {
      progress.set(target % max)
      curFeature.set(c)
      currentStep.set(FEATURES[c])
    })
  }
  useMotionValueEvent(targetFeatStep, "change", onTargFeat)

  function onTargChapTransi(t) {
    if (t < 0) return
    if (t === THE_MOVEMENT_CLOSED) {
      goToMvmt()
      targetChapterTransi.set(-1)
    }
  }
  useMotionValueEvent(targetChapterTransi, "change", onTargChapTransi)

  function auSuivant() {
    const n = (targetFeatStep.get() + 1) % kpt.length
    targetFeatStep.set(n)
  }

  function auPrevious() {
    const n = targetFeatStep.get() === 0 ? kpt.length - 1 : targetFeatStep.get() - 1
    targetFeatStep.set(n)
  }

  function onPreviousClick() {
    dragHasStarted.set(true)
    setShowUi(true)
    if (targetFeatStep.get() === 0) {
      targetChapter.set(PAGES[0])
    } else {
      auPrevious()
    }
  }

  function onNextClick() {
    dragHasStarted.set(true)
    setShowUi(true)
    const allSeen = seenSteps.every(v => !!v)
    if (allSeen) {
      goToMvmt()
    } else {
      auSuivant()
    }
  }

  return (
    <>
      <Zone onPan={onPan} onPanStart={onPanStart} onPanEnd={onPanEnd} />
      <AnimatePresence>
        {showUi ? (
          <>
            <Features
              isDragging={isDragging}
              background={data[THE_WATCH].background}
              progress={progress}
              curFeature={curFeature}
              targetFeatStep={targetFeatStep}
            />
          </>
        ) : null}
      </AnimatePresence>
      <Previous onClickCB={onPreviousClick} />
      <Next onClickCB={onNextClick} />
      <BlockSwitcher />
      <TheWatchIndic onClickCB={onNextClick} progress={progress} dragHasStarted={dragHasStarted} setShowUi={setShowUi} />
      {/*   ////////  TO KEEP commented !!!! <Debug progress={progress} />
      <DebugSVG progress={progress} /> */}
    </>
  )
}

export default function HubFeature({ data }) {
  const console = useConsole()

  const { currentStep, targetChapter, currentChapter, frameToDraw, seenSteps, device, scenario, hubFra } = useExp()
  const { resetPhase } = useSwitcher()

  const [isOpen, setIsOpen] = useState(false)

  function resetSeen() {
    seenSteps[0] = true
    seenSteps[1] = false
    seenSteps[2] = false
  }

  function onOnStepChange(s) {
    if (s >= THE_WATCH_HUB && s <= THE_WATCH_HUB_FEAT_3) {
      setIsOpen(true)
      currentStep.set(THE_WATCH_HUB_FEAT_1)
      currentChapter.set(THE_WATCH_HUB_FEAT_1)
    } else {
      resetSeen()
      setIsOpen(false)
    }
  }
  useMotionValueEvent(currentStep, "change", onOnStepChange)

  function onPhaseChange(p) {
    if (p === HIDDEN_PHASE) {
      if (targetChapter.get() === THE_WATCH_HUB_FEAT_1) {
        setIsOpen(true)
        data[THE_WATCH_HUB][0].seqloader[device.get()][0].then(im => frameToDraw.set(im))
        resetSeen()
      } else {
        setIsOpen(false)
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  return (
    <FeatureProvider>
      <SequenceProvider>
        <HubProvider data={data}>
          <Sequence />
          <BackgroundImg />
          <AnimatePresence>{isOpen ? <Content key='hubcontent' data={data} /> : null}</AnimatePresence>
        </HubProvider>
      </SequenceProvider>
    </FeatureProvider>
  )
}
