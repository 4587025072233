import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"

import getMediaQuery from "css/breakpoints"

import Link from "components/link/Link"
import Button from "components/button/Button"
import { useXplit } from "../context"
import XpShare from "./XpShare"
import { useExp } from "../expcontext"

const Strip = styled.div`
  --text: rgb(var(--light-black));
  height: var(--strip-height);
  width: 100%;
  position: absolute;
  inset-block-start: 0;
  background: white;
  z-index: 12;
  display: grid;
  grid-template-columns: var(--outer-margin) min-content min-content min-content auto min-content var(--outer-margin);
  grid-template-rows: 100%;
  align-items: center;

  & > a {
    grid-column: 2;
    white-space: nowrap !important;
  }
`

const EndWrapper = styled.div`
  position: relative;
  grid-column: -3;
`

const CloseBtn = styled(Button)`
  position: relative;
  display: inline;
  line-height: 1.5;
  padding-block: 0.5rem;
  white-space: normal;
`

export default function XpStrip() {
  const console = useConsole()
  const dictionary = useDictionary()

  const { nav_link = {}, currentInteraction } = useExp()
  const { xp_query } = useXplit()

  function onClose() {
    currentInteraction.set("close")
    xp_query.set(null)
  }

  const link = {
    aria_label: nav_link.label,
    external: "",
    href: nav_link.href,
    label: nav_link.label,
    outlink_name: "",
    style: "inline",
  }

  return (
    <Strip className='light-theme'>
      <Link className='inline light-theme' {...link}>
        {link.label}
      </Link>
      <EndWrapper>
        <CloseBtn type="button" className='icon' icon='close' iconOnly onPress={onClose} aria_label={dictionary.popinClose()} />
        <XpShare />
      </EndWrapper>
    </Strip>
  )
}
