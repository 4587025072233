import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useConsole } from "contexts/Console"

import { Heading as HeadingCN } from "css/classnames"
import getMediaQuery from "css/breakpoints"
import { DiciplineTitle } from "components/testimonees/HubText"
import { body100, headline100, headline50, quote100, headline70, legend110, surtitle70, bold } from "css/text"

export const KickerCN = `css-${hash("kicker")}`
export const SubtitleCN = `css-${hash("subtitle")}`

// const PushCenterTextStyle = DiciplineTitle

const Root = styled.hgroup`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  /* grid-template-columns: max-content; */
  height: fit-content;

  ${({ pushCenter }) =>
    pushCenter &&
    `
      & > h1,
      & > h2,
      & > p {
        background: var(--title-gradient, rgb(var(--green)));
        -webkit-background-clip: text;
        color: transparent;
        background-clip: text;
        display: inline-table; 
        position: relative;
        text-wrap: balance;
        -webkit-text-fill-color: transparent;
        will-change: transform;
      }
      & > p {
        margin-block-start: 0.5rem;
      }

    `}

  & > h2 {
    grid-row-start: 2;
  }

  & > p {
    ${legend110}
    ${bold}
    grid-row-start: 1;
    margin-block-end: 10px;
  }
  & > p:nth-of-type(2) {
    ${({ pushCenter }) => pushCenter && "grid-row-start:3; margin-block-start: 10px;"};
  }
`
const Title = styled.h2`
  ${({ isPush }) => (isPush ? headline50 : headline70)}

  em {
    color: rgb(var(--green));
    font-style: normal;
  }
`

const TitlePushCenter = styled.h2`
  ${quote100}
  font-family: var(--quote-font-family);
  font-style: var(--quote-font-style);
  font-weight: var(--quote-font-weight);
  pointer-events: none;
  ${getMediaQuery("m", { max: true })} {
    margin-bottom: 12px;
  }
  text-wrap: pretty;

  em {
    color: rgb(var(--green));
    font-style: normal;
  }

  background: var(--title-gradient, rgb(var(--green)));
  margin-block-end: 1rem;
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  display: inline-table; // Fix text clipping in Firefox 135 ¯\_(ツ)_/¯
  position: relative;
  text-wrap: balance;
  -webkit-text-fill-color: transparent;
  will-change: transform;
`

export default function Heading({ tag, title, isPush, subtitle, kicker, id, pushCenter, theme }) {
  const console = useConsole()

  //*******
  // TO DELETE
  // subtitle = null
  // kicker = null
  //*******

  return title && (kicker || subtitle) ? (
    <Root className={HeadingCN} pushCenter={pushCenter}>
      {kicker && <p className={KickerCN}>{kicker}</p>}
      {pushCenter ? (
        <TitlePushCenter dangerouslySetInnerHTML={{ __html: title }} id={id} />
      ) : (
        <Title isPush={isPush} dangerouslySetInnerHTML={{ __html: title }} id={id} />
      )}
      {subtitle && <p className={KickerCN}>{subtitle}</p>}
    </Root>
  ) : title && pushCenter ? (
    <TitlePushCenter pushCenter={pushCenter} dangerouslySetInnerHTML={{ __html: title }} id={id} />
  ) : title ? (
    <Title isPush={isPush} as={tag} className={HeadingCN} dangerouslySetInnerHTML={{ __html: title }} id={id} />
  ) : null
}
