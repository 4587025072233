import { createContext, useContext } from "react"
import { useMotionValue } from "framer-motion"

import useConstant from "hooks/useConstant"

export const Context = createContext()

export const LightBoxProvider = ({ bgColor = "rgb(33 33 33)", children }) => {
  const lightBoxes = useConstant(() => new Map())

  const currentIndex = useMotionValue(-1)
  const targetSlide = useMotionValue(-1)

  return <Context.Provider value={{ lightBoxes, currentIndex, targetSlide, bgColor }}>{children}</Context.Provider>
}

export const useLightBox = () => useContext(Context)
