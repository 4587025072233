import { Cloudinary } from "@cloudinary/url-gen"
import { limitFit } from "@cloudinary/url-gen/actions/resize"

export function getPoster({ src, format = "auto:image" }, env) {
  const { cloudinary: { cloudName, secureDistribution } = {} } = env
  src = src && src.constructor === Object ? src : { public_id: src || "/", version: 1 }

  const cld = new Cloudinary({
    cloud: { cloudName: cloudName },
    url: Object.assign({ privateCdn: !!secureDistribution }, !!secureDistribution && { secureDistribution }),
  })
  return () => {
    const video = cld.video(src.public_id)
    video.setVersion(src.version)
    video.addTransformation(`f_${format}`)

    const poster = video.addTransformation("so_0")
    const pUrl = new URL(poster.toURL())
    pUrl.searchParams.delete("_a") //necessary for hydration
    return { poster: pUrl }
  }
}
/*
export function getVideo({ src, format = "auto:video" }, env) {
  const { cloudinary: { cloudName, secureDistribution } = {} } = env
  src = src && src.constructor === Object ? src : { public_id: src || "/", version: 1 }

  const cld = new Cloudinary({
    cloud: { cloudName: cloudName },
    url: Object.assign({ privateCdn: !!secureDistribution }, !!secureDistribution && { secureDistribution }),
  })

  return ({ width, quality = "auto" }) => {
    const video = cld.video(src.public_id)
    video.setVersion(src.version)
    video.resize(limitFit().width(width))
    video.addTransformation(`f_${format}`)
    video.quality(quality)
    const vUrl = new URL(video.toURL())
    vUrl.searchParams.delete("_a") //necessary for hydration
    return { src: vUrl }
  }
}
*/

function posterUrl(cld, src, format = "auto:image") {
  const video = cld.video(src.public_id)
  video.setVersion(src.version)
  video.addTransformation(`f_${format}`)
  const poster = video.addTransformation("so_0")
  const pUrl = new URL(poster.toURL())
  pUrl.searchParams.delete("_a") //necessary for hydration
  return pUrl
}

function videoUrl(cld, src, width, quality, format = "auto:video") {
  const video = cld.video(src.public_id)
  video.setVersion(src.version)
  video.resize(limitFit().width(width))
  video.addTransformation(`f_${format}`)
  video.quality(quality)
  const vUrl = new URL(video.toURL())
  vUrl.searchParams.delete("_a") //necessary for hydration
  return vUrl
}

export function getVideo({ src }, env) {
  const { cloudinary: { cloudName, secureDistribution } = {} } = env
  src = src && src.constructor === Object ? src : { public_id: src || "/", version: 1 }

  const cld = new Cloudinary({
    cloud: { cloudName: cloudName },
    url: Object.assign({ privateCdn: !!secureDistribution }, !!secureDistribution && { secureDistribution }),
  })

  return ({ width, quality = "auto" }) => {
    return {
      src: videoUrl(cld, src, width, quality),
      poster: posterUrl(cld, src),
    }
  }
}

export function videoReady(el, ev, mv) {
  listen()

  function onReady(e) {
    clear()
    mv.set(1)
  }

  function listen() {
    el.addEventListener(ev, onReady, {
      capture: true,
      once: true
    })
  }

  function clear() {
    el.removeEventListener(ev, onReady, {
      capture: true,
      once: true
    })
  }
}