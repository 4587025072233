import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"
import { useUser } from "contexts/User"
import { useEnv } from "contexts/Env"
import { useRef } from "react"

// export const PRICE_CACHE = "price_cache"
let queue = Promise.resolve()

export default function usePrice({ rmc, countryCode: overrideCountryCode = null }) {
  const console = useConsole()
  const locale = useLocale()
  const env = useEnv()
  const { countryCode: defCountryCode } = useUser()

  const lang = locale?.current?.codes?.www
  const countryCode = overrideCountryCode ?? defCountryCode

  const base = process.browser ? env.services : process.env.REVERSE_PROXY_URL
  const pathname = `/api/prices/${rmc}?countryCode=${countryCode}&lang=${lang}`

  const priceData = env.priceData

  return (handler = Function.prototype) => {
    const url = new URL(pathname, base)
    const controller = new AbortController()
    const request = new Request(url)

    // const cacheKey = `${rmc}_${countryCode}_${lang}`
    // const cachedData = sessionStorage.getItem(PRICE_CACHE) // Attempt to retrieve data from cache

    // if (cachedData) {
    //   const cache = JSON.parse(cachedData)
    //   const { data, timestamp } = cache[cacheKey] || {}
    //   // If data exists in cache, parse and return it immediately
    //   if (data && timestamp && isCacheValid(timestamp)) {
    //     handler(null, data)
    //     controller.abort()
    //   }
    // }

    queue = queue
      .then(() => {
        if (!controller.aborted)
          return priceData.current.has(url.href) ? handler(null, priceData.current.get(url.href)) : fetch(request, { signal: controller.signal })
            .then(response => response.json())
            .then(data => {
              priceData.current.set(url.href, data)
              handler(null, data)
              // Store the fetched data in cache for future use
              // const cache = JSON.parse(sessionStorage.getItem(PRICE_CACHE)) || {}
              // cache[cacheKey] = { timestamp: Date.now(), data }
              // sessionStorage.setItem(PRICE_CACHE, JSON.stringify(cache))
            })
            .catch(err => {
              if (err.name === "AbortError") return
              handler(err, null)
            })
      })
      .then(() => new Promise(resolve => setTimeout(resolve, 100)))

    console.verbose("usePrice(%o) => fetch", { url })
    return () => {
      controller.abort()
    }
  }
}

// export function isCacheValid(timestamp) {
//   const thirtyMinutes = 30 * 60 * 1000 // 30 minutes in milliseconds
//   const now = Date.now()
//   return now - timestamp < thirtyMinutes
// }
