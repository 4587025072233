import { useState } from "react"
import { motion, useMotionTemplate, useMotionValueEvent, useTransform } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"
import { buttonContrastMode } from "css/buttons"
import { bold, legend80 } from "css/text"

import { RATIOS, SIZES, useExp } from "../expcontext"
import { useViewport } from "contexts/Viewport"

const Grid = styled(motion.div)`
  position: absolute;
  /* width: 100%;
  height: 100%; */
  z-index: 6;
  display: grid;
  align-items: center;
  /* inset: 0; */
`

const SliderCont = styled(motion.div)`
  grid-column: 3;
  grid-row: 2;
  width: 100%;
  position: relative;
  align-self: center;
  justify-self: center;
  margin-block-end: 50px;

  ${getMediaQuery("m")} {
    grid-column: 2;
    grid-row: 3;
    width: 60%;
    margin-inline-start: 30px;
    margin-block-end: 0;
  }
`

const SliderTrack = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transform: rotate(-90deg);

  ${getMediaQuery("m")} {
    transform: none;
  }
`

const FakeBtn = styled(motion.div)`
  grid-column: 2;
  grid-row: 2;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`

const Track = styled(motion.div)`
  background: linear-gradient(0.25turn, rgba(255 255 255 / 1), rgba(255 255 255 / 1), rgba(255 255 255 / 0));
  width: 100%;
  height: 100%;

  html.prefers-contrast & {
    background: white !important;
    border-color: black !important;
    border-style: solid !important;
    border-width: 2px !important;
    color: black !important;
  }
`

const PinButton = styled(motion.button)`
  ${buttonContrastMode}
  width: 52px;
  height: 52px;
  margin-inline-start: -25.5px;
  margin-block-start: -25px;
  border-radius: 50%;
  background: rgba(255 2552 255 / 0.5);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  border: none;
  z-index: 2;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }

  html.prefers-contrast & {
    & > div {
      background: black;
    }

    @media (hover: hover) {
      &:hover {
        & > div {
          background: white;
        }
      }
    }
  }
`

const Dot = styled(motion.div)`
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
`

const Instruct = styled(motion.p)`
  ${legend80};
  ${bold};
  color: white;
  position: absolute;
  inset-inline-end: 100%;
  text-align: start;
  width: 50px;
  transform: translateY(-50%);
  margin-inline-end: 30px;
  transform: rotate(90deg);
  z-index: 1;

  ${getMediaQuery("m")} {
    text-align: end;
    transform: translateY(-50%);
    width: 60px;
    inset-block-start: 50%;
    inset-inline-end: 100%;
    margin-inline-end: 40px;
  }

  html.prefers-contrast & {
    color: black !important;
    -webkit-text-fill-color: inherit !important;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 10px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 10px);
      z-index: -1;
    }
  }
`

const POS = [
  { x1: 88, x2: 537, y1: 648, y2: 1092 },
  { x1: 490, x2: 940, y1: 313, y2: 757 },
]

export default function Slider({ percent, onPan, onPanEnd, onClick }) {
  const console = useConsole()
  const { width, height } = useViewport()

  const { instruction, device } = useExp()

  const x = useTransform(percent, [0, 1], ["0vw", "30vw"])
  const inset = useMotionTemplate`inset(0 0 0 ${x})`
  const scale = useTransform(percent, [0.65, 1], [1, 0])
  const opacity = useTransform(percent, [0.5, 1], [1, 0])

  const [isPresent, setIsPresent] = useState(true)

  const gridWidth = useTransform([width, height, device], ([w, h, d]) => (RATIOS[d] > w / h ? SIZES[d].width * (h / SIZES[d].height) : w))
  const gridHeight = useTransform([width, height, device], ([w, h, d]) => (RATIOS[d] > w / h ? h : SIZES[d].height * (w / SIZES[d].width)))
  const gridX = useTransform([gridWidth, width, device], ([gw, w, d]) => `${(w - gw) / 2}px`)
  const gridY = useTransform([gridHeight, height, device], ([gh, h, d]) => `${(h - gh) / 2}px`)

  const gridTemplate = useTransform([device, gridWidth, gridHeight], ([d, w, h]) => {
    const x1 = (POS[d].x1 / SIZES[d].width) * w
    const x2 = (POS[d].x2 / SIZES[d].width) * w - x1
    const y1 = (POS[d].y1 / SIZES[d].height) * h
    const y2 = (POS[d].y2 / SIZES[d].height) * h - y1
    return `${y1}px ${y2}px 13vh auto / ${x1}px ${x2}px auto`
  })

  function onPerc(p) {
    if (p < 1) {
      setIsPresent(true)
    } else {
      onPanEnd()
      setIsPresent(false)
    }
  }
  useMotionValueEvent(percent, "change", onPerc)

  const variants = {
    hidden: dur => ({ opacity: 0, transition: { duration: dur } }),
    visible: dur => ({ opacity: 1, transition: { duration: dur } }),
  }

  return isPresent ? (
    <Grid
      variants={variants}
      initial='hidden'
      animate='visible'
      exit='hidden'
      style={{ width: gridWidth, height: gridHeight, insetInlineStart: gridX, insetBlockStart: gridY, gridTemplate }}
    >
      <FakeBtn onPan={onPan} onPanEnd={onPanEnd} onClick={onClick} style={{ x, scale }} />
      <SliderCont>
        <SliderTrack onPan={onPan} onPanEnd={onPanEnd}>
          <Track style={{ clipPath: inset }} />
          <PinButton type="button" onClick={onClick} style={{ x, scale }} aria-label={instruction.movement}>
            <Dot />
          </PinButton>
          <Instruct style={{ opacity }}>{instruction.movement}</Instruct>
        </SliderTrack>
      </SliderCont>
    </Grid>
  ) : null
}
