import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"
import imageLoader, { imageLoaderOneSrc } from "./imageLoader"
import { motion } from "framer-motion"

export const WIDTHS = [240, 320, 640, 800, 1200, 1600, 1920, 2440, 3200, 3840]

export const ImgPlaceholder = css`
  background-color: rgb(127 127 127 / 0.1);
  background-image: url("data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNSAxNSIgdmlld0JveD0iMCAwIDE1IDE1IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0yLjEgNS4zaC4ybDIuMyA3LjdjLjMuOSAxLjUgMS41IDMgMS41czIuOC0uNyAzLTEuNWwyLjMtNy43aC4yYy40IDAgLjgtLjQuOC0uOHMtLjQtLjgtLjgtLjgtLjguNC0uOC44YzAgLjMuMi42LjUuN2wtMi42IDUuNy40LTcuNmMuNCAwIC44LS40LjgtLjhzLS40LS44LS44LS44LS44LjQtLjguOC4yLjcuNi44bC0xLjggNy40LS45LTguM2MuNCAwIC43LS40LjctLjhzLS40LS44LS44LS44LS44LjQtLjguOC4zLjcuNy44bC0uOCA4LjItMS44LTcuNGMuMy0uMS42LS40LjYtLjhzLS40LS44LS44LS44Yy0uNSAwLS44LjQtLjguOHMuNC44LjguOGwuNCA3LjYtMi42LTUuNmMuMi0uMi40LS40LjQtLjcgMC0uNC0uNC0uOC0uOC0uOHMtLjguMy0uOC44YzAgLjQuNC44LjguOHptNS41IDdjMS40IDAgMi41LjQgMi41LjhzLTEuMS44LTIuNS44LTIuNS0uNC0yLjUtLjggMS4xLS44IDIuNS0uOHoiIGZpbGw9InJnYigxMjcgMTI3IDEyNyAvIDAuMikiLz48L3N2Zz4=");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 30px;
`

const Img = styled(motion.img)`
  color: rgb(0 0 0 / 0); // alt
  height: auto;
  max-width: 100%;

  &[loading="lazy"][srcset*=".jpg"] {
    ${ImgPlaceholder}
  }
`

const reverseMedia = {
  portrait: "(max-width: 767px)",
  landscape: "(min-width: 768px)",
}

export default function ImageCLD(props) {
  const {
    className,
    alt = "",
    sources,
    loading,
    decoding = loading === "lazy" ? "async" : null,
    quality = "auto:eco",
    widths = WIDTHS,
    sizes = "(min-width: 48rem) 33.3vw, 84vw",
    sourceMedia = reverseMedia,
    imgId,
    animate,
    initial,
    transition,
  } = props
  const console = useConsole()
  const env = useEnv()
  //  console.log("ImageCLD", props)

  return sources?.length > 1 ? (
    <picture className={className}>
      {sources?.map((source, i) => {
        const { metadata, width, height, policy, path } = source
        const device = metadata?.v7_type && metadata?.v7_type[0] === "portrait" ? "portrait" : "landscape"
        return (
          <source
            key={`source-${i}`}
            media={sourceMedia[device]}
            srcSet={widths?.reduce((acc, c, i, arr) => {
              const image_loader = imageLoader({ src: source, policy, path }, env)({ width: c, quality: quality ?? "auto:eco" })
              const url = image_loader ? new URL(image_loader) : null
              return url ? `${acc}` + `${url.href} ${c}w` + `${i < arr.length - 1 ? ", " : ""}` : acc
            }, "")}
            sizes={sizes}
            width={width}
            height={height}
          />
        )
      })}
      <Img initial={initial} animate={animate} transition={transition} alt={alt} className={className} loading={loading} decoding={decoding} />
    </picture>
  ) : (
    sources?.map((source, i) => {
      const { width, height, policy, path } = source
      return (
        <Img
          key={`image-${i}`}
          alt={alt}
          className={className}
          loading={loading}
          srcSet={widths?.reduce((acc, c, i, arr) => {
            const image_loader = imageLoader({ src: source, policy, path }, env)({ width: c, quality: quality ?? "auto:eco" })
            const url = image_loader ? new URL(image_loader) : null
            return url ? `${acc}` + `${url} ${c}w` + `${i < arr.length - 1 ? ", " : ""}` : acc
          }, "")}
          width={width}
          height={height}
          sizes={sizes}
          decoding={decoding}
        />
      )
    })
  )
}

export function ImageCLDOneSrc(props) {
  const {
    className,
    alt = "",
    sources,
    loading,
    decoding = loading === "lazy" ? "async" : null,
    quality,
    // widths = WIDTHS,
    sizes = "(min-width: 48rem) 33.3vw, 84vw",
    sourceMedia = reverseMedia,
  } = props
  const env = useEnv()

  return sources?.length > 1 ? (
    <picture>
      {sources?.map((source, i) => {
        const { metadata, width, height, policy, path } = source
        const device = metadata?.v7_type && metadata?.v7_type[0] === "portrait" ? "portrait" : "landscape"
        return (
          <source
            key={`source-${i}`}
            media={sourceMedia[device]}
            srcSet={imageLoaderOneSrc({ src: source, policy, path }, env)({ quality: quality ?? "auto:best" })}
            sizes={sizes}
            width={width}
            height={height}
          />
        )
      })}
      <Img alt={alt} className={className} loading={loading} decoding={decoding} />
    </picture>
  ) : (
    sources?.map((source, i) => {
      const { width, height, policy, path } = source
      return (
        <Img
          key={`image-${i}`}
          alt={alt}
          className={className}
          loading={loading}
          srcSet={imageLoaderOneSrc({ src: source, policy, path }, env)({ quality: quality ?? "auto:best" })}
          width={width}
          height={height}
          sizes={sizes}
          decoding={decoding}
        />
      )
    })
  )
}
