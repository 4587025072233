import { useEffect } from "react"
import { useMotionValueEvent } from "framer-motion"

import { useConsole } from "contexts/Console"

import { useSequence } from "./seqcontext"
import { useExp } from "../expcontext"
import { useHub } from "./hubcontext"

export default function Sequence() {
  const console = useConsole()

  const { seqloaders, hubframes } = useHub()

  const { frameToDraw, device } = useExp()
  const { curFrameNum } = useSequence()

  useEffect(() => {
    if (seqloaders) {
      Promise.all(
        seqloaders.map(
          sl =>
            new Promise((resolve, reject) => {
              sl.loadFrames(device.get())
                .then(arr => resolve(arr))
                .catch(err => reject(err))
            })
        )
      )
    }
  }, [seqloaders, device])

  function onFrameNum(f) {
    hubframes[device.get()][f]?.then(im => frameToDraw.set(im))
  }
  useMotionValueEvent(curFrameNum, "change", onFrameNum)

  return null
}
