import { useEffect, useRef, useLayoutEffect, useState } from "react"
import styled from "@emotion/styled"
import { createContext } from "react"
import { InstallAnimationProvider } from "./InstallAnimation"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe } from "./utils"
import { CfgPreload } from "./install.preload"
import { CfgCanvas } from "./install.canvas"

const Main = styled.div`
  grid-row: 1/-1;
  grid-column: 1/-1;
  position: relative;
  z-index: 28;
  opacity: 0;

  .wv_launched & {
    opacity: 1;
  }
`

export function CfgInstall(props) {
    const { Ctx } = props
    const { startstep, startrmc, settled, resized } = useFrameAnimation(Ctx)
    const rfmain = useRef()
    const ictx = createContext()
    const [active, setActive] = useState(false)

    function onSettled(v) {
        setActive(v < 1)
    }
    useLayoutEffect(() => mvSubscribe(settled, onSettled), [])
    /*
        function onMobile(v) {
            setActive(settled.get() <= 0 && v >= 0)
        }
        //    useLayoutEffect(() => mvSubscribe(mobile, onMobile), [])
    */
    function onResized(v) {
        if (!v) return
        setActive(settled.get() <= 0 && !startstep)
    }
    useLayoutEffect(() => mvSubscribe(resized, onResized), [])

    return active ? (
        <InstallAnimationProvider Ctx={ictx} size={resized.get()} preload={startrmc}>
            <Main ref={rfmain}>
                <CfgPreload ictx={ictx} {...props} />
                <CfgCanvas ictx={ictx} {...props} />
            </Main>
        </InstallAnimationProvider>
    ) : null
}