import { useState, useRef, useEffect } from "react"
import { AnimatePresence, motion, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { buttonReset, buttonContrastMode } from "css/buttons"

import { useExp } from "../expcontext"
import { useConsole } from "contexts/Console"
import { useFeature } from "./featurecontext"
import { body50, bold } from "css/text"
import { useSwitcher } from "../switchcontext"

const SwitchCont = styled(motion.div)`
  display: flex;
  gap: 1.5rem;
  z-index: 5;
  grid-row: 4;
  grid-column: 1 / -1;
  padding-inline-start: var(--outer-margin);
  padding-inline-end: var(--outer-margin);
  overflow-x: auto;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

const SwitchBtn = styled(motion.button)`
  ${buttonReset}
  ${buttonContrastMode}
  display: inline;
  line-height: 1.5;
  padding-block: 10px;
  white-space: nowrap;
  ${body50};
  ${bold};
  font-size: 16px;

  transition: opacity 0.3s;

  &::after {
    content: "";
    width: 6px;
    height: 6px;
    background: white;
    display: inline-block;
    border-radius: 50%;
    margin-inline-start: 8px;
    margin-block-end: 2px;
    opacity: 1;

    html.prefers-contrast & {
      background: black;
    }
  }

  &:hover:not([disabled]) {
    opacity: 1;
  }

  &:not([disabled])::after {
    display: none;
  }
  &:not([disabled]) {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 1;
  }

  html.prefers-contrast & {
    opacity: 1 !important;
  }
`

function SwitchButton({ index, content }) {
  const console = useConsole()

  const { scenario } = useExp()
  const { resetPhase } = useSwitcher()

  const ref = useRef()

  function onClick() {
    ref.current.disabled = false
    const phase = `${index}.111`
    resetPhase.set(phase)
  }

  function onScenar(s) {
    ref.current.disabled = s === index
  }
  useMotionValueEvent(scenario, "change", onScenar)

  useEffect(() => {
    ref.current.disabled = scenario.get() === index
  }, [])

  return (
    <SwitchBtn type="button" ref={ref} onClick={onClick}>
      {content}
    </SwitchBtn>
  )
}

export default function SwitcherButtons() {
  const { switchLabel } = useExp()
  const { isFocused } = useFeature()

  const [inactive, setInactive] = useState(!isFocused.get())

  function onFeatFocus(b) {
    setInactive(!b)
  }
  useMotionValueEvent(isFocused, "change", onFeatFocus)

  const variants = {
    inactive: { opacity: 0, transition: { duration: 0.5, type: "tween", ease: "easeOut" } },
    active: { opacity: 1, transition: { duration: 0.5, type: "tween", ease: "easeOut" } },
  }

  return (
    <AnimatePresence>
      {inactive ? (
        <SwitchCont key='switcherz' variants={variants} initial='inactive' animate='active' exit='inactive'>
          {switchLabel.map((s, i) => (
            <SwitchButton key={s} index={i} content={s} />
          ))}
        </SwitchCont>
      ) : null}
    </AnimatePresence>
  )
}
