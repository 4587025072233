import { Suspense, useState, Component } from "react"
import * as components from "components/components"
import * as classnames from "css/classnames"
import * as fallbacks from "fallbacks"
import { useConsole } from "contexts/Console"
import camelCase from "utils/camelCase"
import { useEnv } from "contexts/Env"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.error(error)
    console.debug(info.componentStack)
  }

  render() {
    if (this.state.hasError) return this.props.fallback
    return this.props.children
  }
}

function Error() {
  const env = useEnv()

  return !env.live && <div style={{ height: "50vh", background: "red" }} />
}

function Placeholder({ meta }) {
  const console = useConsole()
  const [loaded] = useState(meta?.Component?._payload?._status)

  console.verbose("Placeholder(was loaded but still suspended: %s, %o)", !!loaded, meta)
  return process.env.NODE_ENV === "development" ? <div style={{ height: "100vh", background: meta.lazy ? "blue" : "red" }} /> : null
}

function Missing({ meta }) {
  const console = useConsole()

  console.debug("Missing(%o)", { type: meta.type })
  return null
}

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1)
}

export function parsePersoModularBlock(block, { console }) {
  // modular block is an array of Object containing a single key,
  // which name is the CS name for that group

  return Object.values(Object.values(Object.values(block))[0])[0].component
}

export function parseModularBlock(block, { console }) {
  // modular block is an array of Object containing a single key,
  // which name is the CS name for that group

  return block
    .reduce((acc, group) => {
      for (const key of Object.values(group)) acc.push(key.component[0])
      return acc
    }, [])
    .filter(Boolean)
}

export default function generateComponents(componentsList, { console, pageviewCallback }) {
  componentsList = Array.isArray(componentsList) ? componentsList : [componentsList].filter(Boolean)

  console.verbose("generateComponents(%o)", { componentsList, pageviewCallback })
  return componentsList
    .map((component, i) => {
      if (component?.$$typeof === Symbol.for("react.element")) return component
      const type = capitalizeFirstLetter(camelCase(component._content_type_uid ?? "undefined"))
      const key = component.uid ?? Math.random().toString()
      const children = component.children ?? []
      const Component = components[type] || Missing
      const lazy = Component?.$$typeof === Symbol.for("react.lazy")
      const Fallback = fallbacks[component.fallback] || fallbacks[type] || Placeholder
      const spacingClass = component.box_spacing && Object.values(component.box_spacing)
      const themeClass = component.theme && (component.theme?.theme || "dark-theme")
      const titleGradClass = component?.title_gradient?.colors.length && classnames.GradientTitle
      const chapoGradClass = component?.chapo_gradient?.colors.length && classnames.GradientChapo
      const classType = [classnames[type]]
      const className = classType
        .concat(spacingClass)
        .concat([themeClass])
        .concat([titleGradClass])
        .concat([chapoGradClass])
        .filter(e => !!e)
        .join(" ")
      const meta = { type, lazy, key, Component, Fallback, index: i }
      const { _content_type_uid, ...props } = component
      console.verbose("generateComponents:component(%s, %s, %o)", key, type, { Component, Fallback, lazy, children })
      return { meta, className, props, children }
    })
    .map(component => {
      return component?.$$typeof === Symbol.for("react.element") ? (
        component
      ) : (
        /*component.meta.lazy ? (
        <ErrorBoundary key={component.meta.key}>
          <Suspense fallback={<component.meta.Fallback {...component.props} className={component.className} meta={component.meta} />}>
            <component.meta.Component {...component.props} className={component.className} meta={component.meta}>
              {generateComponents(component.children, { console })}
            </component.meta.Component>
          </Suspense>
        </ErrorBoundary>
      ) :*/ <ErrorBoundary key={component.meta.key} fallback={<Error {...component.props} className={component.className} meta={component.meta} />}>
          <Suspense fallback={<component.meta.Fallback {...component.props} className={component.className} meta={component.meta} />}>
            <component.meta.Component {...component.props} className={component.className} meta={component.meta} pageviewCallback={pageviewCallback}>
              {generateComponents(component.children, { console })}
            </component.meta.Component>
          </Suspense>
        </ErrorBoundary>
      )
    })
}
