import { useRef, useMemo } from "react"
import { motion, useInView } from "framer-motion"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"

import generateModularBlocks from "utils/generateModularBlocks"

import { getGradient, modularTexts } from "components/articles/common"

import { slideUp } from "animations/slideUp"

import { cssStartVar, cssTxtStartVar, cssWidthVar, txtWidth, txtAlign } from "./cssvars"

const widthVar = css`
  ${cssWidthVar}: 6;

  &.${txtWidth.s} {
    ${cssWidthVar}: 6;
  }
  ${getMediaQuery("m")} {
    &.${txtWidth.m} {
      ${cssWidthVar}: 8;
    }
    &.${txtWidth.l} {
      ${cssWidthVar}: 10;
    }
  }
`

const alignVar = css`
  ${cssTxtStartVar}: 1;

  ${getMediaQuery("m")} {
    &.${txtAlign.start}, &.${txtAlign.start}.${txtWidth.s} {
      ${cssTxtStartVar}: calc(var(${cssStartVar}) + 1);
    }
    &.${txtAlign.start}.${txtWidth.m}, &.${txtAlign.start}.${txtWidth.l} {
      ${cssTxtStartVar}: min(calc(var(${cssStartVar}) + 1), calc(12 - var(${cssWidthVar})));
    }

    &.${txtAlign.center}, &.${txtAlign.center}.${txtWidth.s} {
      ${cssTxtStartVar}: 4;
    }
    &.${txtAlign.center}.${txtWidth.m} {
      ${cssTxtStartVar}: 3;
    }
    &.${txtAlign.center}.${txtWidth.l} {
      ${cssTxtStartVar}: 2;
    }

    &.${txtAlign.end}, &.${txtAlign.end}.${txtWidth.s} {
      ${cssTxtStartVar}: 7;
    }
    &.${txtAlign.end}.${txtWidth.m} {
      ${cssTxtStartVar}: 5;
    }
    &.${txtAlign.end}.${txtWidth.l} {
      ${cssTxtStartVar}: 3;
    }
  }
`

const TxtBloc = styled(motion.div)`
  ${widthVar}
  ${alignVar}
  justify-content: center;
  margin-block-end: 60px;
  grid-row: texts;

  grid-column: col var(${cssTxtStartVar}) / span var(${cssWidthVar});

  ${getMediaQuery("m")} {
    margin-block-end: 80px;
  }

  ${modularTexts}
`

export default function TextBlock({ texts, title_gradient, chapo_gradient, text_layout, inview = null }) {
  const console = useConsole()

  const modularBlocks = useMemo(() => generateModularBlocks(texts, { console }), [])

  const ttlGradient = title_gradient?.colors ? getGradient(title_gradient.colors) : null
  const chapoGradient = chapo_gradient?.colors ? getGradient(chapo_gradient.colors) : null
  const txtw = !!text_layout?.width ? txtWidth[text_layout.width] : txtWidth.s
  const txta = !!text_layout?.horizontal_pos ? txtAlign[text_layout.horizontal_pos] : txtAlign.start
  const rootRef = useRef()
  // { margin: "0% 0% 0% 0%", once: true }
  const isInView = useInView(rootRef, inview ?? { margin: "100% 0% 0% 0%" })
  const prefersReducedMotion = process.browser && document.documentElement.classList.contains("prefers-reduced-motion")

  return (
    <TxtBloc
      className={`${txtw} ${txta}`}
      style={{ "--title-gradient": ttlGradient, "--chapo-gradient": chapoGradient }}
      ref={rootRef}
      variants={slideUp}
      initial='hidden'
      animate={prefersReducedMotion ? "show" : isInView ? "show" : "hide"}
    >
      {modularBlocks.map(Comp => (
        <Comp.type {...Comp.props} key={Comp.key} id={Comp.key} />
      ))}
    </TxtBloc>
  )
}
