import styled from "@emotion/styled"

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  /* background: rgba(255 255 255 / 0.4); */
  & svg {
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    z-index: 1;
  }
`

export default function GlobalLoader({ size }) {
  return (
    <Container>
      <svg width={size} height={size} viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
        {[
          {
            rotate: "rotate(0 50 50)",
            begin: "-0.9166666666666666s",
          },
          {
            rotate: "rotate(30 50 50)",
            begin: "-0.8333333333333334s",
          },
          {
            rotate: "rotate(60 50 50)",
            begin: "-0.75s",
          },
          {
            rotate: "rotate(90 50 50)",
            begin: "-0.6666666666666666s",
          },
          {
            rotate: "rotate(120 50 50)",
            begin: "-0.5833333333333334s",
          },
          {
            rotate: "rotate(150 50 50)",
            begin: "-0.5s",
          },
          {
            rotate: "rotate(180 50 50)",
            begin: "-0.4166666666666667s",
          },
          {
            rotate: "rotate(210 50 50)",
            begin: "-0.3333333333333333s",
          },
          {
            rotate: "rotate(240 50 50)",
            begin: "-0.25s",
          },
          {
            rotate: "rotate(270 50 50)",
            begin: "-0.16666666666666666s",
          },
          {
            rotate: "rotate(300 50 50)",
            begin: "-0.08333333333333333s",
          },
          {
            rotate: "rotate(330 50 50)",
            begin: "0s",
          },
        ].map(({ rotate, begin }, index) => (
          <g transform={rotate} key={`g-${index.toString()}`}>
            <rect x='46' y='2.5' rx='9.200000000000001' ry='0.5' width='8' height='15' fill='white'>
              <animate attributeName='opacity' values='1;0' keyTimes='0;1' dur='1s' begin={begin} repeatCount='indefinite' />
            </rect>
          </g>
        ))}
      </svg>
    </Container>
  )
}
