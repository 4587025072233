import { useMotionTemplate, useTransform, motion, transform, useMotionValueEvent, useMotionValue, animate } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import Image from "components/media/ImageCLD"
import { THE_MOVEMENT, THE_WATCH, useExp } from "../expcontext"
import { useViewport } from "contexts/Viewport"
import { useState } from "react"
import { HIDDEN_PHASE, useSwitcher } from "../switchcontext"
import { useFeature } from "./featurecontext"
import { useHub } from "./hubcontext"

const Bground = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: 2;
  pointer-events: none;
`

const ImgCont = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  user-select: none;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const Span = styled.span`
  font-size: 100px;
  color: rgba(0 0 0 / 0.1);
  position: absolute;
  inset: 0;
`

export default function BackgroundImg() {
  const console = useConsole()
  const { width } = useViewport()

  const { revealProg, ofs, targetChapter, currentStep } = useExp()
  const { resetPhase } = useSwitcher()
  const { isFocused, targetFeatFocus } = useFeature()
  const { gradientProg, data, features } = useHub()

  const [isOpen, setIsOpen] = useState(false)

  const axis = useTransform(targetFeatFocus, t => features?.[t]?.micro_seqloader.axis || null)

  const inset = useMotionTemplate`inset(0 0 0 ${revealProg}px)`
  const opacity = useTransform([revealProg, width, ofs], ([p, w, o]) => transform(p, [w * 0.65, 0], [0, 1]))
  const scale = useMotionValue(1)
  const y = useTransform([gradientProg, scale], ([p, s]) => {
    const f = (s - 1) / 0.2
    return transform(p, [0, 1], [`${1 * f}%`, `-${1 * f}%`])
  })
  const translate = useTransform([axis, y], ([a, v]) => {
    const ax = a === null ? axis.prev : a
    return ax === "x" ? `translateX(${v})` : `translateY(${v})`
  })

  const transfo = useMotionTemplate`scale(${scale}) ${translate}`

  function onOnStepChange(s) {
    if (s >= THE_WATCH && s <= THE_MOVEMENT) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }
  useMotionValueEvent(currentStep, "change", onOnStepChange)

  function onPhaseChange(p) {
    if (p === HIDDEN_PHASE) {
      if (targetChapter.get() >= THE_WATCH && targetChapter.get() <= THE_MOVEMENT) {
        setIsOpen(true)
      } else {
        setIsOpen(false)
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  function onFeatFocus(b) {
    if (b) {
      animate(scale, 1.2, { duration: 0.7, type: "tween", ease: "easeOut" })
    } else {
      animate(scale, 1, { duration: 0.5, type: "tween", ease: "easeOut" })
    }
  }
  useMotionValueEvent(isFocused, "change", onFeatFocus)

  const variants = {
    hidden: { opacity: 0, transition: { duration: 0 } },
    visible: { opacity: 1, transition: { duration: 0 } },
  }

  return isOpen ? (
    <Bground variants={variants} initial='hidden' animate='visible' exit='hidden' style={{ clipPath: inset }}>
      <ImgCont style={{ opacity, transform: transfo }}>
        <Image sources={data[THE_WATCH].background} sizes='100vw' />
      </ImgCont>
    </Bground>
  ) : null
}
