import { useRef } from "react"
import { motion, useInView } from "framer-motion"
import styled from "@emotion/styled"

import { slideUp } from "animations/slideUp"

import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"
import { headline100, headline50, surtitle100, surtitle50, body100, light } from "css/text"
import { Heading as HeadingCN } from "css/classnames"
import { Chapo as ChapoClass } from "css/classnames"
import { Paragraph as ParagraphClass } from "css/classnames"

import Heading, { KickerCN, SubtitleCN } from "components/text/Heading"
import Chapo from "components/text/Chapo"
import TextBlock from "components/text/TextBlock"
import Link from "components/link/Link"
import { textWidths } from "components/push/commons"
import { getGradient } from "components/articles/common"

const Root = styled(motion.div)`
  /* grid-column: 2 / span 5; */
  grid-row: 1 / -1;
  z-index: ${({ pushCenter }) => (pushCenter ? "1" : "unset")};

  /* margin-block-end: 16vw; */
  margin-block: ${({ pushCenter }) => (pushCenter ? "auto" : "0 16vw")};
  display: ${({ pushCenter }) => pushCenter && "flex"};
  flex-direction: ${({ pushCenter }) => pushCenter && "column"};
  align-items: ${({ pushCenter }) => pushCenter && "center"};
  margin-block: ${({ pushCenter }) => pushCenter && "auto"};
  grid-column: ${({ pushCenter }) => (pushCenter ? "2 / span 6" : "2 / span 5")};

  html.prefers-contrast & {
    > a {
      position: relative;
      z-index: 1;
    }
  }

  ${getMediaQuery("m")} {
    /* margin-block-end: 5vw; */
    margin-block: ${({ pushCenter }) => (pushCenter ? "auto" : "0 5vw")};
    /* grid-column: 2 / span 6; */
    grid-column: ${({ pushCenter }) => (pushCenter ? "5 / span 6" : "2 / span 6")};

    &.${textWidths.small} {
      /* grid-column: 2 / span 5; */
      grid-column: ${({ pushCenter }) => (pushCenter ? "5 / span 5" : "2 / span 5")};
    }
    &.${textWidths.medium} {
      /* grid-column: 2 / span 6; */
      grid-column: ${({ pushCenter }) => (pushCenter ? "5 / span 6" : "2 / span 6")};
    }
    &.${textWidths.large} {
      /* grid-column: 2 / span 8; */
      grid-column: ${({ pushCenter }) => (pushCenter ? "4 / span 8" : "2 / span 8")};
    }

    [dir="rtl"] & {
      grid-column: ${({ pushCenter }) => (pushCenter ? "5 / span 6" : "10 / span 4;")};
    }
  }

  ${getMediaQuery("l")} {
    /* margin-block-end: 8vw; */
    margin-block: ${({ pushCenter }) => (pushCenter ? "auto" : "0 8vw")};
  }

  .${HeadingCN}, .text-block {
    text-align: ${({ pushCenter }) => (pushCenter ? "center" : "start")};
  }

  .${KickerCN}, .${SubtitleCN} {
    ${({ pushCenter }) => (pushCenter ? `${surtitle100}` : `${surtitle50}`)};
  }

  hgroup.${HeadingCN} > h2,
  h2.${HeadingCN} {
    ${({ pushCenter }) => (pushCenter ? `${headline100} font-size: clamp(2.5rem, 0.6250rem + 4.6875vw, 6.25rem);` : `${headline50}`)};
    padding: 0;
    margin-block-end: 0.7rem;
    text-wrap: balance;
    html.prefers-contrast & {
      position: relative;
      z-index: 1;
    }
  }

  & > a {
    /* text-decoration: none; */
    /* .dark-theme & {
      color: rgb(var(--pure-white));
    }
    .light-theme & {
      color: rgb(var(--pure-black));
    } */
    /* margin-block-start: ${({ pushCenter }) => pushCenter && "1.5rem"}; */
  }

  .text-block {
    margin-block-end: 0.7rem;
    ${body100}

    html.prefers-contrast & {
      position: relative;
      z-index: 1;
    }
  }

  .${ChapoClass} {
    ${light}
  }

  // A11y
  html.prefers-contrast & {
    color: black !important;
    position: relative;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: 0;
    }
  }
`

function generateGradient(colors) {
  return `linear-gradient(${colors.map(color => `rgba(${color.color.split(" ").join(", ")}, ${color.opacity})`).join(", ")})`
}

export default function BlockText({ isPush, heading, chapo, paragraph, link, text_width, children, pushCenter, gradient, theme }) {
  const console = useConsole()

  const textRef = useRef()

  const isTextInView = useInView(textRef, { margin: "100% 0% 0% 0%" })
  const prefersReducedMotion = process.browser && document.documentElement.classList.contains("prefers-reduced-motion")
  const titleGradient = gradient?.colors ? generateGradient(gradient?.colors) : null

  return (
    <Root
      className={`text-block ${textWidths[text_width || "medium"]}`}
      ref={textRef}
      variants={slideUp}
      initial='hidden'
      animate={prefersReducedMotion ? "show" : isTextInView ? "show" : "hide"}
      pushCenter={pushCenter}
      style={{ "--title-gradient": gradient?.colors.length > 0 ? titleGradient : "rgb(var(--green))" }}
    >
      {heading && <Heading isPush={isPush} pushCenter={pushCenter} className={HeadingCN} {...heading} />}
      {chapo && <Chapo className={ChapoClass} text={chapo} />}
      {paragraph && <TextBlock className={ParagraphClass}>{paragraph}</TextBlock>}
      {link?.label && <Link {...link}>{link.label}</Link>}
      {children}
    </Root>
  )
}
