import Share from "./Share"
import { useConsole } from "contexts/Console"
import ModalContainer, { shareModal } from "components/modal/ModalContainer"

export default function WeChatModal({ onClose, ...props }) {
  const console = useConsole()

  console.verbose("ShareModal(%o)", props)

  return (
    <ModalContainer onClose={onClose} className={`${shareModal} dark-theme`} modalHeading='modal-heading' modalContent='modal-content'>
      <Share {...props} inModal />
    </ModalContainer>
  )
}
