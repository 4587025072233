import { createContext, useContext } from "react"
import { useMotionValue } from "framer-motion"

import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"

export const Context = createContext()

export const FeatureProvider = ({ children }) => {
  const console = useConsole()
  const env = useEnv()

  const targetFeatFocus = useMotionValue(-1)
  const isFocused = useMotionValue(false)

  return <Context.Provider value={{ targetFeatFocus, isFocused }}>{children}</Context.Provider>
}

export const useFeature = () => useContext(Context)
