import { useConsole } from "contexts/Console"

import Button from "../button/Button"
import { PopinContextProvider, usePopin } from "./PopinContextProvider"
import PopinEdito from "./PopinEdito"
import { waTriggerEvent } from "components/analytics/DigitalDataLayer"

function PopBtn({ children, icon, style, label, aria_label, theme, describedBy, className, isHubGrid, handlePress }) {
  const cl = style || "icon translucent-dark"
  const clName = theme ? `${className} ${theme} ${cl}` : `${className} ${cl}`

  const { onOpen } = usePopin()
  const handlePopin = () => {
    onOpen()
    handlePress?.()
    if (isHubGrid)
      waTriggerEvent({
        eventType: "Click",
        eventName: "rftcClicked",
      })
  }

  return (
    <Button className={clName} aria_label={aria_label} onPress={handlePopin} icon={icon || "plus"} aria_describedby={describedBy}>
      {children}
      {label}
    </Button>
  )
}

export default function PopinButton({ children, icon, iconColor, style, label, aria_label, theme, preload, reference, describedBy, className, isHubGrid, handlePress }) {
  const console = useConsole()
  const uid = reference?.[0]?.uid

  console.verbose("PopinButton(%o)", { uid })
  return (
    uid && (
      <PopinContextProvider background={reference[0].background} uid={uid} preload={preload}>
        <PopBtn
          className={className}
          label={label}
          theme={theme}
          style={style}
          icon={icon}
          iconColor={iconColor}
          aria_label={aria_label}
          describedBy={describedBy}
          handlePress={handlePress}
          isHubGrid={isHubGrid}
        >
          {children}
        </PopBtn>
        <PopinEdito label={label} />
      </PopinContextProvider>
    )
  )
}
