import styled from "@emotion/styled"
import { motion, AnimatePresence } from "framer-motion"
import { waTriggerEvent } from "components/analytics/DigitalDataLayer"
import ShareModal from "components/share/ShareModal"
import { Icon } from "components/icon/Icon"
import { buttonIcon, opaqueWhite } from "css/buttons"
import { createPortal } from "react-dom"
import { useLayoutEffect, useState } from "react"
import useSSR from "hooks/useSSR"

const Root = styled.div``

const Button = styled(motion.button)`
  ${buttonIcon}
  ${opaqueWhite}
  box-shadow: 6px 4px 24px 0 rgba(0, 0, 0, 0.2);
  transition: none;

  @media (hover: hover) {
    :hover {
      --container: linear-gradient(45deg, rgb(var(--gradient-dark-green-from)), rgb(var(--gradient-dark-green-to)));
      --text: rgb(var(--pure-white));
    }
  }
`

export default function ShareButton(props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const ssr = useSSR()
  const navigator = process.browser && global?.navigator
  const isNativeAllowed = process.browser && global?.navigator?.canShare && global?.matchMedia("(hover: none) and (pointer: coarse)").matches
  const bodyElement = process.browser && document.body
  const { handleState, handleClick, wa = {} } = props
  const { position = "pre-footer" } = wa

  const onOpenModal = e => {
    e.preventDefault()
    handleClick?.(e)
    setIsModalOpen(true)

    waTriggerEvent({
      eventType: "Click",
      eventName: "shareClicked",
    })
  }

  const onCloseModal = () => {
    setIsModalOpen(false)
  }

  const onClick = e => {
    e.preventDefault()

    try {
      navigator?.share({
        title: document?.title,
        url: window?.location.href,
      })
    } catch (err) {
      console.error(err)
    }

    waTriggerEvent({
      eventName: ["shareClicked", "share"],
      eventType: "Click",
      shareInfo: {
        type: "mobile-share",
        shareLinkName: "no linkName",
        position: position,
        platform: "no plateform",
      },
    })
  }

  useLayoutEffect(() => {
    handleState?.(isModalOpen)
  }, [isModalOpen])

  if (isNativeAllowed) {
    return (
      <Root {...props} label={undefined}>
        <Button onClick={onClick}>
          <Icon type='share' />
          <span>{props.label}</span>
        </Button>
      </Root>
    )
  }

  return (
    !ssr && (
      <Root {...props} label={undefined}>
        <Button onClick={onOpenModal} onTouchEnd={onOpenModal}>
          <Icon type='share' />
          <span>{props.label}</span>
        </Button>

        {process.browser && createPortal(<AnimatePresence>{isModalOpen && <ShareModal {...props} onClose={onCloseModal} />}</AnimatePresence>, bodyElement)}
      </Root>
    )
  )
}
