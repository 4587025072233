import { useState } from "react"
import { AnimatePresence, motion, useIsPresent, useMotionTemplate, useMotionValue, useMotionValueEvent, useTransform } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

// import Image from "components/media/ImageCLD"
import { useExp, MODEL_A, MODEL_B, THE_INTRODUCTION } from "../expcontext"
import { useSwitcher, VOID_PHASE, HIDDEN_PHASE, BP_CHANGE, LOADED_PHASE, SWITCH_PHASE } from "../switchcontext"
import GlobalLoader from "./GlobalLoader"

const Bground = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: 10;
  pointer-events: all;
`

const ImgCont = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  user-select: none;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

function ForegroundContent({ straight }) {
  const console = useConsole()

  const { background, scenario, foregrounds, currentChapter } = useExp()
  const { resetPhase } = useSwitcher()

  const isPresent = useIsPresent()

  const zIndexa = useMotionValue(scenario.get() === MODEL_A ? 1 : 0)
  const zIndexb = useMotionValue(scenario.get() === MODEL_B ? 1 : 0)

  const opacitya = useMotionValue(scenario.get() === MODEL_A ? 1 : 0)
  const opacityb = useMotionValue(scenario.get() === MODEL_B ? 1 : 0)

  const linearGrada = useTransform(currentChapter, s => (s === THE_INTRODUCTION ? "rgba(255 255 255 / 0.4), rgba(255 255 255 / 0.7)" : "#144467, #345f80"))
  const linearGradb = useTransform(currentChapter, s => (s === THE_INTRODUCTION ? "rgba(255 255 255 / 0.4), rgba(255 255 255 / 0.7)" : "#e38b5c, #b47b64"))

  const backgrounda = useMotionTemplate`linear-gradient(${linearGrada})`
  const backgroundb = useMotionTemplate`linear-gradient(${linearGradb})`

  function onAnimComp(e) {
    if (resetPhase.get() >= 0) {
      resetPhase.set(SWITCH_PHASE)
      return
    }
    if (isPresent && resetPhase.get() !== BP_CHANGE) {
      resetPhase.set(HIDDEN_PHASE)
    }
  }

  function onPhaseChange(p) {
    if (p === LOADED_PHASE) {
      resetPhase.set(VOID_PHASE)
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  const variants = v => ({
    visible: { opacity: 1, transition: { duration: v ? 0 : 0.6 } },
    hidden: { opacity: 0, transition: { duration: 0.3 } },
  })

  return (
    <Bground variants={variants(straight)} initial='hidden' animate='visible' exit='hidden' onAnimationComplete={onAnimComp}>
      <ImgCont style={{ opacity: opacitya, zIndex: zIndexa, background: backgrounda }}>
        {/* <Image sources={foregrounds[MODEL_A]} sizes='100vw' /> */}
        {/* <Image sources={background[MODEL_A].asset} sizes='100vw' /> */}
      </ImgCont>
      <ImgCont style={{ opacity: opacityb, zIndex: zIndexb, background: backgroundb }}>
        {/* <Image sources={foregrounds[MODEL_B]} sizes='100vw' /> */}
        {/* <Image sources={background[MODEL_B].asset} sizes='100vw' /> */}
      </ImgCont>
      {/* <Loader /> */}
      <GlobalLoader />
    </Bground>
  )
}

export default function Foreground() {
  const console = useConsole()

  const { targetChapter, scenario, targetScenario } = useExp()
  const { resetPhase } = useSwitcher()

  const [isVisible, setIsVisible] = useState(scenario.get() >= 0)
  const [straight, setStraight] = useState(isVisible)

  function onTargetChange(t) {
    if (t >= 0) {
      setIsVisible(true)
    }
  }
  useMotionValueEvent(targetChapter, "change", onTargetChange)

  function onScenario(s) {
    if (s !== scenario.get()) setIsVisible(true)
  }
  useMotionValueEvent(targetScenario, "change", onScenario)

  function onPhaseChange(p) {
    if (p >= 0) {
      setStraight(false)
      setIsVisible(true)
    } else if (p === VOID_PHASE) {
      setIsVisible(false)
    } else if (p === BP_CHANGE) {
      setIsVisible(true)
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  return <AnimatePresence>{isVisible ? <ForegroundContent key='foreground-key' straight={straight} /> : null}</AnimatePresence>
}
