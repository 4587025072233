import { forwardRef, useRef, useImperativeHandle, useEffect, useState } from "react"
import { motion, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"
import { useExp } from "./expcontext"
import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"

import { getVideo, videoReady } from "./utils"

const Vid = styled(motion.video)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  inset: 0;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  background: rgba(0 0 0 / 0.2);
  z-index: 0;
`

function Source({ src, type, width, quality, path, policy, ...rest }) {
  return <source {...rest} src={src} type={type} />
}

function Honeydeo({ sources }, handle) {
  const console = useConsole()
  const env = useEnv()

  const { prefersReducedMotion, device } = useExp()

  let portrait_index = sources?.reduce((a, v, i) => {
    if (v?.metadata?.v7_type?.[0] === "portrait") a = i
    return a
  }, -1)
  if (portrait_index < 0) {
    // no metadata found
    portrait_index = sources?.reduce((a, v, i) => {
      if (v.width / v.height <= 1) a = i
      return a
    }, -1)
  }
  if (portrait_index < 0) {
    // no ratio found
    portrait_index = 0
  }
  const landscape_index = sources.length < 2 ? portrait_index : 1 - portrait_index

  const resizeWidth = width => (width > 2880 ? 2880 : width)
  const widthLandscape = resizeWidth(sources?.[landscape_index]?.width)
  const widthPortrait = resizeWidth(sources?.[portrait_index]?.width)

  const { src: srcP, poster: postP } = getVideo({ src: sources?.[portrait_index]?.public_id }, env)({ width: widthPortrait, quality: "auto:eco" })
  const { src: srcL, poster: postL } = getVideo({ src: sources?.[landscape_index]?.public_id }, env)({ width: widthLandscape, quality: "auto:eco" })

  const srcs = [
    { src: srcP, poster: postP },
    { src: srcL, poster: postL },
  ]

  const [srcIndex, setSrcIndex] = useState(device.get())

  function onDevice(d) {
    setSrcIndex(d)
  }
  useMotionValueEvent(device, "change", onDevice)

  useImperativeHandle(handle, () => ctx)

  const ctx = {
    play,
    stop,
    ready,
  }

  const vidRef = useRef()

  function play() {
    if (prefersReducedMotion) return stop()
    vidRef?.current?.play()
  }
  function stop() {
    vidRef?.current?.pause()
  }
  function ready(mv, ev) {
    return videoReady(vidRef.current, ev, mv)
  }

  return (
    <>
      <Vid
        key={srcs[srcIndex].src}
        ref={vidRef}
        poster={srcs[srcIndex].poster}
        className='honeydeo'
        controls={false}
        controlsList='nofullscreen nodownload'
        disablePictureInPicture
        disableRemotePlayback
        autoPlay={!prefersReducedMotion}
        muted={true}
        playsInline
        loop={true}
        preload='auto'
      >
        {srcs[srcIndex] && <Source src={srcs[srcIndex].src} type='video/mp4' />}
      </Vid>
      <Overlay />
    </>
  )
}

export default forwardRef(Honeydeo)
