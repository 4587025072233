import { animate, motion, useMotionValue, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { headline70, headline50 } from "css/text"
import { fullGrid } from "css/grid"

import SwitcherButtons from "./SwitcherButtons"
import { useFeature } from "./featurecontext"
import { useHub } from "./hubcontext"
import getMediaQuery from "css/breakpoints"

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  ${fullGrid}

  grid-template-rows:
    [doc-start]
    var(--strip-height)
    [main-start]
    var(--sharemargin)
    min-content
      min-content
      auto
      auto
      [ro main-end]
      var(--strip-height)
      [doc-end];
`

const BlockText = styled(motion.div)`
  grid-column: 2 / span 6;
  grid-row: 3;

  & > h3 {
    width: calc(100% - var(--grid-col-unit) - var(--grid-gap));
  }

  ${getMediaQuery("m")} {
    grid-column: 2 / span 6;
  }
  z-index: calc(var(--canvindex) - 4);
`

const Heading = styled(motion.h3)`
  ${headline70};
  position: relative;
  user-select: none;

  @media (max-width: 375px) {
    ${headline50}
  }

  html.prefers-contrast & {
    color: black !important;
    -webkit-text-fill-color: inherit !important;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

export default function BlockSwitcher() {
  const console = useConsole()

  const { isFocused } = useFeature()
  const { heading } = useHub()

  const opacity = useMotionValue(1)

  function onTargetFocus(b) {
    animate(opacity, b ? 0 : 1, { duration: 0.4, type: "tween", ease: "easeOut" })
  }
  useMotionValueEvent(isFocused, "change", onTargetFocus)

  const variBlockTxt = {
    hidden: { opacity: 0, y: "10%", transition: { duration: 0 } },
    visible: { opacity: 1, y: "0%", transition: { duration: 0.5, delay: 0.3, type: "tween", ease: "easeOut" } },
  }

  return (
    <Container>
      <BlockText variants={variBlockTxt} initial='hidden' animate='visible' exit='hidden'>
        <Heading style={{ opacity }}>{heading}</Heading>
      </BlockText>
      <SwitcherButtons />
    </Container>
  )
}
