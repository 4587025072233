import { useEffect, useState } from "react"
import { animate, AnimatePresence, motion, useMotionValueEvent, useTransform } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"

import { bold, legend80 } from "css/text"
import getMediaQuery from "css/breakpoints"
import { buttonContrastMode } from "css/buttons"

import { RATIOS, SIZES, useExp } from "../expcontext"
import { useHub } from "../the-watch/hubcontext"

const Container = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 10;
  pointer-events: none;
`

const AxisCont = styled(motion.div)`
  border-radius: 50%;
  border: 2px white solid;
  position: absolute;
  inset: 50%;
  pointer-events: none;
  mask-image: linear-gradient(rgba(0, 0, 0, 1), transparent);

  html.prefers-contrast & {
    mask-image: unset;
    outline: 2px solid black;
    outline-offset: -4px;
    box-shadow: 0 0 0 2px black;
  }
`

const PinCont = styled(motion.div)`
  position: absolute;
  inset: 50%;
  width: 44px;
  height: 44px;
  z-index: 10;

  --sizedot: 40px;
  ${getMediaQuery("m")} {
    --sizedot: 52px;
  }
`

const PinButton = styled(motion.button)`
  ${buttonContrastMode}
  width: var(--sizedot);
  height: var(--sizedot);
  margin-inline-start: calc(var(--sizedot) / -2);
  margin-block-start: calc(var(--sizedot) / -2);
  border-radius: 50%;
  background: rgba(255 255 255 / 0.5);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  border: none;
  z-index: 2;

  html.prefers-contrast & {
    & > div {
      background: black;
    }
  }

  &.focus-visible {
    outline: 2px solid rgb(var(--focus, 0 255 255) / 1);
    outline-offset: 2px;
  }
`

const Dot = styled(motion.div)`
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
`

const Instruct = styled(motion.p)`
  position: relative;
  ${legend80};
  ${bold};
  color: white;
  text-align: start;
  width: 80px;
  height: fit-content;
  margin-block-start: calc(var(--sizedot) * 1);
  margin-inline-start: calc(var(--sizedot) / -1);
  ${getMediaQuery("m")} {
    width: 90px;
  }

  html.prefers-contrast & {
    color: black !important;
      -webkit-text-fill-color: inherit !important;

      ::after {
        background: white;
        content: "";
        height: calc(100% + 30px);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 20px);
        z-index: -1;
      }
    }
  }
`

const ELPOS = [
  { a: 3, w: 242, h: 85, x: 25, y: 170, r: -12 },
  { a: 3, w: 242, h: 85, x: 25, y: 170, r: -12 },
]

function Indic({ progress, onPress }) {
  const console = useConsole()
  const { width, height } = useViewport()

  const { instruction, device } = useExp()
  const { max } = useHub()

  const globalRotation = useTransform(progress, [0, max], [0, 360])
  const normAngle = useTransform([globalRotation, device], ([r, d]) => ((ELPOS[d].a + r) % 360) - 180)

  const elWidthCorrected = useTransform([width, height, device], ([w, h, d]) => {
    const nw = RATIOS[device.get()] < w / h ? w : h * RATIOS[device.get()] // mode cover
    return ELPOS[d].w * (nw / SIZES[d].width)
  })
  const elHeightCorrected = useTransform([width, height, device], ([w, h, d]) => {
    const nh = RATIOS[device.get()] < w / h ? w / RATIOS[device.get()] : h // mode cover
    return ELPOS[d].h * (nh / SIZES[d].height)
  })
  const elXCorrected = useTransform([width, height, device], ([w, h, d]) => {
    const nw = RATIOS[d] < w / h ? w : h * RATIOS[d] // mode cover
    return ELPOS[d].x * (nw / SIZES[d].width)
  })
  const elYCorrected = useTransform([width, height, device], ([w, h, d]) => {
    const nh = RATIOS[d] < w / h ? w / RATIOS[d] : h // mode cover
    return ELPOS[d].y * (nh / SIZES[d].height)
  })

  const elw = useTransform(elWidthCorrected, w => w * 2)
  const elh = useTransform(elHeightCorrected, h => h * 2)
  const elx = useTransform([normAngle, elWidthCorrected], ([nr, ew]) => ew * Math.cos((nr * Math.PI) / 180) + ew)
  const ely = useTransform([normAngle, elHeightCorrected], ([nr, eh]) => eh * Math.sin((nr * Math.PI) / 180) + eh)

  const eltransform = useTransform([elx, ely, device], ([x, y, d]) => `translate3d(${x}px,${y}px,0) rotateZ(${-ELPOS[d].r}deg)`)
  const elipstransform = useTransform(
    [elXCorrected, elYCorrected, device],
    ([ex, ey, d]) => `translateX(calc(-50% + ${ex}px)) translateY(calc(-50% + ${ey}px)) rotateZ(${ELPOS[d].r}deg)`
  )

  const variants = {
    hidden: { opacity: 0, transition: { duration: 0.2 } },
    visible: { opacity: 1, transition: { duration: 0.3, type: "tween", ease: "easeOut" } },
  }

  return (
    <Container variants={variants} initial='hidden' animate='visible' exit='hidden'>
      <AxisCont style={{ transform: elipstransform, width: elw, height: elh }} />
      <PinCont style={{ transform: elipstransform, width: elw, height: elh }}>
        <PinButton type='button' className='watch-indic-btn' style={{ transform: eltransform }} onClick={onPress}>
          <Dot />
        </PinButton>
        <Instruct style={{ transform: eltransform }}>{instruction.watch}</Instruct>
      </PinCont>
    </Container>
  )
}

export default function TheWatchIndic({ progress, dragHasStarted, setShowUi, onClickCB }) {
  const console = useConsole()

  const { isRevealed } = useExp()

  const [isVisible, setIsVisible] = useState(true)

  function onStartDrag(b) {
    if (b) {
      setShowUi(true)
      setTimeout(() => setIsVisible(false), 300)
    }
  }
  useMotionValueEvent(dragHasStarted, "change", onStartDrag)

  function onRevealed(b) {
    if (b) {
      animate(progress, 15 * 25, { delay: 0.2, repeatDelay: 0.3, type: "tween", ease: "easeInOut", repeat: 1, repeatType: "reverse", duration: 0.6 })
    }
  }
  useMotionValueEvent(isRevealed, "change", onRevealed)

  useEffect(() => {
    if (isRevealed.get()) {
      animate(progress, 15 * 25, { delay: 0.2, repeatDelay: 0.3, type: "tween", ease: "easeInOut", repeat: 1, repeatType: "reverse", duration: 0.6 })
    }
  }, [])

  return <AnimatePresence>{isVisible ? <Indic progress={progress} onPress={onClickCB} /> : null}</AnimatePresence>
}
