import { useEffect, useLayoutEffect } from "react"
import { motion, useMotionValue } from "framer-motion"
import styled from "@emotion/styled"

import getMediaQuery from "css/breakpoints"
import { buttonIcon, translucentDark } from "css/buttons"
import { fullGrid } from "css/grid"

import { Icon } from "components/icon/Icon"

import { useLightBox } from "./context"
import { useDictionary } from "contexts/Dictionary"

const ArrowsContainer = styled(motion.div)`
  position: relative;
  ${fullGrid}
  z-index: 1;
  pointer-events: none;
  height: fit-content;
  align-self: center;
  grid-row: 1;
  grid-column: doc;
`

const Arrow = styled(motion.button)`
  --arrow-size: 40px;
  ${getMediaQuery("m")} {
    --arrow-size: 44px;
  }
  ${buttonIcon}
  ${translucentDark}

  pointer-events: all;
  display: flex;
  border: 0;
  border-radius: 50%;

  width: var(--arrow-size);
  height: var(--arrow-size);

  &.previous {
    grid-column: col 1;
    justify-self: start;
  }
  &.next {
    grid-column: col 6;
    justify-self: end;
  }
  ${getMediaQuery("m")} {
    &.previous {
      justify-self: center;
      grid-column: doc-start;
    }
    &.next {
      justify-self: center;
      grid-column: col 13;
    }
  }

  &.previous svg {
    transform: scale(-1);
  }
  &.previous:dir(rtl) svg {
    transform: scale(1);
  }
  &.next:dir(rtl) svg {
    transform: scale(-1);
  }
`

export default function Arrows({ slideNum }) {
  const { currentIndex, targetSlide } = useLightBox()
  const dictionary = useDictionary()

  const prevVisible = useMotionValue("hidden")
  const nextVisible = useMotionValue("visible")

  function onPrevious() {
    targetSlide.set(currentIndex.get() - 1)
  }
  function onNext() {
    targetSlide.set(currentIndex.get() + 1)
  }

  function handleVisibility(i) {
    if (i === slideNum - 1) {
      nextVisible.set("hidden")
    } else {
      nextVisible.set("visible")
    }
    if (i === 0) {
      prevVisible.set("hidden")
    } else {
      prevVisible.set("visible")
    }
  }

  function onOpenChange(i) {
    handleVisibility(i)
  }
  useEffect(() => currentIndex.onChange(onOpenChange))

  useLayoutEffect(() => {
    handleVisibility(currentIndex.get())
  }, [])

  return (
    <ArrowsContainer className='dark-theme'>
      <Arrow className='previous' onClick={onPrevious} style={{ visibility: prevVisible }} aria-label={dictionary.ariaLabelPreviousPhoto()}>
        <Icon type={"moveNext"} />
      </Arrow>
      <Arrow className='next' onClick={onNext} style={{ visibility: nextVisible }} aria-label={dictionary.ariaLabelNextPhoto()}>
        <Icon type={"moveNext"} />
      </Arrow>
    </ArrowsContainer>
  )
}
