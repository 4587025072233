import { useRef, useLayoutEffect } from "react"
import { useMotionValueEvent, motion, useTransform, useMotionValue, animate, useMotionTemplate, useSpring } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { headline70, headline50, surtitle100, body70, bold } from "css/text"
import getMediaQuery from "css/breakpoints"

import Honeydeo from "./Honeydeo"
import Image from "components/media/ImageCLD"
import { useExp, THE_INTRODUCTION } from "./expcontext"
import { HIDDEN_PHASE, SWITCH_PHASE, useSwitcher, VOID_PHASE } from "./switchcontext"
import { DURA } from "./ui/SvgHoneycomb"
import VideoScreen from "./VideoScreen"
import { fullGrid } from "css/grid"

const ImageScreen = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  z-index: 0;
  display: grid;
  grid-template-rows: 100%;
  align-items: center;
  justify-items: center;
  overflow: hidden;

  mask-clip: no-clip;
  mask-size: cover;
  mask-mode: view-box;

  &.scen-0 {
    mask-image: url(#polymaskone);
  }
  &.scen-1 {
    mask-image: url(#polymasktwo);
  }
`

const HoneyCont = styled(motion.div)`
  z-index: 1;
  display: grid;
  .scen-0 & {
    grid-row: 3 / span 3;
    grid-column: 1 / span 3;
  }
  .scen-1 & {
    grid-row: 2 / span 2;
    grid-column: 3 / span 3;
  }

  ${getMediaQuery("m")} {
    .scen-1 & {
      grid-row: 1 / span 3;
    }
  }

  & img,
  & video.honeydeo {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    pointer-events: none;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  }
`

const Vignette = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
`

const ImgCont = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
`

const TextBlock = styled(motion.div)`
  width: 100%;
  grid-row: -2 / -1;
  grid-column: 1 / -1;
  height: 100dvh;
  ${fullGrid}

  grid-template-rows:
    [doc-start]
    var(--strip-height)
    [main-start]
    var(--sharemargin)
    repeat(4, [ro] minmax(0, 1fr))
    [ro main-end]
    var(--strip-height)
    [doc-end];
`

const TitleBlock = styled(motion.div)`
  position: relative;
  display: grid;
  grid-template-rows: min-content min-content;
  z-index: 0;
  grid-column: 2 / span 6;
  grid-row: 3;
  height: fit-content;
  pointer-events: none;
  & > h3 {
    margin-block-end: 10px;
    width: calc(100% - var(--grid-col-unit) - var(--grid-gap));
  }
  ${getMediaQuery("m")} {
    grid-column: 2 / span 8;
  }

  html.prefers-contrast & {
    color: black !important;
    -webkit-text-fill-color: inherit !important;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

const Kicker = styled.p`
  ${surtitle100}
`

const Title = styled.h3`
  ${headline70}

  @media (max-width: 375px) {
    ${headline50}
  }
  user-select: none;
`

const Paragraph = styled(motion.p)`
  ${body70}
  &.isbold {
    ${bold}
  }
  user-select: none;
`

export default function Screen({ index, video, heading, kicker, paragraph, honeycomb, gridTemplate, isHover, ...props }) {
  const console = useConsole()

  const { scenario, currentChapter, targetChapter, videoProg, pagisible, prefersReducedMotion } = useExp()
  const { resetPhase } = useSwitcher()

  const handleVid = useRef()
  const durationChange = useMotionValue(0)
  const handleHonVid = useRef()
  const honCanplaythrough = useMotionValue(0)

  const showPagin = useTransform(videoProg, p => p > 0.05)
  const displayed = useRef(false)
  const showTitle = useTransform(videoProg, p => p > 0.15)

  const zIndex = useMotionValue(0)
  const y = useMotionValue("10%")
  const opacitytxt = useMotionValue(0)
  const opacimg = useMotionValue(1)
  const opacscreen = useMotionValue(1)
  const opachoney = useMotionValue(1)
  const blur = useMotionValue(0)
  const filter = useMotionTemplate`blur(${blur}px)`

  const honImgOpac = useTransform([isHover, honCanplaythrough], ([h, p]) => (p && h === index ? 0 : 1))
  const imgOpac = useSpring(honImgOpac, { stiffness: 800, damping: 100 })

  const straight = scenario.get() >= 0

  function onScenarioChange(i) {
    if (currentChapter.get() > THE_INTRODUCTION) return
    if (i === -1) {
      zIndex.set(0)
    }
    handleHonVid.current?.stop()
    handleVid.current?.rewind()
    if (i === index) {
      zIndex.set(1)
      opacimg.set(0)
      handleVid.current.play()
      if (scenario.prev === -1) {
        animate(opachoney, 0, { duration: 0.35 })
      } else {
        opachoney.set(0)
      }
    } else {
      if (scenario.prev === -1) {
        animate(blur, 15, { duration: DURA })
        animate(opacscreen, 0, { duration: DURA })
      } else {
        blur.set(15)
        opacscreen.set(0)
      }
    }
  }
  useMotionValueEvent(scenario, "change", onScenarioChange)

  function onShowTitle(b) {
    if (b && scenario.get() === index) {
      animate(y, "0%", { duration: 3 })
      animate(opacitytxt, 1, { duration: 0.5 })
    }
  }
  useMotionValueEvent(showTitle, "change", onShowTitle)

  function onShowPagin(b) {
    if (displayed.current) return
    pagisible.set(b)
    if (b) displayed.current = true
  }
  useMotionValueEvent(showPagin, "change", onShowPagin)

  function onTargetChange(t) {
    if (t >= 0) {
      handleVid.current.stop()
    }
  }
  useMotionValueEvent(targetChapter, "change", onTargetChange)

  function onPhaseChange(p) {
    if (p === SWITCH_PHASE) {
      const [s] = resetPhase.prev.split(".")
      const scen = Number(s)
      handleHonVid.current?.stop()
      // handleVid.current?.rewind()
      if (scen === index) {
        zIndex.set(1)
        opacimg.set(0)
        opacscreen.set(1)
        opachoney.set(0)
        blur.set(0)
        pagisible.set(true)
      } else {
        blur.set(15)
        opacscreen.set(0)
      }
    } else if (p === HIDDEN_PHASE) {
      if (targetChapter.get() === THE_INTRODUCTION) {
        if (scenario.get() === index) {
          handleVid.current?.rewind()
          handleVid.current?.play()
        }
      } else {
        handleVid.current?.stop()
        // handleVid.current?.rewind()
        videoProg.set(1)
      }
    } else if (p === VOID_PHASE) {
      if (targetChapter.get() === THE_INTRODUCTION) {
        handleVid.current?.rewind()
        handleVid.current?.play()
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  useLayoutEffect(() => {
    if (straight) {
      if (scenario.get() === index) {
        zIndex.set(1)
        opacimg.set(0)
        opacscreen.set(1)
        opachoney.set(0)
        blur.set(0)
        pagisible.set(true)
      } else {
        blur.set(15)
        opacscreen.set(0)
      }
    }

    // keep vignette while video not ready to play
    const { current: vid } = handleHonVid
    if (!vid) return
    return vid.ready(honCanplaythrough, "canplay")
  }, [])

  function onDurationChange() {
    handleVid.current.duration(3)
  }
  useMotionValueEvent(durationChange, "change", onDurationChange)

  useLayoutEffect(() => {
    if (!prefersReducedMotion) return
    const { current: vid } = handleVid
    if (!vid) return
    return vid.ready(durationChange, "durationchange")
  }, [prefersReducedMotion])

  return (
    <ImageScreen style={{ zIndex, opacity: opacscreen, filter }} className={`scen-${index}`}>
      <VideoScreen ref={handleVid} sources={video} videoProg={videoProg} />
      <ImgCont style={{ gridTemplate }}>
        {!straight ? (
          <HoneyCont style={{ opacity: opachoney }}>
            <Honeydeo ref={handleHonVid} sources={honeycomb.video} />
            <Vignette style={{ opacity: imgOpac }} initial={{ opacity: 1 }}>
              <Image className='honeymage' sources={honeycomb.image} sizes='60vw' />
            </Vignette>
          </HoneyCont>
        ) : null}
      </ImgCont>
      <TextBlock>
        <TitleBlock className='title-block' style={{ y, opacity: opacitytxt }}>
          {kicker ? <Kicker>{kicker}</Kicker> : null}
          <Title>{heading}</Title>
          {paragraph.map(({ content, bold }, i) => (
            <Paragraph key={`para-${i.toString()}`} className={bold ? "isbold" : ""}>
              {content}
            </Paragraph>
          ))}
        </TitleBlock>
      </TextBlock>
    </ImageScreen>
  )
}
