import { forwardRef, useEffect, useLayoutEffect, useRef, useImperativeHandle } from "react"
import { motion, useMotionValue, useAnimationControls, useTransform } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"
import useConstant from "hooks/useConstant"
import getMediaQuery, { media } from "css/breakpoints"

import Image from "components/media/ImageCLD"
import FigCaption from "./FigCaption"

import { normalizeWheel, midpoint, distance, WHEEL_SCALE_SPEEDUP, WHEEL_TRANSLATION_SPEEDUP } from "./gesture"

const ImageContainer = styled(motion.figure)`
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
`

const Div = styled(motion.div)`
  height: 100%;
  width: fit-content;
  position: relative;
  grid-column: 1;
  grid-row: 1;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    max-width: unset;
    display: block;
  }
`

function Box({ sources, fig_caption, imageAlts }, handle) {
  const console = useConsole()

  const ref = useRef()
  const contRef = useRef()

  const timer = useRef(null)
  const gestureActive = useRef(false)
  const prevTouchScale = useRef(0)
  const isDoubleTap = useRef(false)
  const prevTouchPan = useConstant(() => new DOMPoint())

  const rect = useConstant(() => new DOMRect())
  const curpt = useConstant(() => new DOMPoint())
  const minpt = useConstant(() => new DOMPoint())
  const maxpt = useConstant(() => new DOMPoint())
  const initialTouches = useConstant(() => ({ t1: new DOMPoint(), t2: new DOMPoint() }))
  const gesture = useConstant(
    () =>
      new Map([
        ["trans", { x: 0, y: 0 }],
        ["scale", 1],
      ])
  )

  const viewport = useViewport()

  const animCtrl = useAnimationControls()

  let portrait_index = 0
  const portrait = sources.find(o => o.metadata?.v7_type?.[0] === "portrait")
  if (!!portrait) portrait_index = sources.indexOf(portrait)
  if (sources.length > 1 && !viewport.isMobile.get()) portrait_index = 1 - portrait_index

  const { width: wimgw, height: himgh } = sources[portrait_index]
  const imgw = useMotionValue(wimgw)
  const imgh = useMotionValue(himgh)

  /*  const imgw = useTransform(viewport.isMobile, m =>
      sources.length === 1
        ? sources[0].width
        : sources.find(o => o.media === media[m ? "portrait" : "landscape"])?.width || sources.find(o => o.media === null)?.width
    )
    const imgh = useTransform(viewport.isMobile, m =>
      sources.length === 1
        ? sources[0].height
        : sources.find(o => o.media === media[m ? "portrait" : "landscape"])?.height || sources.find(o => o.media === null)?.height
    )*/
  const ratio = useTransform([imgw, imgh], ([w, h]) => `${w} / ${h}`)

  const width = useTransform([viewport.width, viewport.height, imgw, imgh], ([w, h, iw, ih]) => (w / h < iw / ih ? "100vw" : "fit-content"))
  const height = useTransform([viewport.width, viewport.height, imgw, imgh], ([w, h, iw, ih]) => (w / h < iw / ih ? "fit-content" : "100vh"))

  function startGesture() {
    gestureActive.current = true
    animCtrl.set({
      x: gesture.get("trans").x,
      y: gesture.get("trans").y,
      scale: gesture.get("scale"),
    })
  }

  function doGesture() {
    animCtrl.set({
      x: gesture.get("trans").x,
      y: gesture.get("trans").y,
      scale: gesture.get("scale"),
    })
  }

  function endGesture() {
    gestureActive.current = false
    animCtrl.set({
      x: gesture.get("trans").x,
      y: gesture.get("trans").y,
      scale: gesture.get("scale"),
    })

    curpt.x = gesture.get("trans").x
    curpt.y = gesture.get("trans").y
  }

  function zoom(h = viewport.height.get()) {
    const nextScale = gesture.get("scale") <= 1 ? 2 : 1
    setMinMax(nextScale, h)
    gesture.set("scale", nextScale)
    gesture.set("trans", {
      x: Math.max(maxpt.x, Math.min(curpt.x, minpt.x)),
      y: Math.max(maxpt.y, Math.min(curpt.y, minpt.y)),
    })
    animCtrl.start({
      x: gesture.get("trans").x,
      y: gesture.get("trans").y,
      scale: nextScale,
      transition: { type: "tween", duration: 0.3 },
    })
    endGesture()
  }

  function setMinMax(scale, h = viewport.height.get()) {
    const os = { x: (rect.width * scale) / 2, y: (rect.height * scale) / 2 }
    const or = { x: Math.max(viewport.width.get() / 2 - os.x, 0), y: Math.max(h / 2 - os.y, 0) }
    minpt.x = os.x - rect.width / 2 - rect.x + or.x
    minpt.y = os.y - rect.height / 2 - rect.y + or.y
    maxpt.x = rect.width / 2 + rect.x - os.x - or.x
    maxpt.y = rect.height / 2 + rect.y - os.y - or.y
  }

  function onwheel(e) {
    const [dx, dy] = normalizeWheel(e)

    if (!gestureActive.current) {
      startGesture()
    }

    if (e.ctrlKey) {
      // PINCH GESTURE || DOUBLE TAP TRACK PAD

      e.preventDefault()

      if (dx === -0 && dy === -0) {
        zoom()
        return
      }

      const factor = dy <= 0 ? 1 - (WHEEL_SCALE_SPEEDUP * dy) / 100 : 1 / (1 + (WHEEL_SCALE_SPEEDUP * dy) / 100)
      const scaleConstrain = Math.max(1, Math.min(gesture.get("scale") * factor, 2))
      gesture.set("scale", scaleConstrain)

      setMinMax(scaleConstrain)

      gesture.set("trans", {
        x: Math.max(maxpt.x, Math.min(curpt.x, minpt.x)),
        y: Math.max(maxpt.y, Math.min(curpt.y, minpt.y)),
      })
    } else {
      // PAN GESTURE

      if (gesture.get("scale") <= 1) {
        return
      } else {
        e.preventDefault()
      }

      const nextX = gesture.get("trans").x - WHEEL_TRANSLATION_SPEEDUP * dx
      const nextY = gesture.get("trans").y - WHEEL_TRANSLATION_SPEEDUP * dy

      gesture.set("trans", {
        x: Math.max(maxpt.x, Math.min(nextX, minpt.x)),
        y: Math.max(maxpt.y, Math.min(nextY, minpt.y)),
      })
    }

    doGesture()

    if (timer.current) {
      window.clearTimeout(timer.current)
    }
    timer.current = window.setTimeout(() => endGesture(), 200)
  }

  function ontouchend(e) {
    ref.current.removeEventListener("touchmove", ontouchmove, { passive: false })
    endGesture()
  }

  function ontouchstart(e) {
    if (e.touches.length === 2) {
      if (!gestureActive.current) {
        startGesture()
      }

      initialTouches.t1.x = e.touches[0].clientX
      initialTouches.t1.y = e.touches[0].clientY
      initialTouches.t2.x = e.touches[1].clientX
      initialTouches.t2.y = e.touches[1].clientY

      prevTouchScale.current = e.scale
      prevTouchPan.x = (initialTouches.t1.x + initialTouches.t2.x) / 2
      prevTouchPan.y = (initialTouches.t1.y + initialTouches.t2.y) / 2

      if (e.cancelable !== false) {
        e.preventDefault()
      }

      ref.current.addEventListener("touchmove", ontouchmove, { passive: false })
    } else if (e.touches.length === 1) {
      if (!isDoubleTap.current) {
        isDoubleTap.current = true
        setTimeout(() => (isDoubleTap.current = false), 300)
      } else {
        zoom(ref.current.offsetHeight)
      }
      if (gesture.get("scale") <= 1) {
      } else {
        e.preventDefault()
        prevTouchPan.x = e.touches[0].clientX
        prevTouchPan.y = e.touches[0].clientY

        ref.current.addEventListener("touchmove", ontouchmove, { passive: false })
      }
    }
  }

  function ontouchmove(e) {
    if (e.touches.length === 2) {
      const curpts = { t1: { x: e.touches[0].clientX, y: e.touches[0].clientY }, t2: { x: e.touches[1].clientX, y: e.touches[1].clientY } }
      const mp_curr = midpoint(curpts)

      const scale = e.scale !== undefined ? e.scale : distance(curpts) / distance(initialTouches)
      const scaleConstrain = Math.max(1, Math.min(scale - prevTouchScale.current + gesture.get("scale"), 2))
      setMinMax(scaleConstrain, ref.current.offsetHeight)

      gesture.set("scale", scaleConstrain)

      const nextX = gesture.get("trans").x + mp_curr.x - prevTouchPan.x
      const nextY = gesture.get("trans").y + mp_curr.y - prevTouchPan.y

      gesture.set("trans", {
        x: Math.max(maxpt.x, Math.min(nextX, minpt.x)),
        y: Math.max(maxpt.y, Math.min(nextY, minpt.y)),
      })

      prevTouchPan.x = mp_curr.x
      prevTouchPan.y = mp_curr.y
      prevTouchScale.current = scale

      doGesture()
      if (e.cancelable !== false) {
        e.preventDefault()
      }
    } else if (e.touches.length === 1) {
      e.preventDefault()

      const curpts = { x: e.touches[0].clientX, y: e.touches[0].clientY }
      const nextX = gesture.get("trans").x + curpts.x - prevTouchPan.x
      const nextY = gesture.get("trans").y + curpts.y - prevTouchPan.y

      gesture.set("trans", {
        x: Math.max(maxpt.x, Math.min(nextX, minpt.x)),
        y: Math.max(maxpt.y, Math.min(nextY, minpt.y)),
      })

      prevTouchPan.x = curpts.x
      prevTouchPan.y = curpts.y

      doGesture()
    }
  }

  function updateRect() {
    rect.x = contRef.current.offsetLeft
    rect.y = contRef.current.offsetTop
    rect.width = contRef.current.offsetWidth
    rect.height = contRef.current.offsetHeight
  }

  function onResize() {
    updateRect()
    setMinMax(gesture.get("scale"))
  }
  useEffect(() => viewport.height.onChange(onResize))
  useEffect(() => viewport.width.onChange(onResize))

  function onLoad(e) {
    updateRect()
    setMinMax(gesture.get("scale"))
  }

  useLayoutEffect(() => {
    const r = ref.current

    updateRect()
    setMinMax(gesture.get("scale"))

    window.addEventListener("wheel", onwheel, { passive: false, capture: true })
    r.addEventListener("touchstart", ontouchstart, { passive: false, capture: true })
    r.addEventListener("touchend", ontouchend, { passive: false, capture: true })
    r.addEventListener("touchcancel", ontouchend, { passive: false, capture: true })
    r.addEventListener("load", onLoad, { capture: true })

    return () => {
      window.removeEventListener("wheel", onwheel, { passive: false, capture: true })
      r.removeEventListener("touchstart", ontouchstart, { passive: false, capture: true })
      r.removeEventListener("touchmove", ontouchmove, { passive: false })
      r.removeEventListener("touchend", ontouchend, { passive: false, capture: true })
      r.removeEventListener("touchcancel", ontouchend, { passive: false, capture: true })
      r.removeEventListener("load", onLoad, { capture: true })
    }
  }, [])

  function reset() {
    gestureActive.current = false

    prevTouchScale.current = 0

    prevTouchPan.x = 0
    prevTouchPan.y = 0

    initialTouches.t1.x = 0
    initialTouches.t1.y = 0
    initialTouches.t2.x = 0
    initialTouches.t2.y = 0

    gesture.set("scale", 1)
    gesture.set("trans", { x: 0, y: 0 })

    endGesture()
  }

  const ctx = {
    reset: () => reset(),
  }

  useImperativeHandle(handle, () => ctx)

  return (
    <ImageContainer ref={ref}>
      <Div ref={contRef} style={{ width, height }} animate={animCtrl}>
        <Image alt={imageAlts} sources={sources} sizes='100vw' />
      </Div>
      {fig_caption?.caption?.length ? <FigCaption {...fig_caption} style={{ aspectRatio: ratio, width, height }} /> : null}
    </ImageContainer>
  )
}

export default forwardRef(Box)
