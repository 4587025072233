import React, { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import styled from "@emotion/styled"
import { motion, AnimatePresence } from "framer-motion"
import { FocusScope } from "@react-aria/focus"
import FocusTrap from "focus-trap-react"
import { useConsole } from "contexts/Console"

import { useDictionary } from "contexts/Dictionary"
import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"

import useScrollLock from "hooks/useScrollLock"

import { ButtonStyled } from "components/button/Button"
import { Icon } from "components/icon/Icon"

import { useLightBox } from "./context"
import LightBox from "./LightBox"

const CloseButton = styled(ButtonStyled)`
  position: relative;
  z-index: 2;

  grid-row: 1;
  grid-column: col 6;
  margin-block-start: 36px;
  justify-self: end;

  ${getMediaQuery("m")} {
    margin-block-start: 50px;
    grid-column: col 13;
    justify-self: center;
  }
`

const ModalContent = styled(motion.div)`
  /* z-index: 1000; */
  z-index: calc(var(--z-top, 1000) + 10);

  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  justify-items: center;
  align-items: center;
`

const Container = styled.div`
  ${fullGrid}
`

function Cont({ imageAlts, setIsOpen, texts, isRoller }) {
  const ref = useRef()
  const contRef = useRef()
  const console = useConsole()

  const scrollLock = useScrollLock()
  const dictionary = useDictionary()
  const { currentIndex, bgColor } = useLightBox()

  const onClick = () => currentIndex.set(-1)

  const onOpenChange = i => setIsOpen(i >= 0)
  useEffect(() => currentIndex.onChange(onOpenChange))

  function onKeyDown(e) {
    if (e.code === "Escape") {
      e.stopPropagation()
      e.preventDefault()
      currentIndex.set(-1)
    }
  }

  useEffect(() => {
    const r = ref.current
    scrollLock.lock(r)
    return () => scrollLock.unlock(r)
  }, [])

  const LightBoxTitle = isRoller ? texts?.texts[0]?.heading?.title : ""
  console.verbose(texts, "++++++++++++ modal")

  return (
    // <FocusScope contain restoreFocus autoFocus>
    <FocusTrap focusTrapOptions={{ onDeactivate: onClick }}>
      <ModalContent
        ref={ref}
        key='lightbox'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: "tween", duration: 0.4 }}
        style={{ background: bgColor }}
        onKeyDownCapture={onKeyDown}
        aria-modal='true'
        role='dialog'
        aria-label={`${LightBoxTitle} ${dictionary.photos()}`}
      >
        <Container ref={contRef} className='dark-theme'>
          <CloseButton onClick={onClick} className='icon translucent-dark' aria-label={dictionary.ariaLabelCloseLightbox()}>
            <Icon type='close' />
          </CloseButton>
          <LightBox imageAlts={imageAlts} ariaLabel={`${LightBoxTitle} ${dictionary.photos()}`} />
        </Container>
      </ModalContent>
    </FocusTrap>

    // </FocusScope>
  )
}

export default function ModalBox({ imageAlts }, texts, isRoller, props) {
  const [isOpen, setIsOpen] = useState(false)

  const { currentIndex } = useLightBox()

  const onOpenChange = i => setIsOpen(i >= 0)
  useEffect(() => currentIndex.onChange(onOpenChange))

  return (
    process.browser &&
    createPortal(
      <AnimatePresence>
        {isOpen && <Cont imageAlts={imageAlts} {...props} texts={texts} isRoller={isRoller} isOpen={isOpen} setIsOpen={setIsOpen} />}
      </AnimatePresence>,
      document.body
    )
  )
}
