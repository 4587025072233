import { useEffect, useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useFrameAnimation, DIAL_BOUNCE, DIAL_SCALE, DIAL_OFFSET_Y, PK20_FIT } from "./FrameAnimation"
import ImageCLD from "components/media/ImageCLD"
import { useEnv } from "contexts/Env"
import getMediaQuery from "css/breakpoints"
import { useMediaLoader, mvSubscribe, rafDelay } from "./utils"
import { colors, pageGradientVar } from "./../model/colors"
import { useDictionary } from "contexts/Dictionary"
import { CfgCanvasAnimation } from "./canvas.animation"
import { useMotionValue, useTransform } from "framer-motion"
import hash from "@emotion/hash"
import { SWAP_IN, SWAP_OUT, SWAP_IN_DURATION, SWAP_OUT_DURATION, INSTALL_ECO_TRANSITION } from "./constants"
import { visuallyHidden } from "css/text"

const Tabs = styled.ul`
  user-select: none;
  margin: 0;
  padding: 0;
  grid-row: 1;
  grid-column: 1/-1;
  justify-self: end;
  align-self: center;
  position: relative;
  z-index: 22;
  ${colors}

  ${SWAP_OUT}

    &.wv_reveal {
    ${SWAP_IN}
    transition-delay: 0ms, 0ms;
  }
  .cfg-install & {
    transition: ${INSTALL_ECO_TRANSITION};
  }

  --tab-gap: 12px;
  --tab-diameter: 44px;

  display: flex;
  flex-direction: column;
  gap: var(--tab-gap);
  --spacing: calc(var(--outer-margin) - var(--grid-gap) / 2 - var(--tab-diameter) / 2);
  ${getMediaQuery("m")} {
    --spacing: calc(var(--outer-margin) + var(--grid-col-unit) / 2 - var(--tab-diameter) / 2);
    --tab-gap: 20px;
    --tab-diameter: 60px;
  }
  padding-inline-end: var(--spacing);

  & li {
    &[aria-current="true"] {
      & button {
        border-color: rgba(255, 255, 255, 1);
      }
    }

    & button {
      background: none;
      position: relative;
      display: block;
      width: var(--tab-diameter);
      height: var(--tab-diameter);
      border-radius: var(--tab-diameter);
      overflow: hidden;
      border: 2px solid rgba(255, 255, 255, 0.3);
      box-sizing: border-box;
      transition: border-color 400ms cubic-bezier(0.61, 1, 0.88, 1);
      cursor: pointer;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-color: rgba(255, 255, 255, 1);
        }
      }

      & > div {
        background-image: var(${pageGradientVar});
        position: absolute;
        top: 0;
        margin-inline-start: -200%;
        height: 100%;
        width: 300%;
      }

      ${getMediaQuery("m")} {
        &.cfg-tp-watch {
          & > img {
            top: 0;
            height: 100%;
            width: auto;
            left: 50%;
            transform: translateX(-50%) scale(1.4);
          }
        }
      }

      & > img {
        pointer-events: none;
        -webkit-user-drag: none;
        position: absolute;
        top: 0;
        height: 100%;
        width: auto;
        left: 50%;
        object-fit: cover;
        max-width: unset;
        transform: translateX(-50%) scale(2);

        ${getMediaQuery("m")} {
          width: 100%;
          height: auto;
          left: 0%;
          top: 50%;
          transform: translateY(-50%) scale(2);
        }
      }
    }
  }
`

const BOUNCE_DURATION = 100

const Panels = styled.div`
  user-select: none;
  grid-row: 1/-1;
  grid-column: 1/-1;
  position: relative;
  z-index: 20;
  display: grid;
  grid-template-rows: 1fr;
  align-content: center;
  --shadow-ratio: 1380 / 1180;
  transform: scale(${DIAL_BOUNCE.landscape});
  transform-origin: 50% 50%;
  transition: transform ${BOUNCE_DURATION}ms cubic-bezier(0.61, 1, 0.88, 1) 0ms;

  ${getMediaQuery("m", { max: true })} {
    transform: scale(${DIAL_BOUNCE.portrait});
  }

  &.wv_reveal {
    transform: scale(1);
  }

  & > div {
    position: relative;
    grid-row: 1/-1;
    grid-column: 1/-1;
    display: flex;
    direction: ltr;

    &.cfg-tp-bracelet {
      & > img {
        visibility: hidden;
      }
      justify-content: start;
      ${getMediaQuery("l", { max: true })} {
        align-items: end;
      }
      ${getMediaQuery("m", { max: true })} {
        //            align-items: center;
        & > img,
        & > canvas {
          width: 100%;
          height: auto;
        }
      }
    }
    &.cfg-tp-watch {
      align-self: center;
      justify-content: center;
      height: calc(var(--cfg-dial-shrink) * var(--shadow-ratio));
      transform: translateY(var(--cfg-dial-offset));
      & img {
        //                    background: rgba(255,0,0,.6);
      }
      ${getMediaQuery("m", { max: true })} {
        //            height: calc(${DIAL_SCALE.portrait} * 1% / ${PK20_FIT.portrait.ratio});
        //            transform: translateY(calc(${DIAL_SCALE.portrait} * 1% * ${DIAL_OFFSET_Y.portrait} / 100));
      }
      .cfg-install & {
        transition: none !important;
      }
    }
    &.cfg-tp-zoom {
      justify-content: center;
      & img {
        transition: transform 0ms cubic-bezier(1, 1, 1, 1) ${SWAP_OUT_DURATION}ms;
      }
      &.wv_reveal {
        & img {
          transform: scale(1.05);
          transform-origin: 50% 50%;
          transition: transform 10000ms cubic-bezier(1, 1, 1, 1) 0ms;
        }
      }
    }
    &.cfg-tp-side {
      & > img {
        visibility: hidden;
      }
      justify-content: end;
      ${getMediaQuery("l", { max: true })} {
        align-items: end;
      }
      ${getMediaQuery("m", { max: true })} {
        & > img,
        & > canvas {
          width: 100%;
          height: auto;
        }
        align-items: center;
        & > img {
          //              transform: scale(1.15);
          //              transform-origin: 50% 100%;
        }
      }
    }
    //      transition: opacity 100ms cubic-bezier(0.61, 1, 0.88, 1) 0ms, visibility 0ms 100ms;

    ${SWAP_OUT}

    &.wv_reveal {
      ${SWAP_IN}
    }

    & > img {
      max-width: unset;
      -webkit-user-drag: none;
      //          pointer-events: none;
      display: block;
      position: absolute;
      height: 100%;
      width: auto;
      object-fit: cover;
      //          background: rgba(255,0,0,.3);
    }

    & > canvas {
      display: block;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }

  &.cfg-newpanel {
    & > div {
      &.wv_reveal {
        transition-delay: 0ms, 0ms;
      }
      &.cfg-tp-side,
      &.cfg-tp-bracelet {
        & > img {
          visibility: visible;
        }
        & > canvas {
          visibility: hidden;
        }
      }
    }
  }
`

const CanvasOverlay = styled.div`
  ${SWAP_OUT}

  &.wv_reveal {
    ${SWAP_IN}
  }
  position: relative;
  z-index: 20;
  grid-row: 1;
  grid-column: 1/-1;
  background: linear-gradient(0deg, rgba(56, 56, 56, 0.5) 0%, rgba(56, 56, 56, 0) 80%);
  height: 250px;
  align-self: end;
`

const CANVAS_VIEWS = ["watch", "zoom", "side", "bracelet"]
const CANVAS_VIEWS_LABELS = new Map([
  ["watch", "watchView"],
  ["zoom", "dialView"],
  ["side", "sideView"],
  ["bracelet", "braceletView"],
])

export function buildPicture(v, key, catalogYear, portrait) {
  let public_id = []
  const cat_id = `catalogue/${catalogYear}/`
  let policy = []
  let path = ["", ""]
  let width = [240, 240]
  let height = [118, 357]
  let range = 0
  if (!v) return null
  //  const isLHD = ["m126720vtnr-0001", "m126720vtnr-0002"].includes(v.rmc)

  switch (key) {
    case "watch":
      public_id = [`upright-c-shadow/${v.rmc}`, `upright-c-shadow/${v.rmc}`]
      policy = ["t_v7-config-with-shadow-landscape", "t_v7-config-with-shadow-portrait"]
      path = [`v1:catalogue:${catalogYear}:upright-c:${v.rmc}`, `v1:catalogue:${catalogYear}:upright-c:${v.rmc}`]
      width = [800, 780]
      height = [1380, 1356]
      break
    case "zoom":
      public_id = [`upright-c/${v.rmc}`, null]
      policy = ["t_v7-config-watch-view-landscape"]
      width = [1920, 0]
      height = [1085, 0]
      break
    case "side":
      public_id = [
        `configurator/anim-case/landscape-${v.part_case_id}-${v.part_bracelet_id}/anim-config-case-landscape-${v.part_case_id}-${v.part_bracelet_id}--0015`,
        `configurator/anim-case/portrait-${v.part_case_id}-${v.part_bracelet_id}/anim-config-case-portrait-${v.part_case_id}-${v.part_bracelet_id}--0015`,
      ]
      policy = ["t_v7", "t_v7"]
      width = [2440, 780]
      height = [1373, 1620]
      range = 16
      break
    case "bracelet":
      public_id = [
        `configurator/anim-bracelet/landscape-${v.part_bracelet_id}/anim-config-bracelet-landscape-${v.part_bracelet_id}--0013`,
        `configurator/anim-bracelet/portrait-${v.part_bracelet_id}/anim-config-bracelet-portrait-${v.part_bracelet_id}--0013`,
      ]
      policy = ["t_v7", "t_v7"]
      width = [2440, 780]
      height = [1373, 1620]
      range = 14
      break
    default:
      return null
  }

  return {
    public_id: public_id[portrait] ? `${cat_id}${public_id[portrait]}` : null,
    version: 1,
    policy: policy[portrait],
    path: path[portrait],
    width: width[portrait],
    height: height[portrait],
    range: range,
    data_preload: public_id[portrait],
  }
}

function useItems(model, getModel, mobile) {
  const env = useEnv()
  const rfpreload = useRef(null)

  function update() {
    if (!model.get()) {
      rfpreload.current = null
      return
    }
    const m = getModel()
    if (!m) throw new Error(`error: model ${model.get()} not found in catalog`)
    rfpreload.current = CANVAS_VIEWS.map(k => ({
      uid: k,
      picture: buildPicture(m, k, env.catalogYear, mobile.get()),
      pcs: `pcs${hash(m.color_set)}`,
      className: `cfg-tp-${k}`,
    })).filter(v => v.picture.public_id)
    //    console.log("buildPicture", rfpreload.current)
    //    if (lastStep()) return setItems(null)
    //    setTimeout(() => setItems(rfpreload.current), 2000)
  }

  useEffect(() => mvSubscribe(model, update, false), [])
  useEffect(() => mvSubscribe(mobile, update, false), [])

  return rfpreload
}

function CfgCanvasOverlay({ Ctx }) {
  const { settled } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function onSettled(v) {
    display(v > 0)
  }
  useLayoutEffect(() => mvSubscribe(settled, onSettled), [])

  return <CanvasOverlay ref={rfmain} />
}

function preloadImages(el, lQ, ready) {
  rafDelay(() => {
    ;[...el.querySelectorAll("img")].forEach(img => {
      if (img.complete) onLoad({ target: img })
    })
    if (!lQ.length) {
      ready.set(true)
      return
    }
    el.addEventListener("load", onLoad, true)
    el.addEventListener("error", onLoad, true)
  })

  return () => {
    el.removeEventListener("load", onLoad, true)
    el.removeEventListener("error", onLoad, true)
  }

  function onLoad(e) {
    if (!lQ.length) return
    const preloadData = e.target.parentNode.getAttribute("data-preload")
    const index = lQ.indexOf(preloadData)
    if (index < 0) return
    lQ.splice(index, 1)
    ready.set(!lQ.length)
  }
}

function CfgCanvasTab({ uid, picture, className, canvasview, pcs }) {
  const rfmain = useRef()
  const dictionary = useDictionary()

  function onCanvasview(v) {
    rfmain.current.setAttribute("aria-current", v === uid)
  }
  useLayoutEffect(() => mvSubscribe(canvasview, onCanvasview), [])

  function onClick(e) {
    canvasview.set(uid)
  }

  return (
    <li ref={rfmain} aria-current={false}>
      {picture && (
        <button onClick={onClick} className={className} aria-label={dictionary[CANVAS_VIEWS_LABELS.get(uid)]()} data-preload={picture.data_preload}>
          <div className={pcs} />
          <ImageCLD sources={[picture]} widths={[240]} loading='eager' sizes='24Opx' />
        </button>
      )}
    </li>
  )
}

export function CfgCanvasTabs({ Ctx }) {
  const { model, getModel, mobile, swapping, lastStep, readyTabs, canvasview, settled } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)
  const ready = useMotionValue(false)
  const [items, setItems] = useState(null)
  const rfpreload = useItems(model, getModel, mobile)

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function onReadyTabs(v) {
    display(settled.get() > 0 && v && !lastStep())
  }
  useLayoutEffect(() => mvSubscribe(readyTabs, onReadyTabs), [items])

  function onReady(v) {
    readyTabs.set(v || lastStep())
  }
  useLayoutEffect(() => mvSubscribe(ready, onReady), [])

  function update() {
    setItems(null)
    if (lastStep()) return readyTabs.set(true)
    rafDelay(() => setItems(rfpreload.current))
  }

  let tmupdate = useRef(0).current
  function refresh() {
    clearTimeout(tmupdate)
    display(false)
    tmupdate = setTimeout(update, SWAP_OUT_DURATION)
  }

  function onModel(v) {
    if (!v) return
    refresh()
  }
  useEffect(() => mvSubscribe(model, onModel), [])

  function onMobile(v) {
    refresh()
  }
  useEffect(() => mvSubscribe(mobile, onMobile, false), [])

  function onSwapping(v) {
    if (v && lastStep()) return refresh()
    if (!v && !items) return update()
  }
  useEffect(() => mvSubscribe(swapping, onSwapping, false), [items])

  function onSettled(v) {
    display(ready.get() && !lastStep() && v > 0)
  }
  useLayoutEffect(() => mvSubscribe(settled, onSettled), [])

  useLayoutEffect(() => {
    if (!items) return ready.set(false)
    return preloadImages(
      rfmain.current,
      items.map(item => item.picture.data_preload),
      ready
    )
  }, [items])

  return (
    <Tabs ref={rfmain}>
      {items?.map(item => (
        <CfgCanvasTab key={`cfg-panel-${item.picture.data_preload}`} {...item} canvasview={canvasview} />
      ))}
    </Tabs>
  )
}

function CfgCanvasPanel(props) {
  //  console.log("reload CfgCanvasPanel", props)
  const { Ctx, uid, picture, className } = props
  const { canvasview, step, readyPanels } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  const dictionary = useDictionary()
  const path = useMotionValue(null)
  const imageready = useMotionValue(false)
  const frameready = useMotionValue(false)
  const canvasready = useMotionValue(false)
  const ready = useTransform([imageready, frameready, canvasready], ([...args]) => args.every(v => v))

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function update(v) {
    display(step.get() > 0 && v === uid && ready.get())
    if (v !== uid) return
    readyPanels.set(ready.get())
  }
  useLayoutEffect(() => mvSubscribe(canvasview, update), [])

  function onReady(v) {
    //    if (uid === "watch") alert(`ready ${v}`)
    display(step.get() > 0 && v && canvasview.get() === uid)
    if (canvasview.get() !== uid) return
    readyPanels.set(v)
  }
  useLayoutEffect(() => mvSubscribe(ready, onReady), [])

  function onFrameready(v) {
    if (!v || !picture.range) return
    display(step.get() > 0 && canvasview.get() === uid)
  }
  useLayoutEffect(() => mvSubscribe(frameready, onFrameready), [])

  function onStep(v) {
    display(v > 0 && ready.get() && canvasview.get() === uid)
  }
  useLayoutEffect(() => mvSubscribe(step, onStep), [])

  function onImageready(v) {
    if (!v) return path.set(null)
    if (!picture.range) {
      frameready.set(true)
      canvasready.set(true)
      return
    }
    const img = rfmain.current.querySelector("img")
    if (!img) return
    path.set(`${img.currentSrc.replace(/w_[0-9]{1,4}/i, "@w_").replace(/[0-9]{4}$/i, "")};${picture.width};${picture.height}`)
  }
  useLayoutEffect(() => mvSubscribe(imageready, onImageready), [picture])

  useLayoutEffect(() => {
    imageready.set(false)
    if (!picture) return
    return preloadImages(rfmain.current, [picture.data_preload], imageready)
  }, [picture])

  return (
    picture && (
      <div id={`cfg-tp-${uid}`} aria-labelledby={`cfg-t-${uid}`} ref={rfmain} className={className} data-preload={picture.data_preload}>
        {picture.range ? (
          <CfgCanvasAnimation {...props} canvasready={canvasready} frameready={frameready} path={path}>
            <ImageCLD sources={[picture]} widths={[picture.width]} loading='eager' sizes={`${picture.width}px`} />
          </CfgCanvasAnimation>
        ) : (
          <ImageCLD
            sources={[picture]}
            widths={[picture.width]}
            alt={dictionary[CANVAS_VIEWS_LABELS.get(uid)]()}
            loading='eager'
            sizes={`${picture.width}px`}
          />
        )}
      </div>
    )
  )
}

export function CfgCanvasPanels({ Ctx }) {
  //console.log("reload CfgCanvasPanels")
  const {
    model,
    getModel,
    swapping,
    lastStep,
    mobile,
    settled,
    loading,
    preloading,
    ready,
    readyPanels,
    readyCanvas,
    orientation,
    bkg,
    canvasview,
    rfcanvasview,
    newpanel,
    step,
    swapReady,
  } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  const [items, setItems] = useState(null)
  const rfpreload = useItems(model, getModel, mobile)

  function display() {
    rfmain.current?.classList.add("wv_reveal")
  }

  function onSettled(v) {
    if (v <= 0) return
    display()
  }
  useLayoutEffect(() => mvSubscribe(settled, onSettled), [])

  function save(fid) {
    const next = fid ?? canvasview.get()
    if (next) rfcanvasview.current = next
    canvasview.set(null)
  }

  function restore() {
    if (!rfcanvasview.current) return
    canvasview.set(rfcanvasview.current)
    rfcanvasview.current = null
  }

  function update() {
    setItems(null)
    if (lastStep()) return readyPanels.set(true)
    rafDelay(() => {
      setItems(rfpreload.current)
      restore()
    })
  }

  function refresh() {
    save()
    setTimeout(update, SWAP_OUT_DURATION)
  }

  function onModel(v) {
    if (!v) return
    refresh()
  }
  useEffect(() => mvSubscribe(model, onModel), [])

  function onMobile(v) {
    refresh()
  }
  useEffect(() => mvSubscribe(mobile, onMobile, false), [])

  function onCanvasview(v) {
    if (!v) return readyPanels.set(lastStep())
    newpanel.set(!!rfcanvasview.current)
  }
  useLayoutEffect(() => mvSubscribe(canvasview, onCanvasview, false), [])

  function onNewpanel(v) {
    rfmain.current?.classList[v ? "add" : "remove"]("cfg-newpanel")
  }
  useLayoutEffect(() => mvSubscribe(newpanel, onNewpanel, false), [])

  function onSwapping(v) {
    if (lastStep()) return save("watch")
    if (!v && !canvasview.get()) return update()
    //    rafDelay(restore)
  }
  useEffect(() => mvSubscribe(swapping, onSwapping, false), [items])

  return (
    <>
      <Panels ref={rfmain} aria-live='polite'>
        {items?.map(item => (
          <CfgCanvasPanel key={`cfg-panel-${item.uid}`} {...item} Ctx={Ctx} />
        ))}
      </Panels>
      <CfgCanvasOverlay Ctx={Ctx} />
    </>
  )
}
