import { useRef } from "react"
import styled from "@emotion/styled"
import { motion, useScroll, useTransform, useMotionValue, useMotionValueEvent, transform, useInView } from "framer-motion"

import { useConsole } from "contexts/Console"
import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"
import { headline70, headline50 } from "css/text"

import Image from "components/media/ImageCLD"
import Video, { cssContainer } from "components/media/VideoCLD"
import { kickerCN, subtitleCN } from "components/page-heading/style"
import { cssVarHeight } from "contexts/Header"
import { useViewport } from "contexts/Viewport"
//import { slideUp } from "animations/slideUp"

export const slideUp = {
    show: {
        opacity: 1,
        y: 0,
        transition: { delay: 0, type: "tween", duration: .6 },
    },
    hide: {
        opacity: 0,
        y: 50,
        transition: { delay: 0, type: "tween", duration: .4 },
    },
}

const Main = styled.section`
  position: relative;
    ${fullGrid}
    --min-height: 165vw;
    --full-height: max(calc(101vh - var(${cssVarHeight})), var(--min-height));
    grid-template-rows: [fullh-start heading] var(--full-height) [chapo] auto [fullh-end];

    ${getMediaQuery("m")} {
        --min-height: 40vw;
    }
`

const HeaderWrapper = styled(motion.header)`
    position: relative;
    z-index: 1;
    grid-column: main;
    grid-row: heading;
    align-items: start;
    ${getMediaQuery("m")} {
        align-items: center;
    }

    grid-column: main;
    display: grid;
    height: 100%;

  ${getMediaQuery("m")} {
        grid-column: col 9 / span 4;

        &:dir(rtl) {
            grid-column: col 1 / span 4;
        }
    }
`

const Header = styled(motion.h1)`
    position: relative;
    --offset-top: 3vh;
    top: calc(var(--subnav-height) + var(--offset-top));

    ${getMediaQuery("m")} {
        --offset-top: 8vh;
        top: 0;
    }
  & > span {
    display: block;
    ${headline70}
  }

  & > p {
    ${headline50}
    color: rgb(var(--subtitle-color));
  }

  html.prefers-contrast & {
      color: black !important;
      -webkit-text-fill-color: inherit !important;

      & > p {
        color: black !important;
      }

      ::after {
        background: white;
        content: "";
        height: calc(100% + 30px);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 20px);
        z-index: -1;
      }
    }
`

const ChapoPara = styled(motion.p)`
    position: relative;
    z-index: 1;
    grid-column: main;
    --margin-block-start: -10vh;
    --margin-block-end: 10vh;

    ${getMediaQuery("m")} {
        grid-column: col 4 / span 6;
    }
    
    grid-row: chapo;
    ${headline50}

    margin-block: var(--margin-block-start) var(--margin-block-end);
    ${getMediaQuery("m")} {
        --margin-block-start: 10vh;
        --margin-block-end: 20vh;
    }

  &:lang(th) {
//    padding-bottom: 0.5ex;
  }

  @media (prefers-contrast: no-preference) {
    &.gradient {
      background: var(--gradient);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

   html.prefers-contrast & {
      color: black !important;
      -webkit-text-fill-color: inherit !important;

      ::after {
        background: white;
        content: "";
        height: calc(100% + 30px);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 20px);
        z-index: -1;
      }
    }
`

const Overlay = styled(motion.div)`
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    transform: translateZ(0);
`

const Figure = styled.figure`
  display: block;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  grid-column: doc;
  grid-row: fullh;
  --edgefit: calc(100% + 1px);

  & > div.${cssContainer} {
    position: relative;
    height: 100%;
    & video {
        width: var(--edgefit);
        height: var(--edgefit);
        object-position: top;
        object-fit: cover;
    }
  }

  & img {
    width: var(--edgefit);
    height: var(--edgefit) !important;
    object-position: top;
    object-fit: cover;
  }
`

function VideoOverlay({ title_opacity }) {
    const opacity = useTransform(title_opacity, op => 1 - op)

    return (
        <Overlay style={{ opacity }} />
    )
}

function Heading({ title, subtitle, subtitle_color, title_opacity }) {
    const ref = useRef(null)
    const { isMobile } = useViewport()
    const href = useRef(null)
    const scroll_y = useMotionValue(0)
    const y = useMotionValue(0)
    const opacity = useMotionValue(1)

    const { scrollY } = useScroll({ target: href, offset: ["start end", "end end"] })

    function onScrollY(v) {
        scroll_y.set(v)
    }
    useMotionValueEvent(scrollY, "change", onScrollY)

    const offsets = [6, 4]
    useTransform([scroll_y, isMobile], ([sy, mob]) => {
        if (!href.current || !ref.current) return
        const { height: parent_height } = href.current.getBoundingClientRect()
        const offset_y = transform(sy, [0, parent_height], [0, parent_height / offsets[+mob]])
        y.set(offset_y)
        const { top, height } = ref.current.getBoundingClientRect()
        const bottom = document.documentElement.querySelector(".header-subnav")?.offsetHeight ?? 0
        const pos = top - bottom
        const op = transform(pos, [height, 0], [1, 0])
        opacity.set(op)
    })

    function onOpacity(v) {
        title_opacity.set(v)
    }
    useMotionValueEvent(opacity, "change", onOpacity)

    return (
        <HeaderWrapper ref={href}>
            <Header className="dark-theme" ref={ref} style={{ y, opacity }}>
                {title ? <span dangerouslySetInnerHTML={{ __html: title }} /> : null}
                {subtitle ? <p style={{ "--subtitle-color": subtitle_color }} dangerouslySetInnerHTML={{ __html: subtitle }} /> : null}
            </Header>
        </HeaderWrapper>
    )
}

function setProperty(el, key, value) {
    el?.style?.setProperty?.(key, value)
}

function observeResize(el, onResize) {
    if (!el) return
    const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
            if (entry.borderBoxSize) {
                const borderBoxSize = entry.borderBoxSize[0]
                onResize(borderBoxSize.inlineSize, borderBoxSize.blockSize, entry.target)
            }
        }
    })

    resizeObserver.observe(el)

    return () => {
        resizeObserver.disconnect()
    }
}

function Chapo({ txt, gradient, py, dy }) {
    const ref = useRef(null)
    const colors = gradient?.colors ? gradient.colors.map(({ color, opacity }) => `rgb(${color} / ${opacity})`) : []
    const gradientVal = colors.length ? `linear-gradient(${gradient.angle ? `${gradient.angle}deg, ` : ""}${colors})` : ""
    const prefersReducedMotion = process.browser && document.documentElement.classList.contains("prefers-reduced-motion")
    const isInView = useInView(ref, { margin: "0% 0% -100px 0%" })

    return <ChapoPara
        ref={ref}
        className={colors.length ? "gradient" : ""}
        style={{ "--gradient": gradientVal }}
        dangerouslySetInnerHTML={{ __html: txt }}
        variants={slideUp}
        initial="hide"
        animate={prefersReducedMotion ? "show" : isInView ? "show" : "hide"}
    />
}

export default function CoverTitleFade(props) {
    const console = useConsole()
    const { heading, subtitle_color, chapo, chapo_gradient, media } = props
    const ref = useRef()
    const py = useMotionValue(0)
    const dy = useMotionValue(0)
    const title_opacity = useMotionValue(1)

    return (
        <Main ref={ref}>
            {heading ? <Heading {...heading} subtitle_color={subtitle_color} py={py} dy={dy} title_opacity={title_opacity} /> : null}
            {chapo ? <Chapo txt={chapo} gradient={chapo_gradient} py={py} dy={dy} /> : null}
            {media?.[0] && (
                <Figure>
                    {media[0].image_cld && (
                        <Image {...media[0].image_cld} sizes='100vw' />
                    )}
                    {media[0].video_cld && (
                        <>
                            <Video {...media[0].video_cld} autoPlay loop noButton sizes='100vw' />
                            <VideoOverlay title_opacity={title_opacity} />
                        </>
                    )}
                </Figure>
            )}
        </Main>
    )
}
