import { useState } from "react"
import { AnimatePresence, motion, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { buttonReset, buttonContrastMode } from "css/buttons"
import getMediaQuery from "css/breakpoints"

import { Icon } from "components/icon/Icon"
import { useFeature } from "./featurecontext"

const PrevCont = styled(motion.div)`
  position: absolute;
  inset-inline-start: var(--arrowmargin);
  inset-block-start: 50%;
  z-index: calc(var(--canvindex) - 4);
  z-index: 2;

  display: none;

  ${getMediaQuery("m")} {
    display: block;
  }
`

const Butt = styled(motion.button)`
  ${buttonReset}
  ${buttonContrastMode}
  display: flex;
  color: #212121;
  align-items: center;
  justify-content: center;
  width: var(--btn-height);
  height: var(--btn-height);
  background: white;
  border-radius: 50%;
  cursor: pointer;
`

export default function Previous({ onClickCB }) {
  const { isFocused } = useFeature()

  const [isNotFeatFocused, setIsNotFeatFocused] = useState(true)

  function onFeatFocus(b) {
    setIsNotFeatFocused(!b)
  }
  useMotionValueEvent(isFocused, "change", onFeatFocus)

  const variants = {
    hidden: { opacity: 0, transition: { duration: 0 } },
    visible: { opacity: 1, transition: { duration: 1.5, delay: 0.4, type: "tween", ease: "easeOut" } },
  }
  const variCont = {
    notpresent: { opacity: 0, transition: { duration: 0.3, type: "tween", ease: "easeOut" } },
    present: { opacity: 1, transition: { duration: 0.3, delay: 0.3, type: "tween", ease: "easeOut" } },
  }

  return (
    <PrevCont variants={variants} initial='hidden' animate='visible' exit='hidden'>
      <AnimatePresence>
        {isNotFeatFocused ? (
          <motion.div variants={variCont} initial='notpresent' animate='present' exit='notpresent'>
            <Butt type="button" onClick={onClickCB} aria-label="Previous interaction">
              <Icon type='movePrevious' />
            </Butt>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </PrevCont>
  )
}
