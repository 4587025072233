import { useEffect, useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"

import { useViewport } from "contexts/Viewport"
import { useDictionary } from "contexts/Dictionary"

import { useLightBox } from "./context"
import Box from "./Box"
import Pagination from "./Pagination"
import Arrows from "./Arrows"
import { useConsole } from "contexts/Console"

const List = styled.ul`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(100vw, 1fr));
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;

  grid-row: 1;
  grid-column: 1;

  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Item = styled.li`
  width: 100vw;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  overflow: hidden;
`

const Controls = styled.div`
  display: contents;
`

export default function LightBox({ ariaLabel, imageAlts = [] }) {
  const dictionary = useDictionary()
  const console = useConsole()

  const { lightBoxes, currentIndex, targetSlide } = useLightBox()

  const { width } = useViewport()

  const [current, setCurrent] = useState(currentIndex.get())

  const ref = useRef()

  useLayoutEffect(() => {
    const dir = document.documentElement.dir === "rtl" ? -1 : 1

    ref.current.scrollTo(width.get() * currentIndex.get() * dir, 0)

    const elements = [...ref.current.children]

    function onIntersectionObserved(entries) {
      entries.forEach(entry => !!entry.isIntersecting && currentIndex.set([...lightBoxes].findIndex(([k, v]) => entry.target.id === k)))
    }
    const observer = new IntersectionObserver(onIntersectionObserved, {
      root: ref.current,
      rootMargin: "0px",
      threshold: 0.85,
    })

    elements.forEach(item => observer.observe(item))

    return () => elements.forEach(item => observer.unobserve(item))
  }, [])

  const onIndexChange = i => {
    if (i < 0) return

    const targetEl = ref.current.querySelector(`#${[...lightBoxes][i][0]}`).offsetLeft
    ref.current.scrollTo({
      top: 0,
      left: targetEl,
      behavior: "smooth",
    })
  }
  useEffect(() => targetSlide.onChange(onIndexChange))

  const onOpenChange = i => setCurrent(i)
  useEffect(() => currentIndex.onChange(onOpenChange))

  return (
    <>
      <List ref={ref} aria-roledescription='carousel' aria-live='polite' role='region' aria-label={ariaLabel}>
        {Array.from(lightBoxes).map(([id, { sources, fig_caption, ...props }], i) => {
          return (
            <Item
              key={id}
              id={id}
              role='group'
              aria-roledescription='slide'
              aria-label={dictionary.ariaLabelPhotoNumOutOf(i + 1, lightBoxes.size)}
              aria-hidden={i !== current}
            >
              <Box sources={sources} fig_caption={fig_caption} imageAlts={imageAlts[i]} />
            </Item>
          )
        })}
      </List>

      {lightBoxes.size > 1 && (
        <Controls role='group' aria-label='slide controls'>
          <Arrows slideNum={lightBoxes.size} />
          <Pagination />
        </Controls>
      )}
    </>
  )
}
