import { useRef, useState, useLayoutEffect } from "react"
import { motion, useScroll, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useConsole } from "contexts/Console"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"
import { Chapo as ChapoClass } from "css/classnames"
import { Heading as HeadingClass } from "css/classnames"
import { Paragraph as ParagraphClass } from "css/classnames"
import { headline100, surtitle100, body100, body50, light, bold } from "css/text"

import Heading from "./Heading"
import Chapo from "./Chapo"
import CTAs from "../CTAs"

const isBold = `b${hash("bold-paragraph")}`

const Container = styled(motion.div)`
  grid-column: doc;
  grid-row: 1;
  ${fullGrid}
  height: 100vh;

  overflow: hidden;

  li:first-of-type & {
    grid-row: 2;
    margin-block-start: -50vh;
  }

  li:first-of-type & > div {
    margin-block-start: calc(clamp(5rem, 3.4375rem + 3.9063vw, 6.875rem) * -1);
  }
`

const Block = styled(motion.div)`
  color: white;
  text-align: center;
  position: relative;
  align-self: center;

  grid-column: main;

  pointer-events: none;

  z-index: 0;

  & button,
  & a {
    pointer-events: all;
  }

  hgroup {
    justify-items: center;
    & h2 {
      ${headline100}
      font-size: clamp(2.5rem, 0.6250rem + 4.6875vw, 6.25rem);
    }
    & .kicker,
    & .subtitle {
      ${surtitle100}
      margin-block-end: 5px;
    }
  }

  hgroup:not(:last-child) {
    margin-block-end: 10px;
  }

  & .${ChapoClass} {
    ${body100}
    ${light}
  }

  & .${ChapoClass} + .${ParagraphClass} {
    margin-block-start: 20px;
  }

  & button,
  & a {
    margin-block-start: 30px;
  }

  ${getMediaQuery("m")} {
    grid-column: col 3 / span 8;
    hgroup {
      & .kicker,
      & .subtitle {
        margin-block-end: 10px;
      }
    }
  }

  ${getMediaQuery("l")} {
    grid-column: col 3 / span 8;

    hgroup {
      & .kicker,
      & .subtitle {
        margin-block-end: 20px;
      }
    }
    hgroup:not(:last-child) {
      margin-block-end: 30px;
    }
  }

  html.prefers-contrast & {
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 20px);
      height: calc(100% + 30px);
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      background: white;
      z-index: -1;
    }
    color: black;
  }
`

const P = styled(motion.p)`
  ${body50}
  ${light}
  &.${isBold} {
    ${bold}
  }
`

const transition = {
  type: "tween",
  duration: 0.8,
  ease: "easeOut",
}

const variants = {
  show: {},
  hide: {},
}

const animA = {
  show: { opacity: 1, transition },
  hide: { opacity: 0, transition },
}

export default function TextBlock({ heading, chapo, paragraph_blocks, cta, index, insetInput }) {
  const console = useConsole()

  const ref = useRef()
  const refCont = useRef()

  const { scrollYProgress } = useScroll({ target: refCont, offset: ["start end", "end end"] })

  const [isInView, setIsInView] = useState(false)

  useMotionValueEvent(scrollYProgress, "change", p => {
    const pp = Math.abs(1 - p)
    insetInput?.set(pp * 100)
  })

  useLayoutEffect(() => {
    const r = ref.current

    function onIntersectionObserved(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsInView(true)
        }
      })
    }
    const observer = new IntersectionObserver(onIntersectionObserved, {
      rootMargin: "-30%",
      threshold: 0.25,
    })

    observer.observe(r)

    return () => observer.unobserve(r)
  }, [])

  return (
    <Container ref={refCont}>
      <Block ref={ref} initial='hide' animate={isInView ? "show" : "hide"} variants={variants}>
        {heading && <Heading {...heading} className={HeadingClass} id={`ashlar--title-${index}`} />}
        {chapo && <Chapo className={ChapoClass} text={chapo} />}
        {paragraph_blocks.length
          ? paragraph_blocks.map(({ paragraph, is_bold }, i) => (
            <P key={i} className={`${ParagraphClass} ${is_bold ? isBold : ""}`} variants={animA} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))
          : null}
        {cta.length ? <CTAs cta={Object.entries(cta[0])[0]} describedBy={`ashlar--title-${index}`} /> : null}
      </Block>
    </Container>
  )
}
