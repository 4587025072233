import { useConsole } from "contexts/Console"
import { useLayoutEffect, useRef } from "react"
import { useMotionValueEvent, useTransform } from "framer-motion"
import { useExp } from "./expcontext"
import { waTriggerEvent, storeCustomPageName } from "components/analytics/DigitalDataLayer"
import { useNavigation } from "contexts/Navigation"

const WA_DICT = {
    event_name_started: "experienceStarted",
    event_name_views: "experienceStepViews",
    event_name_completed: "experienceCompleted",
    step_splitscreen: "splitscreen",
    step_introduction: "introduction",
    step_design: "design",
    step_movement: "movement",
    step_outro: "outro",
    diameter_40: "40mm",
    diameter_36: "36mm",
}

const WA_MAP = new Map([
    ["-1:-1:-1", {
        eventName: [WA_DICT.event_name_started, WA_DICT.event_name_views],
        stepName: WA_DICT.step_splitscreen,
        experienceInfo: {
            step: WA_DICT.step_splitscreen,
        }
    }],
    ["0:0:100", {
        eventName: [WA_DICT.event_name_views],
        stepName: WA_DICT.step_introduction,
        experienceInfo: {
            step: WA_DICT.step_introduction,
            diameter: WA_DICT.diameter_40,
        }
    }],
    ["0:111:111", {
        eventName: [WA_DICT.event_name_views],
        stepName: WA_DICT.step_design,
        experienceInfo: {
            step: WA_DICT.step_design,
            diameter: WA_DICT.diameter_40,
        }
    }],
    ["0:201:201", {
        eventName: [WA_DICT.event_name_views],
        stepName: WA_DICT.step_movement,
        experienceInfo: {
            step: WA_DICT.step_movement,
            diameter: WA_DICT.diameter_40,
        }
    }],
    ["0:201:203", {
        passive: true,
        eventName: [WA_DICT.event_name_views],
        stepName: WA_DICT.step_movement,
        experienceInfo: {
            step: WA_DICT.step_movement,
            diameter: WA_DICT.diameter_40,
        }
    }],
    ["0:300:301", {
        eventName: [WA_DICT.event_name_views, WA_DICT.event_name_completed],
        stepName: WA_DICT.step_outro,
        experienceInfo: {
            step: WA_DICT.step_outro,
            diameter: WA_DICT.diameter_40,
        }
    }],
    ["1:0:100", {
        eventName: [WA_DICT.event_name_views],
        stepName: WA_DICT.step_introduction,
        experienceInfo: {
            step: WA_DICT.step_introduction,
            diameter: WA_DICT.diameter_36,
        }
    }],
    ["1:111:111", {
        eventName: [WA_DICT.event_name_views],
        stepName: WA_DICT.step_design,
        experienceInfo: {
            step: WA_DICT.step_design,
            diameter: WA_DICT.diameter_36,
        }
    }],
    ["1:201:201", {
        eventName: [WA_DICT.event_name_views],
        stepName: WA_DICT.step_movement,
        experienceInfo: {
            step: WA_DICT.step_movement,
            diameter: WA_DICT.diameter_36,
        }
    }],
    ["1:201:203", {
        passive: true,
        eventName: [WA_DICT.event_name_views],
        stepName: WA_DICT.step_movement,
        experienceInfo: {
            step: WA_DICT.step_movement,
            diameter: WA_DICT.diameter_36,
        }
    }],
    ["1:300:301", {
        eventName: [WA_DICT.event_name_views, WA_DICT.event_name_completed],
        stepName: WA_DICT.step_outro,
        experienceInfo: {
            step: WA_DICT.step_outro,
            diameter: WA_DICT.diameter_36,
        }
    }],
    ["watch:0", "design"],
    ["watch:1", "dial"],
    ["watch:2", "bracelet"],
    ["movement:0", "dynapulse"],
    ["movement:1", "oscillator"],
    ["movement:2", "absorbers"],
    ["movement:opened", "opened"],
])

export default function XplitWA() {
    const console = useConsole()
    let { scenario, currentChapter: chapter, currentStep: step, currentInteraction: int } = useExp()
    const scs = useTransform([scenario, chapter, step], args => args.join(":"))
    const navigation = useNavigation()
    const custom_pageName = buildPageName()
    const next_pageName = useRef(null)

    function buildPageName(stepName) {
        let pageName = navigation.homepage ? "home" : navigation.slug.replace(/\//g, ":").replace(/^:/, "")
        if (stepName) pageName = `${pageName}:experience:step:${stepName}`
        return pageName
    }

    function wa(passive) {
        if (!process.browser || passive) return
        const data = WA_MAP.get(scs.get())
        if (!data) return
        let { eventType = "Click", eventName, stepName, experienceInfo, itemInfo } = data
        let pageName = buildPageName(stepName)
        const interaction = int.get()
        if (WA_MAP.has(interaction)) {
            eventName = ["experienceInteraction"]
            pageName = `${pageName}:${WA_MAP.get(interaction)}`
            experienceInfo = Object.assign({}, experienceInfo, {
                interactionName: WA_MAP.get(interaction)
            })
        } else if (interaction === "close") {
            pageName = next_pageName.current
            eventName = ["itemClosed"]
            itemInfo = {
                componentName: "experience",
            }
        }
        next_pageName.current = pageName
        const { href } = global.location
        const pageURL = href.split("?")[0]
        const obj = Object.assign({
            eventName,
            eventType,
            pageName,
            pageURL,
        }, itemInfo ? {
            itemInfo
        } : experienceInfo ? {
            experienceInfo
        } : {})

        waTriggerEvent(obj)
        int.set(null)
    }

    function onScs(v) {
        if (!v) return
        wa(WA_MAP.get(v)?.passive)
    }
    useMotionValueEvent(scs, "change", onScs)

    function onInt(v) {
        if (!v || ["escape"].includes(v)) return
        if (v === "restore") {
            const data = WA_MAP.get(scs.get())
            if (!data) return
            const { stepName } = data
            next_pageName.current = buildPageName(stepName)
            return
        }
        wa()
    }
    useMotionValueEvent(int, "change", onInt)

    useLayoutEffect(() => {
        onScs(scs.get())

        return () => {
            storeCustomPageName(custom_pageName)
        }
    }, [])

    return null
}
