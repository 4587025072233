import { useEffect, useLayoutEffect, useState, useRef } from "react"
import { useMotionValue, useMotionValueEvent, motion, AnimatePresence, clamp, useTransform, transform, animate, useMotionTemplate } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"

import { bold, legend80 } from "css/text"
import getMediaQuery from "css/breakpoints"

import { Icon } from "components/icon/Icon"
import { useExp, THE_WATCH, THE_WATCH_HUB_FEAT_1, THE_WATCH_HUB_FEAT_3, THE_WATCH_HUB, THE_INTRODUCTION } from "../expcontext"
import { HIDDEN_PHASE, useSwitcher } from "../switchcontext"
import { buttonReset, buttonContrastMode } from "css/buttons"

const Container = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  z-index: 1;
`

const PanCont = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  inset-inline-start: calc(100% - var(--outer-margin) - var(--btn-height) / 2);
  overflow: hidden;
  background: rgba(0 0 0 / 0.15);
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
  backdrop-filter: blur(10px);
  z-index: 1;
`

const Disk = styled(motion.button)`
  ${buttonReset}
  ${buttonContrastMode}
  --btnsize: 40px;
  ${getMediaQuery("m")} {
    --btnsize: 44px;
  }

  width: var(--btnsize);
  height: var(--btnsize);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset-block-start: 70%;
  inset-inline-end: var(--outer-margin);
  border: none;
  background: rgba(228 228 228 / 0.3);
  border-radius: 50%;
  transform: translateY(-50%);
  z-index: 3;
  backdrop-filter: blur(8px);

  & svg {
    transform: scaleX(-1);
  }

  ${getMediaQuery("m")} {
    inset-block-start: 50%;
  }
`

const Instruct = styled(motion.p)`
  ${legend80};
  ${bold};
  color: white;
  position: absolute;
  inset-inline-end: 100%;
  text-align: end;
  width: 140%;
  inset-block-start: 50%;
  transform: translateY(-50%);
  margin-inline-end: 10px;
  ${getMediaQuery("m")} {
    width: 200%;
  }

  html.prefers-contrast & {
    color: black !important;
    -webkit-text-fill-color: inherit !important;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 10px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 10px);
      z-index: -1;
    }
  }
`

function Content({ seqloader }) {
  const console = useConsole()
  const { width } = useViewport()

  const { revealProg, ofs, currentStep, targetChapter, frameToDraw, instruction, isRevealed, targetChapterTransi, device } = useExp()
  const { resetPhase } = useSwitcher()

  const isCtrlComplete = useMotionValue(false)
  const isOpening = useMotionValue(false)

  const curFrameNum = useMotionValue(-1)

  const nbf = seqloader.nbf

  const sx = useMotionValue(0)
  const x = useTransform([sx, width], ([xs, w]) => Math.max(xs, -w))
  const progress = useTransform(x, x => transform(x, [-width.get() * 0.4, -width.get()], [0, 1]))
  const isEnded = useTransform(curFrameNum, f => f === nbf - 1)
  const pointerEvents = useTransform(isEnded, b => (b ? "none" : "all"))

  const bright = useTransform(progress, [0, 1], [75, 100])
  const filter = useMotionTemplate`brightness(${bright}%)`

  function open() {
    if (!isOpening.get()) {
      isOpening.set(true)
      const speed = (nbf - curFrameNum.get()) / 30
      animate(sx, -width.get(), { duration: speed }).then(() => currentStep.set(THE_WATCH_HUB_FEAT_1))
    }
  }

  function onPan(e, info) {
    sx.set(Math.min(sx.get() + info.delta.x, 0))
  }

  function onClick() {
    open()
  }
  function onPointerUp() {
    open()
  }

  function onProg(p) {
    curFrameNum.set(Math.round(clamp(0, 1, p) * (nbf - 1)))
  }
  useMotionValueEvent(progress, "change", onProg)

  function onProgReveal(p) {
    revealProg.set(width.get() * 0.92 - ofs.get() + p)
  }
  useMotionValueEvent(x, "change", onProgReveal)

  function onWidth(w) {
    if (currentStep.get() >= THE_WATCH_HUB_FEAT_1) {
      revealProg.set(w * 0.92 - ofs.get() - w)
    } else {
      revealProg.set(w * 0.92 - ofs.get() + x.get())
    }
  }
  useMotionValueEvent(width, "change", onWidth)

  function onEnded(b) {
    if (currentStep.get() <= THE_WATCH) {
      currentStep.set(THE_WATCH_HUB_FEAT_1)
    }
    isCtrlComplete.set(b)
    isRevealed.set(b)
  }
  useMotionValueEvent(isEnded, "change", onEnded)

  function reset() {
    curFrameNum.set(0)
    sx.set(0)
  }

  function onFrameNum(f) {
    seqloader[device.get()][f]?.then(im => frameToDraw.set(im))
  }
  useMotionValueEvent(curFrameNum, "change", onFrameNum)

  function onTargChapTransi(t) {
    if (t < 0) return
    if (t === THE_WATCH_HUB_FEAT_1) {
      open()
      targetChapterTransi.set(-1)
    }
  }
  useMotionValueEvent(targetChapterTransi, "change", onTargChapTransi)

  function onPhaseChange(p) {
    if (p === HIDDEN_PHASE) {
      if (targetChapter.get() >= THE_WATCH_HUB && targetChapter.get() <= THE_WATCH_HUB_FEAT_3) {
        curFrameNum.set(nbf - 1)
      } else if (targetChapter.get() === THE_INTRODUCTION) {
        reset()
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  const variants = {
    visible: { x: "0%", transition: { duration: 0.45, type: "tween", ease: "easeOut" } },
    hidden: { x: "20%", transition: { duration: 0 } },
  }

  const ref = useRef(null)
  useLayoutEffect(() => {
    ref.current.focus()
  }, [])

  return (
    <Container key='modal' initial='hidden' animate='visible' exit='hidden' variants={variants} onPointerUp={onPointerUp} style={{ pointerEvents }}>
      <PanCont onPan={onPan} style={{ x }} />
      <Disk ref={ref} type='button' onClick={onClick} onPan={onPan} style={{ x }} arial-label={instruction.drawer}>
        <Icon type='arrowRight' />
        <Instruct>{instruction.drawer}</Instruct>
      </Disk>
    </Container>
  )
}

export default function Drawer({ scenarSelected }) {
  const console = useConsole()
  const { width } = useViewport()

  const { currentStep, revealProg, ofs, theWatchSequences, isRevealed, device } = useExp()

  const [isOpen, setIsOpen] = useState(currentStep.get() >= THE_WATCH && currentStep.get() <= THE_WATCH_HUB_FEAT_3)
  const [curDevice, setCurDevice] = useState(device.get())

  const seqloader = theWatchSequences[scenarSelected][THE_WATCH].seqloader

  function onOnStepChange(s) {
    if (s < THE_WATCH) {
      isRevealed.set(false)
      revealProg.set(width.get() * 0.92 - ofs.get())
    }
    if (s >= THE_WATCH && s <= THE_WATCH_HUB) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }
  useMotionValueEvent(currentStep, "change", onOnStepChange)

  function onDevice(d) {
    setCurDevice(d)
  }
  useMotionValueEvent(device, "change", onDevice)

  useEffect(() => {
    seqloader.loadFrames(curDevice)
  }, [seqloader, curDevice])

  return <AnimatePresence>{isOpen ? <Content seqloader={seqloader} /> : null}</AnimatePresence>
}
