import { useState, useLayoutEffect, useRef } from "react"
import { useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"
import { usePage } from "contexts/Page"

import ShareButton from "components/share/ShareButton"
import { updateMeta, populateMetaCS } from "components/page/Meta"
import { useXplit } from "./../context"
import { QUERY_PARAM, R_QUERY_MAP } from "./../XplitQuery"
import { useExp } from "../expcontext"
import getMediaQuery from "css/breakpoints"

const ShareBtn = styled(ShareButton)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end !important;
  border-radius: 100%;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
  margin-block-start: var(--sharemargin);

  ${getMediaQuery("m")} {
    margin-block-start: calc(var(--sharemargin) + 3px);
  }
`
const UPDATE_DELAY = 100

export default function XpShare() {
  const console = useConsole()
  const page = usePage()
  const { share, initialMeta } = page
  const { xp_query, query_meta } = useXplit()
  const env = useEnv()
  //    console.verbose("++++ XpShare initialMeta", initialMeta)
  const [url, setUrl] = useState(null)
  const { shared, helmet, currentInteraction } = useExp()
  const rfdelay = useRef(UPDATE_DELAY)

  let tm = 0

  function update(v) {
    if (!query_meta?.length) return
    if (v !== null) {
      if (!R_QUERY_MAP.has(v)) v = [...R_QUERY_MAP.keys()][0]
      v = R_QUERY_MAP.get(v)
    }
    let virtualMeta = updateMeta(QUERY_PARAM, v, query_meta, initialMeta.lngpref, env) ?? {}
    virtualMeta = Object.assign({}, initialMeta, virtualMeta)
    setUrl(virtualMeta.ogurl)
    populateMetaCS(virtualMeta)
  }

  function onXpQuery(v) {
    clearTimeout(tm)
    tm = setTimeout(() => update(v), rfdelay.current)
    rfdelay.current = UPDATE_DELAY
  }
  useMotionValueEvent(xp_query, "change", onXpQuery)

  useLayoutEffect(() => {
    onXpQuery(xp_query.get())
  }, [])

  function handleState(v) {
    shared.set(+v)
  }

  function handleClick(e) {
    shared.set(1)
  }

  function onHelmet(v) {
    rfdelay.current = 0
    onXpQuery(xp_query.get())
  }
  useMotionValueEvent(helmet, "change", onHelmet)

  return <ShareBtn {...share} url={url} wa={{ position: "experience", sharetype: "generic-share" }} handleState={handleState} handleClick={handleClick} />
}
