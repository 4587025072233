import { motion } from "framer-motion"
import styled from "@emotion/styled"
import hash from "@emotion/hash"
import { css } from "@emotion/react"

import { fullGrid } from "css/grid"
// import { useConsole } from "contexts/Console"
import { PopinButton } from "components/components"
import Button from "components/button/Button"
import { useStory } from "components/stories/context"
import getMediaQuery from "css/breakpoints"

export const textWidths = {
  small: `tw${hash("txt-width-small")}`,
  medium: `tw${hash("txt-width-medium")}`,
  large: `tw${hash("txt-width-large")}`,
}

export const Frame = styled.div`
  width: 100%;
  height: 100%;
  border: 20px solid white;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;

  ${getMediaQuery("m", { max: true })} {
    border: 10px solid white;
  }
`

export const RootBase = css`
  cursor: pointer;
  position: relative;

  * {
    pointer-events: none;
  }

  a,
  button {
    pointer-events: auto;
  }
`

export const RootFixed = css`
  --hh: 100vh;
  display: block;
  height: calc(var(--hh) * 3);
  margin-block-start: calc(var(--hh) * -1);
  margin-block-end: calc(var(--hh) * -1);
  z-index: -3;
`

export const Container = styled(motion.div)`
  ${fullGrid}
  width:${({ pushCenter }) => pushCenter && "100%"};
  grid-template-rows: [top-row] auto [bottom-row];
  align-items: end;
  overflow: hidden;
  color: rgb(var(--light-grey));
  height: 100%;
  text-decoration: none;

  .light-theme & {
    color: rgb(var(--light-black));
  }

  > figure {
    grid-column: doc-start / doc-end;
    grid-row: ${({ pushCenter }) => (pushCenter ? "1 / -1" : "top-row / bottom-row")};
    z-index: ${({ pushCenter }) => (pushCenter ? "0" : "-1")};

    video {
      position: relative;
    }
  }

  picture {
    img {
      display: block;
      width: 100%;
      height: 100%;
      transform: translateY(var(--ypos)) scale(var(--scale));
    }
  }
`

function StoryCTA({ story }) {
  const { storyOpener, initiator, storyGroups } = useStory()

  const { label, aria_label } = story[0].launcher.button
  const { uid, story_id } = story[0]

  storyGroups.set(uid, story)

  function onPress() {
    initiator.set("push extd")
    storyOpener.set(`${uid}:${story_id}-0`)
  }

  return (
    <Button icon='plus' className='filled translucent-light' onPress={onPress} aria_label={aria_label}>
      {label}
    </Button>
  )
}

function PopinCTA({ describedBy, ...popin_button }) {
  return <PopinButton {...popin_button} icon='plus' />
}

const CTAS = {
  story_button: StoryCTA,
  popin: PopinCTA,
}

export function getCTA(cta) {
  const CTAComp = CTAS[cta[0]]

  return <CTAComp {...cta[1]} />
}
