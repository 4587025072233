import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { motion, useAnimationControls } from "framer-motion"
import styled from "@emotion/styled"

import { fullGrid } from "css/grid"

import { useConsole } from "contexts/Console"

import { waTriggerEvent } from "components/analytics/DigitalDataLayer"

import Image from "./Image"
import Video from "./Video"

import { useStory } from "../../context"

import TextBlock from "../text/TextBlock"
import { usePlayer } from "../context"
import { getStoryIndex, updateStorage } from "../utils"

const Container = styled(motion.section)`
  --overlay-color: 0 0 0;
  &.light-theme {
    --overlay-color: 255 255 255;
  }
  ${fullGrid}
  grid-template-rows: [card-start] 52px [txt-block-start] 1fr [txt-block-end] 15vh [card-end];
  grid-row: doc;
  grid-column: doc;
  height: 100vh;
  overflow: hidden;
  z-index: 0;
  border: 0px;
  outline: none;
  pointer-events: none;
`

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 30%;
    width: 100%;
    background: linear-gradient(rgb(var(--overlay-color) / 0.5), transparent);
  }

  &.overlay-bottom::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 70%;
    width: 100%;
    background: linear-gradient(transparent, rgb(var(--overlay-color) / 0.3));
  }
`

export default function Card({
  slugId,
  storyId,
  media,
  duration,
  media_anim,
  index,
  indexOfStory,
  title,
  subtitle,
  content_title,
  content_text,
  link,
  text_anim,
  overlay,
  theme,
  currentTheme,
  title_name,
  subtitle_name,
  content_title_name,
  content_text_name,
  openId,
  titleSubtitle,
}) {
  const console = useConsole()

  const { slug, slugMap, currentStoryIndex, focusable } = usePlayer()
  const { storyGroups, initiator } = useStory()

  const ref = useRef()

  const [isCurrent, setIsCurrent] = useState(slug.get() === slugId)

  const anim = useAnimationControls()

  const contentName = title_name?.toLowerCase() || subtitle_name?.toLowerCase() || content_title_name?.toLowerCase() || content_text_name?.toLowerCase()
  const contentItems = [
    {
      contentType: "story",
      contentTag: "no content tag",
      contentName: `slide ${index + 1}: ${contentName || "no content name"}`,
      contentTheme: storyGroups.get(openId)[indexOfStory].story_title.toLowerCase(),
    },
  ]

  function fireEventWA() {
    if (initiator.get() === "xplit") return
    const isFirst = index === 0
    const isLast = slugMap[indexOfStory].indexOf(slugId) === slugMap[indexOfStory].length - 1

    waTriggerEvent({
      eventName: "storyViews",
      contentItems,
      component: initiator.get(),
      storyStarted: isFirst,
      storyCompleted: isLast,
    })
  }

  function onSlugChange(s) {
    const curi = getStoryIndex(slugMap, s)
    if (curi === indexOfStory) {
      if (s === slugId) {
        updateStorage(storyId, index)
        titleSubtitle.set(`${title}:${subtitle}`)
        anim.start("visible")
        fireEventWA()
        setTimeout(() => {
          currentTheme.set(theme || "dark-theme")
        }, 150)
      } else {
        anim.start("hidden")
      }
    }
    setIsCurrent(slug.get() === slugId)
  }
  useEffect(() => slug.onChange(onSlugChange))

  function onCurStoIndexChange(i) {
    if (indexOfStory !== i) {
      anim.set("hidden")
    }
  }
  useEffect(() => currentStoryIndex.onChange(onCurStoIndexChange))

  useEffect(function init() {
    if (slug.get() === slugId) {
      titleSubtitle.set(`${title}:${subtitle}`)
      anim.start("visible")
      fireEventWA()
      updateStorage(storyId, index)
    } else {
      anim.set("hidden")
    }
  }, [])

  useLayoutEffect(() => {
    const link = [...ref.current.getElementsByTagName("a")]
    focusable.set(slugId, link)
  }, [])

  console.verbose("\n•••••••••• media", media[0])

  const prefersReducedMotion = process.browser && document.documentElement.classList.contains("prefers-reduced-motion")

  return (
    <Container
      ref={ref}
      aria-roledescription='slide'
      aria-hidden={!isCurrent}
      role='group'
      initial='hidden'
      animate={anim}
      variants={index === 0 ? Card.firstVariants : Card.variants}
      className={theme || "dark-theme"}
    >
      {Object.keys(media[0])[0] === "video_cld" ? (
        <Video slugId={slugId} indexOfStory={indexOfStory} {...Object.values(media[0])[0]} prefersReducedMotion={prefersReducedMotion} />
      ) : Object.keys(media[0])[0] === "image_cld" ? (
        <Image slugId={slugId} duration={duration} media_anim={media_anim} indexOfStory={indexOfStory} {...Object.values(media[0])[0]} prefersReducedMotion={prefersReducedMotion} />
      ) : null}
      <Overlay className={overlay ? "overlay-bottom" : ""} />
      {content_title || content_text || link.label || link.href || link.external ? (
        <TextBlock content_title={content_title} content_text={content_text} link={link} text_anim={text_anim} contentItems={contentItems} />
      ) : null}
    </Container>
  )
}

Card.transition = { type: "spring", bounce: 0, duration: 0.5 }

Card.variants = {
  visible: { opacity: 1, zIndex: 0, visibility: "visible", transition: Card.transition },
  hidden: { opacity: 0, transitionEnd: { zIndex: -1, visibility: "hidden" }, transition: Card.transition },
}
Card.firstVariants = {
  visible: { opacity: 1, zIndex: 0, transition: Card.transition },
  hidden: { opacity: 1, zIndex: 0, transitionEnd: { zIndex: -1 }, transition: Card.transition },
}
