import Image from "components/media/ImageCLD"
import FigCaption from "components/figcaption/FigCaption"

export default function Figure({ fig_caption, ...props }) {
  return (
    <figure>
      <Image {...props} />
      {fig_caption?.caption?.length ? <FigCaption {...fig_caption} horizontal_position='start' /> : null}
    </figure>
  )
}
