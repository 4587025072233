import { useLayoutEffect, useRef } from "react"
import { motion, useMotionValueEvent, useTransform, animate, useMotionTemplate } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { MODEL_A, MODEL_B, THE_INTRODUCTION, useExp } from "../expcontext"

const Svg = styled(motion.svg)`
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 0;
  pointer-events: none;
`

export const DURA = 2

export const SHAPE40 = [
  {
    void: [31.02, 206, -62, 206, -62, 77, 31.02, 77, 100, 141.5, 31.02, 206],
    open: [154.32, 252, -62, 252, -62, -48, 154.32, -48, 314.74, 102, 154.32, 252],
    close: [-18.74, 222, -62, 222, -62, 162, -18.74, 162, 13.35, 192, -18.74, 222],
  },
  {
    void: [46, 175.5, -78, 175.5, -78, 19.5, 46, 19.5, 119, 97.5, 46, 175.5],
    open: [154.46, 181, -84, 181, -84, -119, 154.46, -119, 294.85, 31, 154.46, 181],
    close: [-12.46, 171, -84, 171, -84, 81, -12.46, 81, 29.65, 126, -12.46, 171],
  },
]
export const SHAPE36 = [
  {
    void: [102.98, 10, 196, 10, 196, 139, 102.98, 139, 34, 74.5, 102.98, 10],
    open: [-20.32, -44, 196, -44, 196, 256, -20.32, 256, -180.74, 106, -20.32, -44],
    close: [152.74, -14, 196, -14, 196, 46, 152.74, 46, 120.65, 16, 152.74, -14],
  },
  {
    void: [122, -62.5, 246, -62.5, 246, 93.5, 122, 93.5, 49, 15.5, 122, -62.5],
    open: [3.54, -75, 242, -75, 242, 225, 3.54, 225, -136.85, 75, 3.54, -75],
    close: [170.46, -65, 242, -65, 242, 25, 170.46, 25, 128.35, -20, 170.46, -65],
  },
]

export const VBOXW = [113, 168]
export const VBOXH = [168, 113]

export const XPTS = [
  [SHAPE40[0].void[0], SHAPE36[0].void[8], SHAPE40[0].void[8], SHAPE36[0].void[10]],
  [SHAPE40[1].void[0], SHAPE36[1].void[8], SHAPE40[1].void[8], SHAPE36[1].void[10]],
]
export const YPTS = [
  [SHAPE36[0].void[1], SHAPE40[0].void[7], SHAPE36[0].void[5], SHAPE40[0].void[11]],
  [SHAPE36[1].void[9], SHAPE40[1].void[7], SHAPE36[1].void[5], SHAPE40[1].void[9]],
]

const VBOX = [`0 0 ${VBOXW[0]} ${VBOXH[0]}`, `0 0 ${VBOXW[1]} ${VBOXH[1]}`]

export default function SvgHoneycomb() {
  const console = useConsole()

  const { scenario, currentChapter, dirBlurX, dirBlurY, device } = useExp()

  const ref40 = useRef()
  const ref36 = useRef()

  const dirBlur = useMotionTemplate`${dirBlurX},${dirBlurY}`

  const points40 = useTransform([device, scenario], ([d, s]) =>
    s < 0 ? SHAPE40[d].void.join(" ") : s === 0 ? SHAPE40[d].open.join(" ") : SHAPE40[d].close.join(" ")
  )
  const points36 = useTransform([device, scenario], ([d, s]) =>
    s < 0 ? SHAPE36[d].void.join(" ") : s === 0 ? SHAPE36[d].close.join(" ") : SHAPE36[d].open.join(" ")
  )

  function onScenar(s) {
    if (s < 0) return
    if (currentChapter.get() > THE_INTRODUCTION) return
    if (s === MODEL_A) {
      if (scenario.prev === -1) {
        animate(points40, SHAPE40[device.get()].open.join(" "), { duration: DURA * 2 })
        animate(points36, SHAPE36[device.get()].close.join(" "), { duration: DURA })
      } else {
        points40.set(SHAPE40[device.get()].open.join(" "))
        points36.set(SHAPE36[device.get()].close.join(" "))
      }
    } else if (s === MODEL_B) {
      if (scenario.prev === -1) {
        animate(points40, SHAPE40[device.get()].close.join(" "), { duration: DURA })
        animate(points36, SHAPE36[device.get()].open.join(" "), { duration: DURA * 2 })
      } else {
        points40.set(SHAPE40[device.get()].close.join(" "))
        points36.set(SHAPE36[device.get()].open.join(" "))
      }
    }
  }
  useMotionValueEvent(scenario, "change", onScenar)

  function onDevice(d) {
    ref40.current.setAttribute("viewBox", VBOX[d])
    ref36.current.setAttribute("viewBox", VBOX[d])
  }
  useMotionValueEvent(device, "change", onDevice)

  useLayoutEffect(() => {
    onScenar(scenario.get())
  }, [])

  return (
    <Svg>
      <defs>
        <svg ref={ref40} id='polyshape0' viewBox={VBOX[device.get()]} preserveAspectRatio='xMidYMid meet'>
          <motion.polygon points={points40} fill='inherit' />
        </svg>
        <svg ref={ref36} id='polyshape1' viewBox={VBOX[device.get()]} preserveAspectRatio='xMidYMid meet'>
          <motion.polygon points={points36} fill='inherit' />
        </svg>
        <mask id='polymaskone' fill='white'>
          <use href='#polyshape0' />
        </mask>
        <mask id='polymasktwo' fill='white'>
          <use href='#polyshape1' />
        </mask>
        <filter id='dirblur'>
          <motion.feGaussianBlur in='SourceGraphic' stdDeviation={dirBlur} />
        </filter>
      </defs>
    </Svg>
  )
}
