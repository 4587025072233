import { useLayoutEffect, useState, useRef, useTransition } from "react"
import { motion, AnimatePresence, useMotionValueEvent, useTransform } from "framer-motion"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import getMediaQuery from "css/breakpoints"
import { useDictionary } from "contexts/Dictionary"
import { useConsole } from "contexts/Console"
import Image from "components/media/ImageCLD"
import { bold, legend110 } from "css/text"
import Button from "components/button/Button"
import camelCase from "utils/camelCase"
import { capitalizeFirstLetter } from "utils/generateComponents"
import * as components from "components/components"
import { useNavigation } from "contexts/Navigation"
import FocusTrap from "focus-trap-react"
import { waTriggerEvent } from "components/analytics/DigitalDataLayer"
import { usePanelModal } from "contexts/PanelModal"

const Main = styled.aside`
  margin: 0;
  padding: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;
  pointer-events: none;

  & > div {
    display: grid;
    padding: 0 0 20px;
    box-sizing: border-box;
    --gap: .75rem;

    ${getMediaQuery("m", { min: true })} {
      padding: 30px;
    }
  }
`

const Heading = styled(motion.h2)`
    --max-width: 400px;
    width: 90vw;
    max-width: var(--max-width);
    min-height: 100px;
    background: #fff;
    --border-radius: 8px;
    border-radius: var(--border-radius);
    pointer-events: auto;
    cursor: pointer;
    justify-self: center;

    ${getMediaQuery("m", { min: true })} {
      width: var(--max-width);
      justify-self: end;
    }
    
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const content = css`
  position: relative;
  display: grid;
  grid-template-columns: .25fr .75fr;
  align-items: center;
  cursor: pointer;
  flex: 1;
  height: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;

  & > span:nth-of-type(1) {
    position: relative;
    display: block;
    height: 100%;
    & img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
  & > span:nth-of-type(2) {
    position: relative;
    display: block;
    padding: .75rem;

    & span,
    & strong {
      display: block;
      text-align: start;
      ${legend110}
      ${bold}
      color: var(--text);

      html.prefers-contrast & {
        color: black !important;
        -webkit-text-fill-color: inherit !important;
      }
    }

    & span {
      font-size: .75rem;
    }

    & strong {
      font-size: 1rem;
    }
  }
`

const Launcher = styled.button`
  border: 0;
  padding: 0;
  background: none;
  ${content}
`

const Cta = styled.div`
  position: relative;
  flex: 0;
  align-self: start;
  padding-block-start: .5rem;
  padding-inline-end: .5rem;
`

const Close = styled(Button)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  --btn-height: 2rem;

  > svg {
    width: 10px !important;
    height: 10px !important;
  }
`

const A = styled.a`
  display: block;
  text-decoration: none;
  color: unset;
  ${content}

  & * {
    pointer-events: none;
  }
`

const PUSH_VARIANTS = {
  visible: {
    opacity: 1,
    transition:
    {
      type: "tween",
      duration: .6,
      delay: .6
    }
  },
  hidden: {
    opacity: 0,
    transition:
    {
      type: "tween",
      duration: .3
    }
  }
}

function PushNotificationLink(props) {
  const { session_id } = props
  const [inactive, setInactive] = useState(!process.browser || sessionStorage.getItem(session_id) === "0")
  const { panelModalState } = usePanelModal()
  const [active, setActive] = useState(!inactive && !panelModalState.get())

  function onPanelModalState(v) {
    setActive(!inactive && !v)
  }
  useMotionValueEvent(panelModalState, "change", onPanelModalState)

  function handleClose() {
    setInactive(true)
  }

  useLayoutEffect(() => {
    setActive(!inactive && !panelModalState.get())
  }, [inactive])

  return (
    <PushNotificationContent
      {...props}
      active={active}
      handleClose={handleClose}
    />
  )
}

export function PushNotificationContent(props) {
  const console = useConsole()
  const { children, _content_type_uid, session_id, image, heading, link, handlePress, handleClose, active, excluded } = props
  const { title, kicker } = heading ?? {}
  const { external, href, aria_label } = link ?? {}
  const initial = process.browser && sessionStorage.getItem(session_id) === "1" ? "visible" : "hidden"
  const dictionary = useDictionary()
  const navigation = useNavigation()
  const ref = useRef(null)

  function triggerWa(data) {
    if (!process.browser) return

    waTriggerEvent(data)
  }

  function onPress(e) {
    sessionStorage.setItem(session_id, "0")
    triggerWa({
      eventName: "notificationClicked",
      eventType: "Click",
      notificationInfo: {
        "notificationDestination": external || href,
      }
    })
    if (!!e) handlePress?.()
  }

  function onClose(e) {
    sessionStorage.setItem(session_id, "0")
    triggerWa({
      eventName: "itemClosed",
      eventType: "Click",
      itemInfo: {
        "componentName": _content_type_uid,
      }
    })
    handleClose?.()
  }

  function onKeyDown(e) {
    if (e.key === "Escape") onClose(e)
  }

  useLayoutEffect(() => {
    if (!active) return
    if (initial === "visible" && excluded)
      sessionStorage.removeItem(session_id)
    if (initial === "hidden" && !excluded)
      sessionStorage.setItem(session_id, "1")
  }, [active, excluded, initial])

  function navigate(e) {
    onPress()
    if (external) return
    e.preventDefault()
    navigation.navigate(e.target.getAttribute("href"))
  }

  const Content = () => <>
    <span>
      <Image {...image} sizes="20vw" />
    </span>
    <span>
      {kicker?.label ? <span style={{ "--text": kicker.color ? `rgb(${kicker.color})` : "" }}>{kicker.label}</span> : null}
      {title?.label ? <strong style={{ "--text": title.color ? `rgb(${title.color})` : "" }}>{title.label}</strong> : null}
    </span>
  </>

  return (
    <Main ref={ref} aria-live="polite">
      {children}
      <AnimatePresence>
        {active && !excluded ? (
          <motion.div
            key={session_id}
            variants={PUSH_VARIANTS}
            initial={initial}
            animate="visible"
            exit="hidden"
          >
            <FocusTrap active={active} focusTrapOptions={{ initialFocus: false, allowOutsideClick: true }}>
              <Heading className="light-theme"
                onKeyDown={onKeyDown}
              >
                {session_id !== PUSH_LINK_ID ?
                  <Launcher onClick={onPress}>
                    <Content />
                  </Launcher> :
                  <A href={external ? new URL(external) : navigation.localize(href)} aria-label={aria_label} onClick={navigate} target={external ? "_blank" : null}>
                    <Content />
                  </A>
                }
                <Cta>
                  <Close aria-label={dictionary.popinClose()} onPress={onClose} icon='close' className='icon translucent-dark'>
                    <span>{dictionary.popinClose()}</span>
                  </Close>
                </Cta>
              </Heading>
            </FocusTrap>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Main>
  )
}

function incExc(page, page_slug) {
  if (page?.url_includes?.length) {
    page = page.url_includes.reduce((a, v) => {
      return page_slug.includes(v) ? page : a
    }, null)
  }
  page = page?.url_excludes.reduce((a, v) => {
    return !page_slug.includes(v) ? a : null
  }, page)
  let wild = page?.url_excludes.includes("*")
  return !!page && !wild
}

function isValid({ page_context, page_type, page_slug }) {
  let ret = false
  let all = page_context.find(item => item.page_type === "all")
  if (all)
    ret = incExc(all, page_slug)

  let match = page_context.find(item => item.page_type === page_type)
  if (!match) return ret
  match = incExc(match, page_slug)
  return all ? (ret && match) : match
}

const PUSH_LINK_ID = "push_link"
const DISABLE_SLUG = [
  "/new-watches"
]

function PushNotification(props) {
  const console = useConsole()
  //  console.verbose("PushNotification", props)
  const { reference_component } = props
  const Cmp = reference_component && components[capitalizeFirstLetter(camelCase(reference_component))]
  const { page_slug } = props
  const session_id = Cmp ? reference_component : PUSH_LINK_ID

  useLayoutEffect(() => {
    if (!page_slug) return
    const disable = DISABLE_SLUG.reduce((a, v) => {
      if (page_slug.includes(v)) a = true
      return a
    }, false)

    if (disable) sessionStorage.setItem(session_id, "0")
  }, [page_slug])

  return Cmp ?
    <Cmp {...props} session_id={session_id} excluded={!isValid(props)} /> :
    <PushNotificationLink {...props} session_id={session_id} excluded={!isValid(props)} />
}

export default PushNotification