export const WHEEL_SCALE_SPEEDUP = 2
export const WHEEL_TRANSLATION_SPEEDUP = 2

const DELTA_LINE_MULTIPLIER = 8
const DELTA_PAGE_MULTIPLIER = 24
const MAX_WHEEL_DELTA = 24

function limit(delta, max_delta) {
  return Math.sign(delta) * Math.min(max_delta, Math.abs(delta))
}

export function normalizeWheel(e) {
  let dx = e.deltaX
  let dy = e.deltaY
  if (e.shiftKey && dx === 0) {
    ;[dx, dy] = [dy, dx]
  }
  if (e.deltaMode === WheelEvent.DOM_DELTA_LINE) {
    dx *= DELTA_LINE_MULTIPLIER
    dy *= DELTA_LINE_MULTIPLIER
  } else if (e.deltaMode === WheelEvent.DOM_DELTA_PAGE) {
    dx *= DELTA_PAGE_MULTIPLIER
    dy *= DELTA_PAGE_MULTIPLIER
  }
  return [limit(dx, MAX_WHEEL_DELTA), limit(dy, MAX_WHEEL_DELTA)]
}

export function midpoint({ t1, t2 }) {
  return {
    x: (t1.x + t2.x) / 2,
    y: (t1.y + t2.y) / 2,
  }
}

export function distance({ t1, t2 }) {
  return Math.hypot(t2.x - t1.x, t2.y - t1.y)
}
