import { useMotionTemplate, useTransform, motion, transform, useMotionValueEvent, AnimatePresence } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import Image from "components/media/ImageCLD"
import { THE_END, THE_MOVEMENT, useExp } from "../expcontext"
import { useViewport } from "contexts/Viewport"
import { useState } from "react"
import { HIDDEN_PHASE, useSwitcher } from "../switchcontext"

const Bground = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: 2;
  pointer-events: none;
`

const ImgCont = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  user-select: none;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default function BackgroundImg({ asset }) {
  const console = useConsole()
  const { width } = useViewport()

  const { background, revealProg, unrevealProg, targetChapter, currentStep, currentChapter } = useExp()
  const { resetPhase } = useSwitcher()

  const [isOpen, setIsOpen] = useState(false)

  const inset = useMotionTemplate`inset(0 0 0 ${unrevealProg}%)`
  const opacity = useTransform([revealProg, width], ([p, w]) => transform(p, [w * 0.92, 0], [0, 1]))

  function onOnStepChange(s) {
    if (s >= THE_MOVEMENT && s < THE_END) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }
  useMotionValueEvent(currentStep, "change", onOnStepChange)

  function onPhaseChange(p) {
    if (p === HIDDEN_PHASE) {
      if (targetChapter.get() >= THE_MOVEMENT && targetChapter.get() < THE_END) {
        setIsOpen(true)
      } else {
        setIsOpen(false)
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  const variants = {
    hidden: { opacity: 0, transition: { duration: 0.3 } },
    visible: { opacity: 1, transition: { duration: 0.45 } },
  }

  return (
    <AnimatePresence>
      {isOpen ? (
        <Bground variants={variants} initial='hidden' animate='visible' exit='hidden' style={{ clipPath: inset }}>
          <ImgCont style={{ opacity }}>
            <Image sources={asset} sizes='100vw' />
          </ImgCont>
        </Bground>
      ) : null}{" "}
    </AnimatePresence>
  )
}
