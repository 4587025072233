import { lazy } from "react"

/**
 * ContentStack Components
 */

export { default as Header } from "components/header/Header"
export { default as SubNavigation } from "components/sub-nav/Pane"
export { default as Footer } from "components/footer/Footer"

export { default as PageHeading } from "components/page-heading/PageHeading"

export { default as CoverImage } from "components/cover/simple/CoverImage"
export { default as CoverVideo } from "components/cover/simple/CoverVideo"
export { default as CoverFeatures } from "components/cover/CoverFeatures"
export { default as CoverIntroImage } from "components/cover/intro/CoverIntroImage"
export { default as CoverIntroVideo } from "components/cover/intro/CoverIntroVideo"
export { default as CoverTitleFade } from "components/cover/intro/CoverTitleFade"
export { default as CoverLana } from "components/cover-lana/CoverLana"
export { default as CoverRftc } from "components/cover/CoverRftc"

export { default as Push } from "components/push/Push"

export { default as Stacker } from "components/stacker/Stacker"
export { default as AshlarWall } from "components/ashlar-wall/AshlarWall"
export { default as Ashlar, AshlarRoller } from "components/ashlar-wall/Ashlar"

export { default as P13n } from "components/p13n/P13n"
export { default as P13nApp } from "components/p13n/P13nApp"

export { default as Perso } from "components/perso/Perso"

export { default as LegalNotices } from "components/legal-notices/LegalNotices"

/* MODULAR BLOCKS */
export { default as Heading } from "components/text/Heading"
export { default as Title } from "components/text/Title"
export { default as Date } from "components/text/Date"
export { default as BigHeading } from "components/text/BigHeading"
export { default as Paragraph } from "components/text/Paragraph"
export { default as Chapo } from "components/text/Chapo"
export { default as QuoteBlock } from "components/text/QuoteBlock"
export { default as ImageCld } from "components/media/Figure"
export { default as CtaLink } from "components/link/CtaLink"
export { default as Link } from "components/link/CtaLink"
export { default as PopinButton } from "components/popin-edito/PopinButton"
export { default as LegalHeading } from "components/legal-notices/LegalHeading"
export { default as LegalParagraph } from "components/legal-notices/LegalParagraph"
export { default as LegalFooter } from "components/legal-notices/LegalFooter"
export { default as LegalList } from "components/legal-notices/LegalList"
export { default as LegalButton } from "components/legal-notices/LegalButton"
/* END OF MODULAR BLOCKS */

export const ArticleImage = lazy(() => import("components/articles/ArticleImage"))
export const ArticleOverlay = lazy(() => import("components/articles/ArticleOverlay"))
export const ArticleSimple = lazy(() => import("components/articles/ArticleSimple"))
export const ArticleVarHeight = lazy(() => import("components/articles/ArticleVarHeight"))
export const BannerPicture = lazy(() => import("components/banner/BannerPicture"))
export const BehindMask = lazy(() => import("components/behind-mask/BehindMask"))
export const BeautyshotCard = lazy(() => import("components/cards/beautyshot-card/BeautyshotCard"))
export const ConfigLauncher = lazy(() => import("components/config-launcher/ConfigLauncher"))
export const CoverRevealVideo = lazy(() => import("components/cover/CoverRevealVideo"))
export const CrisSlides = lazy(() => import("components/cris-slides/CrisSlides"))
export const CsrItTable = lazy(() => import("components/csr/Csr"))
export const CsrItTableDynamic = lazy(() => import("components/csr/CsrDynamic"))
export const DownloadsPage = lazy(() => import("components/downloads/DownloadsPage"))
export const EditoExplore = lazy(() => import("components/edito-explore/EditoExplore"))
export const FamilyVariations = lazy(() => import("components/family-variations/FamilyVariations"))
export const Faq = lazy(() => import("components/faq/Faq"))
export const FindARetailer = lazy(() => import("components/find-a-retailer/FindARetailer"))
export const FocusFeatures = lazy(() => import("components/focus-features/FocusFeatures"))
export const Fragainer = lazy(() => import("components/fragainer/Fragainer"))
export const Gallery = lazy(() => import("components/gallery/Gallery"))
export const HubFilter = lazy(() => import("components/hub-filter/HubFilter"))
export const ImageCard = lazy(() => import("components/roller/cards/ImageCard"))
export const ImageCardLink = lazy(() => import("components/roller/cards/ImageCardLink"))
export const ImageCardPopin = lazy(() => import("components/roller/cards/ImageCardPopin"))
export const ImageSimple = lazy(() => import("components/articles/ImageSimple"))
export const IntroFeaturesBezel = lazy(() => import("components/intro-features-bezel/IntroFeaturesBezel"))
export const Model = lazy(() => import("components/model/Model"))
export const NextChapter = lazy(() => import("components/next-chapter/NextChapter"))
// export const P13n = lazy(() => import("components/p13n/P13n"))
export const PopinEdito = lazy(() => import("components/popin-edito/PopinEdito"))
export const PushExtd = lazy(() => import("components/push/PushExtd"))
export const PushCenter = lazy(() => import("components/push/PushCenter"))
export const PushFixed = lazy(() => import("components/push/PushFixed"))
export const PushExtdFixed = lazy(() => import("components/push/PushExtdFixed"))
export const Quote = lazy(() => import("components/articles/Quote"))
export const Retailer = lazy(() => import("components/retailer/Retailer"))
export const Roller = lazy(() => import("components/roller/Roller"))
export const Scrollersive = lazy(() => import("components/scrollersive/Scrollersive"))
export const SearchResults = lazy(() => import("components/search/SearchResults"))
export const Share = lazy(() => import("components/share/Share"))
export const Skyline = lazy(() => import("components/skyline/Skyline"))
export const SpecsFeatures = lazy(() => import("components/specs-features/SpecsFeatures"))
export const StoryIntro = lazy(() => import("components/story-intro/StoryIntro"))
export const Testimonee = lazy(() => import("components/testimonee/Testimonee"))
export const TextImageHorizontal = lazy(() => import("components/articles/TextImageHorizontal"))
export const TextImageVertical = lazy(() => import("components/articles/TextImageVertical"))
export const TextVideoHorizontal = lazy(() => import("components/articles/TextVideoHorizontal"))
export const VideoAutoplay = lazy(() => import("components/video-autoplay/VideoAutoplay"))
export const VideoCard = lazy(() => import("components/roller/cards/VideoCard"))
export const VideoOverlay = lazy(() => import("components/articles/VideoOverlay"))
export const VideoPlayer = lazy(() => import("components/video-player/VideoPlayer"))
export const VideoPlayerTimeline = lazy(() => import("components/video-player-timeline/VideoPlayerTimeline"))
export const VideoPlayerHowTo = lazy(() => import("components/video-player-how-to/VideoPlayerHowTo"))
export const VideoText = lazy(() => import("components/video-text/VideoText"))
export const Watches = lazy(() => import("components/watches/watches"))
export const WatchSuggestionPush = lazy(() => import("components/push/WatchSuggestionPush"))
export const WatchSuggestions = lazy(() => import("components/watch-suggestions/WatchSuggestions"))
export const Wishlist = lazy(() => import("components/wishlist/Wishlist"))
export const Ymal = lazy(() => import("components/ymal/Ymal"))
export const DiptyqueImage = lazy(() => import("components/diptyque-image/DiptyqueImage"))
export const VideoPlayerExpand = lazy(() => import("components/video-player-expand/VideoPlayerExpand"))

/** DEV */
export const DevTransition = lazy(() => import("components/dev/Transition"))
export const Globe = lazy(() => import("components/globe/globe"))
export const StoreLoc = lazy(() => import("components/store-locator/store-locator"))
export const JpegPage = lazy(() => import("components/jpeg-page/JpegPage"))
export const DataHubGrid = lazy(() => import("components/testimonees/HubGrid"))

//export const TestImageIm = lazy(() => import("components/test/TestImageIm"))
export const WatchDrag = lazy(() => import("components/watch-drag/WatchDrag"))
export const DualGrid = lazy(() => import("components/dual-grid/DualGrid"))
export const XplitLauncher = lazy(() => import("components/xplit/launcher/Launcher"))

export const PushNotification = lazy(() => import("components/push-notification/PushNotification"))
