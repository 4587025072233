import { useState, useEffect } from "react"
import styled from "@emotion/styled"
import * as button from "css/buttons"
import getMediaQuery from "css/breakpoints"

const Button = styled.button`
  ${button.buttonFilled}
  ${button.opaqueGreen}
  display: flex;
  margin: 0 auto;
  margin-block-start: 6.25rem;

  ${getMediaQuery("m")} {
    margin-block-start: 11.25rem;
  }
`

export default function LegalButton({ label, aria_label }) {
  const [showConsentButton, setShowConsentButton] = useState(false)
  let interval
  const start = Date.now()

  useEffect(() => {
    const checkConsent = () => {
      if (window.__tcfapi) {
        clearInterval(interval)
        window.__tcfapi("getNonIABVendorConsents", 2, nonIabConsent => {
          if (nonIabConsent?.gdprApplies === true) {
            setShowConsentButton(true)
          }
        })
      } else {
        if (Date.now() - start > 20000) clearInterval(interval)
      }
    }

    checkConsent()
    interval = setInterval(checkConsent, 300)

    return () => clearInterval(interval)
  }, [])

  const handleConsentClick = () => {
    if (window.__tcfapi) {
      window.__tcfapi("displayConsentUi", 2, function () {})
    }
  }

  return showConsentButton ? (
    <Button onClick={handleConsentClick} aria-label={aria_label}>
      {label}
    </Button>
  ) : null
}
