import { useEffect, useLayoutEffect } from "react"
import { Helmet } from "react-helmet-async"
import { Global, css } from "@emotion/react"
import hash from "@emotion/hash"
import normalize from "emotion-normalize"

import { useConsole } from "contexts/Console"
import { Provider } from "contexts/Theme"
import { useViewport } from "contexts/Viewport"

import { colors } from "css/colors"
import { fonts } from "css/fonts"
import { gridVars } from "css/grid"
import { spacing } from "css/spacing"
import { theme } from "css/theme"
import { a11y } from "css/a11y"
import getMediaQuery from "css/breakpoints"
import { webFonts } from "css/webFonts"
import { useEnv } from "contexts/Env"

const cssLock = `css-${hash("lock")}`

const cssVarExpansion = `--${hash("header:expansion")}`
const cssVarTransitionDelay = `--${hash("header:transition:delay")}`

const globals = css`
  ${normalize}
  ${fonts}
  ${colors}
  ${spacing}
  ${theme}
  ${a11y}

  :root {
    ${gridVars}
    --outer-margin: 8vw;

    --s-h-space: 4vh;
    --m-h-space: 7vh;
    --l-h-space: 10vh;
    --xl-h-space: 120vw;

    --btn-height: 2.5rem;

    --subnav-height: 4.375rem;

    ${getMediaQuery("m")} {
      --s-h-space: 5vh;
      --m-h-space: 10vh;
      --l-h-space: 15vh;
      --xl-h-space: 40vw;

      --btn-height: 2.75rem;

      --subnav-height: 5rem;
    }

    ${cssVarExpansion}
    ${cssVarTransitionDelay}

    // if we want to clamp the main content to max width... :
    /* --outer-margin: calc((100vw - min(84vw, 1240px)) / 2); */
  }

  html {
    overscroll-behavior: contain;
    scrollbar-width: thin;
    overflow-x: hidden;
    scrollbar-gutter: stable;
  }

  body {
    margin: 0 auto;
    overflow: unset !important;
//    width: 100vw;
    /* width: clamp(25rem, 100vw, 120rem); */
  }

  html.${cssLock} {
    overflow: hidden;

    & body {
      touch-action: none;
    }
  }

  main {
    position: relative;
    z-index: 0;
  }

  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  :is(h1, h2, h3, h4, h5, h6, p, ul, ol, dd, figure) {
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
  }

  :is(ul, ol) {
    list-style-type: none;
  }

  img {
    display: inline-block;
  }

  sup {
    font-size: 60%;
    line-height: 0;
    position: static;
    vertical-align: super;
  }

  // Hide the Akamai Adaptive Media Player right-click menu
  .amp-context-menu {
    display: none !important;
  }
  iframe {
    border-width: 0;
    margin-bottom: -6px;
  }
`

function VH100() {
  const viewport = useViewport()

  if (global.CSS && CSS.supports("height: 100dvh")) {
    document.documentElement.style.setProperty("--vh100", `100dvh`)
  }

  const onVpHeightChange = h => {
    document.documentElement.style.setProperty("--vh100", `${h}px`)
  }
  useEffect(() => {
    if (!CSS.supports("height: 100dvh")) return viewport.height.onChange(onVpHeightChange)
  })

  useLayoutEffect(() => {
    if (!CSS.supports("height: 100dvh")) onVpHeightChange(viewport.height.get())
  })

  return null
}

function VW100() {
  if (global.CSS) {
    if (!CSS.supports("height: 100dvw")) {
      document.documentElement.style.setProperty("--vw100", "100vw")
    } else document.documentElement.style.setProperty("--vw100", `100dvw`)
  }
  return null
}

export default function Theme({ children }) {
  const console = useConsole()
  const supports = "light"
  const cssVars = ""
  const cssPrefers = ""
  const env = useEnv()

  console.verbose("Theme(%o)", { supports })
  return (
    <>
      <Global styles={webFonts(env.statics)} />
      <Global styles={globals} />
      <Helmet>
        <meta name='color-scheme' content={supports} />
        <style>{`
          :root{ ${cssVars} }
          ${cssPrefers}
          `}</style>
        <link rel='preload' href='https://cdn.fonts.net/t/1.css?apiType=css&projectid=9a4d0dd4-75e2-40ad-a2b0-025abbfea777' as='style' />
        <link rel='stylesheet' href='https://cdn.fonts.net/t/1.css?apiType=css&projectid=9a4d0dd4-75e2-40ad-a2b0-025abbfea777' />
        <link rel='preconnect' href={`${env.statics}/`} crossorigin />
        <link rel='preconnect' href={`${env.content}/`} crossorigin />
        <link rel='dns-prefetch' href={`${env.statics}/`} />
        <link rel='dns-prefetch' href={`${env.content}/`} />
        <link
          rel='preload'
          href={`${env.statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextRegular_normal_normal.woff2`}
          as='font'
          type='font/woff2'
          crossOrigin
        />
        <link
          rel='preload'
          href={`${env.statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextBold_normal_normal.woff2`}
          as='font'
          type='font/woff2'
          crossOrigin
        />
        <link rel='preload' href={`${env.statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-Regular-WebS.woff2`} as='font' type='font/woff2' crossOrigin />
      </Helmet>
      <VH100 />
      <VW100 />
      <Provider
        value={{
          classNames: {
            lock: cssLock,
          },
        }}
      >
        {children}
      </Provider>
    </>
  )
}
