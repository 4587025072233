import { createContext, useContext, useState, useEffect } from "react"
import { useMotionValue, useTransform } from "framer-motion"

import { useConsole } from "contexts/Console"

import useConstant from "hooks/useConstant"

export const Context = createContext()

export const StateProvider = ({ children }) => {
  const console = useConsole()

  const [pagesCount, setPagesCount] = useState(0)

  const slides = useConstant(() => new Set())
  const pages = useConstant(() => new Map())

  const currentIndex = useMotionValue(0)
  const targetSlide = useMotionValue(0)
  const prog = useMotionValue(0)
  const y = useMotionValue(0)
  const isReturnVisitor = useMotionValue(-1)

  const num = useMotionValue(0)

  const unit = useTransform(num, n => 1 / n)

  const input = useTransform([num, unit], ([n, u]) => {
    console.log("_input_input", Array.from(Array(n + 1)).map((_, i) => u * i - u * 0.5))
    return Array.from(Array(n + 1)).map((_, i) => u * i - u * 0.5)
  })
  const inStopVid = useTransform([num, unit], ([n, u]) => Array.from(Array(n)).map((_, i) => u * (i + 1) - u * 0.5))

  const _input = useTransform(num, n => {
    const total = ([...pages.values()].reduce((a, v) => a + v.h, 0))
    const values = [0].concat([...pages.values()].sort((a, b) => a.index - b.index).map(v => v.h))
    const ret = []
    values.forEach((v, i) => {
      ret[i] = (i > 0 ? ret[i - 1] : -50) + v
    })
    //    console.log("_input", ret.map(v => v / total))
    return ret.map(v => v / total)
  })
  const _inStopVid = useTransform(_input, arr => {
    const total = ([...pages.values()].reduce((a, v) => a + v.h, 0))
    const values = [...pages.values()].sort((a, b) => a.index - b.index).map(v => v.h)
    return arr.map((v, i) => {
      return v + values[i + 1] / total
    })
  }
  )


  function updatePageIndex(uid, index) {
    //    console.log("updatePageIndex", uid, index)
    pages.set(uid, Object.assign(pages.get(uid), { index: index }))
    //    console.log("pages", pages)
  }

  function updatePageNum(uid, h, index) {
    //    console.log("updatePageNum", uid, h, index)
    slides.add(uid)
    pages.set(uid, { h: +h, index: index })
    setPagesCount(slides.size)
    //    console.log("pages", pages)
  }

  function removePage(uid) {
    slides.delete(uid)
    pages.delete(uid)
    setPagesCount(slides.size)
    //    console.log("pages", pages)
  }

  useEffect(() => {
    num.set(pagesCount)
  }, [pagesCount, num])

  return (
    <Context.Provider value={{ currentIndex, targetSlide, input, _input, inStopVid, _inStopVid, prog, y, pagesCount, updatePageNum, removePage, updatePageIndex, num, isReturnVisitor }}>
      {children}
    </Context.Provider>
  )
}

export const useValue = () => useContext(Context)
