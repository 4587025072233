import { useEffect, useRef } from "react"
import { clamp, useTransform, motion, useMotionValueEvent, animate, useMotionTemplate } from "framer-motion"
import styled from "@emotion/styled"
import { useConsole } from "contexts/Console"

import { useExp } from "../expcontext"

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  inset: 0;
  pointer-events: none;
`

const Svg = styled(motion.svg)`
  position: absolute;
  inset: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  pointer-events: none;

  & .svg-contrast {
    visibility: hidden;
  }

  html.prefers-contrast & .svg-contrast {
    visibility: visible;
  }
  html.prefers-contrast & .svgbutton {
    stroke: white;
  }
  html.prefers-contrast & .svg-contrast.svgbutton {
    stroke: black;
  }
`

// html.prefers-contrast & {

// }

const Dot = styled(motion.circle)`
  pointer-events: fill;
  display: block;
  :is(a, button, summary, circle[role="button"].svgbutton).focus-visible {
    & {
      stroke: rgb(var(--focus, 0 255 255) / 1);
      outline: none !important;
    }
    & + circle {
      stroke: rgb(var(--focus, 0 255 255) / 1);
      visibility: visible;
    }
  }
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`

const DotStroke = styled(motion.circle)`
  pointer-events: none;
  display: block;
  visibility: hidden;
`

const DOTSPOS = {
  dial: [
    { start: { x: 90, y: 96 }, end: { x: 22, y: 96 }, ctrlS: { x: 77, y: 82 }, ctrlE: { x: 35, y: 82 } },
    { start: { x: 124, y: 37 }, end: { x: 44, y: 37 }, ctrlS: { x: 104, y: 24 }, ctrlE: { x: 64, y: 24 } },
  ],
  bracelet: [
    { start: { x: 31, y: 97 }, end: { x: 21, y: 135 }, ctrlS: { x: 24, y: 112 }, ctrlE: { x: 22, y: 124 } },
    { start: { x: 122, y: 35 }, end: { x: 106, y: 90 }, ctrlS: { x: 112, y: 48 }, ctrlE: { x: 106, y: 70 } },
  ],
  design: [
    { start: { x: 21, y: 135 }, end: { x: 38, y: 96 }, ctrlS: { x: 21, y: 124 }, ctrlE: { x: 29, y: 106 } },
    { start: { x: 92, y: 110 }, end: { x: 123, y: 50 }, ctrlS: { x: 97, y: 90 }, ctrlE: { x: 109, y: 64 } },
  ],
}

const VBOXW = [113, 240]
const VBOXH = [168, 135]

const VBOX = [`0 0 ${VBOXW[0]} ${VBOXH[0]}`, `0 0 ${VBOXW[1]} ${VBOXH[1]}`]

export default function FocusIndic({ progress, max, feature_id, onPan, onPanEnd }) {
  const console = useConsole()

  const { device } = useExp()

  const svgRef = useRef()

  const percent = useTransform(progress, sp => Math.max(Math.min(sp, max), 0) / max)

  const ptStx = useTransform(device, d => DOTSPOS[feature_id][d].start.x)
  const ptSty = useTransform(device, d => DOTSPOS[feature_id][d].start.y)
  const ptEnx = useTransform(device, d => DOTSPOS[feature_id][d].end.x)
  const ptEny = useTransform(device, d => DOTSPOS[feature_id][d].end.y)

  const ptStCtlx = useTransform(device, d => DOTSPOS[feature_id][d].ctrlS.x)
  const ptStCtly = useTransform(device, d => DOTSPOS[feature_id][d].ctrlS.y)
  const ptEnCtlx = useTransform(device, d => DOTSPOS[feature_id][d].ctrlE.x)
  const ptEnCtly = useTransform(device, d => DOTSPOS[feature_id][d].ctrlE.y)

  const p = useMotionTemplate`M${ptStx} ${ptSty} C ${ptStCtlx} ${ptStCtly}, ${ptEnCtlx} ${ptEnCtly}, ${ptEnx} ${ptEny}`

  function onPerc(p) {
    svgRef.current.setCurrentTime(clamp(0, 1, p))
  }
  useMotionValueEvent(percent, "change", onPerc)

  function onClick() {
    const tar = progress.get() === 0 ? max : 0
    animate(progress, tar, { duration: 0.4 })
  }

  function onKeyDown(e) {
    if (e.key === "Enter") {
      onClick()
    }
  }

  function onDevice(d) {
    svgRef.current.setAttribute("viewBox", VBOX[d])
    requestAnimationFrame(() => {
      svgRef.current.pauseAnimations()
      svgRef.current.setCurrentTime(clamp(0, 1, percent.get()))
    })
  }
  useMotionValueEvent(device, "change", onDevice)

  useEffect(() => {
    svgRef.current.pauseAnimations()
    svgRef.current.setCurrentTime(clamp(0, 1, percent.get()))

    svgRef.current.querySelector("circle[role='button']").focus()
  }, [])

  const variants = {
    hidden: { opacity: 0, transition: { duration: 0.3 } },
    visible: { opacity: 1, transition: { duration: 0.5, delay: 0.2 } },
  }

  return (
    <Container>
      <Svg ref={svgRef} viewBox={VBOX[device.get()]} preserveAspectRatio='xMidYMid slice' variants={variants} initial='hidden' animate='visible' exit='hidden'>
        <motion.path className='svg-contrast' id='theMotionPath' d={p} stroke='black' strokeWidth='7' fill='transparent' vectorEffect='non-scaling-stroke' />
        <motion.circle className='svg-contrast' cx={ptStx} cy={ptSty} fill='black' r='1.1' />
        <motion.circle className='svg-contrast' cx={ptEnx} cy={ptEny} fill='black' r='1.1' />
        <Dot
          className='svgbutton svg-contrast'
          role='button'
          tabIndex='0'
          aria-pressed='false'
          r='0.1'
          fill='none'
          onPan={onPan}
          onPanEnd={onPanEnd}
          onClick={onClick}
          onKeyDown={onKeyDown}
          stroke='black'
          strokeWidth='56'
          vectorEffect='non-scaling-stroke'
        >
          <animateMotion dur='1s' fill='freeze'>
            <mpath href='#theMotionPath' />
          </animateMotion>
        </Dot>

        <motion.path id='theMotionPath' d={p} stroke='white' strokeWidth='2' fill='transparent' vectorEffect='non-scaling-stroke' />
        <motion.circle cx={ptStx} cy={ptSty} fill='white' r='0.7' />
        <motion.circle cx={ptEnx} cy={ptEny} fill='white' r='0.7' />
        <Dot
          className='svgbutton'
          role='button'
          tabIndex='0'
          aria-pressed='false'
          r='0.1'
          fill='none'
          onPan={onPan}
          onPanEnd={onPanEnd}
          onClick={onClick}
          onKeyDown={onKeyDown}
          stroke='rgba(255 255 255 / 0.3)'
          strokeWidth='50'
          vectorEffect='non-scaling-stroke'
        >
          <animateMotion dur='1s' fill='freeze'>
            <mpath href='#theMotionPath' />
          </animateMotion>
        </Dot>
        <motion.circle r='0.7' fill='white' vectorEffect='non-scaling-stroke'>
          <animateMotion dur='1s' fill='freeze'>
            <mpath href='#theMotionPath' />
          </animateMotion>
        </motion.circle>
        <DotStroke r='4.1' fill='none' strokeWidth='2' vectorEffect='non-scaling-stroke'>
          <animateMotion dur='1s' fill='freeze'>
            <mpath href='#theMotionPath' />
          </animateMotion>
        </DotStroke>
        <motion.circle className='svg-contrast' r='0.7' fill='black' vectorEffect='non-scaling-stroke'>
          <animateMotion dur='1s' fill='freeze'>
            <mpath href='#theMotionPath' />
          </animateMotion>
        </motion.circle>
      </Svg>
    </Container>
  )
}
