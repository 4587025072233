import styled from "@emotion/styled"
import { useConsole } from "contexts/Console"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import Video, { cssVideo, cssContainer, cssPoster, cssFallback } from "components/media/VideoCLD"
import Image from "components/media/ImageCLD"
// import { Icon } from "components/icon/Icon"

import PageHeading from "./simple/PageHeading"
import CTATxt from "./simple/CTATxt"
import { headline50, light, normal } from "css/text"

const Header = styled.header`
  ${fullGrid}
  position: relative;
  justify-content: center;

  overflow: hidden;

  z-index: 0;

  &.multirows {
    grid-template-rows: min-content min-content 40px;

    ${getMediaQuery("m")} {
      grid-template-rows: min-content min-content 60px;
    }

    & hgroup {
      padding-block-start: 0;
      align-self: center;
    }
  }

  & hgroup {
    padding-block-start: unset;
    align-self: center;
    grid-column: 1 col / span 6;

    ${getMediaQuery("m")} {
      grid-column: 2 col / span 10;
    }
    & > h1 {
      ${headline50}
      ${normal}
    font-family: "RolexFont-S", sans-serif;
    }
  }

  & hgroup > svg {
    width: 5rem;
    height: 5rem;
    fill: white;
    color: white;
    margin-block-end: 1.25rem;

    ${getMediaQuery("s", { max: true })} {
      width: 3.125rem;
      height: 3.125rem;
    }
  }

  & video {
    position: absolute;
  }

  & .img {
    display: grid;
    z-index: -1;
    grid-column: doc;
    grid-row: 1 / -1;
    /* grid-template-areas: "video"; */
    overflow: hidden;
    position: relative;
  }

  & .${cssContainer} {
    z-index: -1;
    grid-column: doc;
    grid-row: 1 / -1;

    .${cssVideo}, .${cssPoster}, .${cssFallback} {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .${cssVideo} {
      transform: translateY(var(--ypos)) scale(1.2);
    }
  }
`

export default function CoverRftc({ heading, heading_gradient, media, text, video_amp_cta, className, ...props }) {
  const console = useConsole()
  const { video_cld, image_cld } = media?.[0]

  const cl = text?.length && !text?.[0]?.link_404 ? " multirows" : ""

  return (
    <Header className={`${className}${cl}`}>
      <PageHeading
        animate
        heading={heading}
        heading_gradient={heading_gradient}
        link={text?.[0]?.link_404}
        video={video_cld}
        video_amp={video_amp_cta?.length ? video_amp_cta[0].video_player_cld : null}
        isLink={text?.length && !text?.[0]?.link_404}
        isRftc
      />
      {video_cld && <Video autoPlay noButton {...video_cld} sizes='100vw' />}
      {image_cld && <Image className='img' {...image_cld} sizes='100vw' />}
      {text?.length && !text?.[0]?.link_404 ? <CTATxt blocks={text} /> : null}
    </Header>
  )
}
