import { useConsole } from "contexts/Console"
import { usePage } from "contexts/Page"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import { useNavigation } from "contexts/Navigation"
import { useLocale } from "contexts/Locale"
import { useEnv } from "contexts/Env"

const META_MAP = new Map([
  ["ogtitle", "og:title"],
  ["ogdescription", "og:description"],
  ["ogurl", "og:url"],
  ["ogimage", "og:image"],
  ["ogsearchimage", "og:searchimage"],
  ["ogtype", "og:type"],
  ["ogsitename", "og:sitename"],
  ["searchSection", "search_section"],
  ["searchTitle", "search_title"],
])

export function populateMetaCS(data) {
  const head = process.browser ? document.documentElement.querySelector("head") : null
  if (!head) return
    ;[...Object.entries(data)]?.map(([k, v]) => {
      if (k === "title") {
        const el = head.querySelector(k)
        if (el) el.innerHTML = v
      }
      else {
        const el = head.querySelector(`meta[name="${META_MAP.has(k) ? META_MAP.get(k) : k}"]`)
        if (el) el.setAttribute("content", v)
      }
    })
}

function populateMetaSS(data) {
  return [...Object.entries(data)]?.map(([k, v]) => k === "lngpref" ? null : k === "title" ?
    <title key={k}>{v}</title> :
    v ? <meta key={k} name={META_MAP.has(k) ? META_MAP.get(k) : k} content={v} />
      : null
  )
}

function cleanMeta(data) {
  //  console.log("++++ cleanMeta", data)
  data = data ?? {}
  return [...Object.entries(data)].reduce((a, [k, v]) => {
    if (!!v) a[k] = v
    return a
  }, {})
}

function initMeta(page_meta, locale_meta, href, lng_prefix, env) {
  //  console.log("++++ initMeta", page_meta, locale_meta, href, lng_prefix, env)
  page_meta = page_meta ?? {}
  locale_meta = locale_meta ?? {}

  function fillUrl(v, lng_prefix) {
    let u = new URL(`${lng_prefix}${v}`, `https://${env.hostname}`).href
    //    if (u[u.length - 1] === "/") u = u.slice(0, -1)
    return u
  }

  return {
    title: page_meta.title,
    description: page_meta.description,
    image: page_meta.image,
    searchimage: page_meta.searchimage,
    ogtitle: page_meta.title_og || page_meta.title,
    ogdescription: page_meta.description_og || page_meta.description,
    ogurl: href || fillUrl(page_meta.url, lng_prefix),
    ogimage: page_meta.image_og_cld && new URL(page_meta.image_og_cld, `https://${env.cloudinary.secureDistribution}`),
    ogsearchimage: page_meta.searchimage_og_cld && new URL(page_meta.searchimage_og_cld, `https://${env.cloudinary.secureDistribution}`),
    ogtype: locale_meta.type_og,
    ogsitename: locale_meta.sitename_og,
    searchSection: page_meta.search_section,
    searchTitle: page_meta.searchTitle,
    lngpref: lng_prefix
  }
}

export function updateMeta(param, value, query_meta, lngpref, env) {
  const query_meta_config_param = query_meta?.find(qm => qm.param === param)
  //  console.log("++++ updateMeta query_meta_config_param", query_meta_config_param)
  if (!query_meta_config_param) return null
  const query_meta_param_value = query_meta_config_param.values.find(qmv => qmv.value === value)
  //  console.log("++++ updateMeta query_meta_param_value", query_meta_param_value)
  if (!query_meta_param_value) return null
  return cleanMeta(initMeta(query_meta_param_value, null, null, lngpref, env))
}

export default function Meta({ children, initialState, ctx }) {
  const console = useConsole()
  const location = useLocation()
  const page = usePage()
  const locale = useLocale()
  const navigation = useNavigation()
  const env = useEnv()

  const u = new URL(location.pathname, `https://${env.hostname}`)
  let href = u.href
  if (href[href.length - 1] === "/") href = href.slice(0, -1)
  let pathname = u.pathname
  if (pathname[pathname.length - 1] !== "/") pathname = `${pathname}/`
  let lng_prefix = pathname.match(/^\/[a-z]{2}([-_][a-zA-Z]{2,4})?\//) ?? ["/"]
  lng_prefix = lng_prefix[0].slice(0, -1)
  //  console.verbose("lng_prefix", lng_prefix)

  ctx.initialMeta = initMeta(page?.meta, initialState?.[locale.current.codes.www]?.meta, href, lng_prefix, env)
  const query_meta = initialState?.[locale.current.codes.www]?.query_meta
  const query_meta_param = page?.query_meta_param ?? []

  let virtualMeta = {}
  for (const param of query_meta_param) {
    const searchParamsValue = [...new URLSearchParams(location.search)].find(([k, v]) => k === param)
    virtualMeta = updateMeta(param, searchParamsValue?.[1], query_meta, lng_prefix, env) ?? {}
  }
  virtualMeta = Object.assign({}, ctx.initialMeta, virtualMeta)

  const canonical =
    !["wishlist page"].includes(page?.page_type) && // some pages do not have a canonical ?
    (() => {
      if (page?.content_in_english) {
        // CSR pages
        if (env.cn && locale.current.codes.www == "zh-hans") {
          const url = new URL(href)
          url.pathname = "/en" + url.pathname
          return url.href
        } else if (!env.cn && locale.current.codes.www != "fr") {
          const url = new URL(href)
          url.pathname = url.pathname.replace(/^\/[a-z]{2}([-_][a-zA-Z]{2,4})?\//, "/")
          return url.href
        } else {
          return href
        }
      } else {
        switch (locale.current.codes.www) {
          case "zh-hans": {
            // if zh-hans, force .cn with no locale code in url
            const url = new URL(href)
            url.hostname = "www.rolex.cn"
            url.pathname = url.pathname.replace(/^\/zh-hans/, "")
            return url.href
          }
          // case "zh-hant": { /** zh-hant is not present anymore on rolex.cn */
          //   // if zh-hant, force .cn
          //   const url = new URL(href)
          //   url.hostname = "rolex.cn"
          //   return url.href
          // }
          default:
            return href
        }
      }
    })()

  const alternates =
    !env.cn && locale.current.codes.www == "zh-hans" // No alternates for zh-hans on .com, only canonical (pointing to .cn)
      ? []
      : [
        { hrefLang: "X-default", code: locale.defaultLocale.codes.www },
        { hrefLang: "en-cn", code: "en", external: env.cn ? false : true },
        ...[
          ...locale.locales.map(({ codes: { www: code }, external }) => ({ hrefLang: code, code, external })),
          ...(locale.externals ?? []).map(({ codes: { www: code }, external }) => ({ hrefLang: code, code, external })),
        ],
      ].map(({ hrefLang, code, external }) => {
        const pathname = navigation.localize(navigation.slug, code)
        const origin = env.cn && external ? "https://www.rolex.com" : `https://${env.hostname}` // TODO cn parity with current env

        let href
        try {
          href = new URL(pathname, origin).href
        } catch {
          href = new URL(pathname, "https://www.rolex.com").href
        }

        if (!env.cn && (hrefLang == "zh-hans" || hrefLang == "en-cn")) {
          const url = new URL(href)
          url.hostname = "www.rolex.cn"
          if (hrefLang == "zh-hans") url.pathname = url.pathname.replace(/^\/zh-hans/, "")
          if (hrefLang == "en-cn") url.pathname = "/en" + url.pathname
          href = url.href
        }

        if (env.cn && hrefLang == "en") {
          const url = new URL(href)
          url.hostname = "www.rolex.com"
          url.pathname = url.pathname.replace(/^\/en/, "")
          href = url.href
        }

        if (href[href.length - 1] === "/") href = href.slice(0, -1)

        return { hrefLang, href }
      })

  console.verbose("Meta(%o)", ctx.initialMeta)
  return (
    <>
      <Helmet>
        {populateMetaSS(virtualMeta)}
        {canonical && <link rel='canonical' href={canonical} />}
        {alternates.map(({ hrefLang, href }) => (
          <link key={hrefLang} rel='alternate' hrefLang={hrefLang} href={href} />
        ))}
      </Helmet>
      {children}
    </>
  )
}
