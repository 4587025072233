import { createContext, useContext, useLayoutEffect, useRef } from "react"
import { useMotionValue, useMotionValueEvent } from "framer-motion"
import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"
import { useNavigation } from "contexts/Navigation"
import { useLocale } from "contexts/Locale"
import XPlitQuery from "./XplitQuery"

export const Context = createContext()

async function load(path) {
  return Promise.resolve(new URL(path, process.browser ? global.location.origin : process.env.REVERSE_PROXY_URL))
    .then(url => new Request(url))
    .then(request => fetch(request))
    .then(response => response.json())
    .then(body => {
      return [null, body]
    })
    .catch(err => [err, {}])
}

async function getData({ navigation, locale }) {
  const path = navigation.localize("/xplit.extra.json", locale.current.codes.www)
  const d = await load(path)
  return d[1][0]
}

export const XplitProvider = ({ children, initialState }) => {
  const console = useConsole()
  const navigation = useNavigation()
  const locale = useLocale()
  const query_meta = initialState?.[locale.current.codes.www]?.query_meta

  const isOpen = useMotionValue(false)
  const data = useMotionValue("")
  const triggerer = useRef(null)

  async function loadData() {
    const d = await getData({ navigation, locale })
    if (!d) return
    data.set(JSON.stringify(d))
  }

  function clearData() {
    data.set("")
    triggerer.current = null
  }

  const xp_query = useMotionValue(null)

  function xp_updateQuery(s, c) {
    if (s < 0 || c < 0) xp_query.set("-1")
    else xp_query.set([s, c].join("/"))
  }

  return <Context.Provider value={{ isOpen, data, loadData, clearData, xp_query, xp_updateQuery, query_meta, triggerer }}>
    <XPlitQuery />
    {children}
  </Context.Provider>
}

export const useXplit = () => useContext(Context)
