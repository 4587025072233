import { useRef, useLayoutEffect, useEffect, useState } from "react"
import { useMotionValueEvent, motion, useTransform, useMotionValue } from "framer-motion"
import styled from "@emotion/styled"
import { FocusScope } from "@react-aria/focus"
import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"

import { buttonReset, buttonContrastMode } from "css/buttons"
import { body50, bold, headline70 } from "css/text"
import getMediaQuery from "css/breakpoints"

import useScrollLock from "hooks/useScrollLock"

import Image from "components/media/ImageCLD"
import { useExp, MODEL_A, MODEL_B, CANVINDEX, THE_INTRODUCTION } from "./expcontext"
import { useXplit } from "./context"
import Screen from "./Screen"
import GlobalContainer from "./GlobalContainer"
import { SwitcherProvider } from "./switchcontext"
import Foreground from "./ui/Foreground"
import SvgHoneycomb, { VBOXW, VBOXH, XPTS, YPTS } from "./ui/SvgHoneycomb"
import XpStrip from "./ui/XpStrip"
import XplitWA from "./XplitWA"
import { useStory } from "components/stories/context"
//import XplitDebug from "./XplitDebug"

const SectionStandAlone = styled(motion.section)`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;

  & h1,
  h2,
  h3,
  p,
  span {
    user-select: none;
  }

  --strip-height: 40px;
  --sharemargin: 25px;
  --arrowmargin: calc(var(--outer-margin) / 2 - var(--btn-height) / 2 - var(--grid-gap) / 2);

  ${getMediaQuery("m")} {
    --strip-height: 50px;
    --sharemargin: 40px;
    --arrowmargin: 2vw;
  }

  ${getMediaQuery("l")} {
    --arrowmargin: calc(var(--outer-margin) / 2 - var(--btn-height) / 2 - var(--grid-gap) / 2);
  }

  &.no-focus-outline {
    & button.focus-visible {
      outline: none !important;
    }
  }
`

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
`

const BackG = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background: #212121;
  pointer-events: none;
`

const SVGButtons = styled(motion.svg)`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  pointer-events: none;
  & > svg {
    pointer-events: fill;
    cursor: pointer;
  }
`

const Heading = styled(motion.h1)`
  position: relative;
  ${headline70}
  grid-column: 1 / -1;
  grid-row: 1;
  text-align: center;
  margin-block-end: 10px;

  html.prefers-contrast & {
    color: black !important;
    -webkit-text-fill-color: inherit !important;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 20px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

const EnterBtn = styled(motion.button)`
  ${buttonReset}
  ${buttonContrastMode}
  ${bold}
  height: 44px;
  overflow: hidden;
  border-radius: 44px;
  display: flex;
  border: none;
  z-index: 2;
  align-items: center;
  pointer-events: inherit;
  user-select: none;
  cursor: pointer;
  padding-inline: 2.1875rem;

  --hover-background: rgba(12 12 12 / 0.3);
  --hover-color: white;
  color: rgb(var(--light-black));
  background: white;

  font-size: 1rem;
  transition: all 0.3s;
  white-space: nowrap;
  margin-block-end: 20px;
  backdrop-filter: blur(8px);

  &:first-of-type {
    grid-row: -1;
  }

  ${getMediaQuery("m")} {
    margin-block-end: 0;

    &:first-of-type {
      grid-row: unset;
    }
  }

  @media (hover: hover) {
    &:hover {
      background: var(--hover-background);
      color: var(--hover-color);
    }
  }
  &.hover {
    background: var(--hover-background);
    color: var(--hover-color);
  }
`

const TextsCont = styled(motion.div)`
  position: absolute;
  pointer-events: none;
  inset-block-start: 16%;
  inset-inline-start: 50%;
  transform: translate(-50%, 0);
  display: grid;
  width: 85%;
  height: auto;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content;
  align-items: center;
  justify-items: center;
  grid-row-gap: 10px;
  grid-column-gap: 40px;

  & > button {
    pointer-events: all;
  }

  ${getMediaQuery("m")} {
    width: 40%;
    inset-block-start: 50%;
    transform: translate(-50%, -50%);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content min-content;

    & > button:first-of-type {
      justify-self: end;
      margin-block-end: 0;
    }
    & > button:last-of-type {
      justify-self: start;
    }
  }
`

const Paragraph = styled(motion.p)`
  position: relative;
  ${body50};
  grid-column: 1 / -1;
  grid-row: 2;
  text-align: center;
  width: 100%;
  margin-block-end: 20px;
  ${getMediaQuery("m")} {
    width: 70%;
  }

  html.prefers-contrast & {
    color: black !important;
    -webkit-text-fill-color: inherit !important;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 20px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

const BgCont = styled(motion.div)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

function KeyScape() {
  const console = useConsole()
  const { currentInteraction, shared } = useExp()
  const { xp_query } = useXplit()
  const last_interaction = useRef(null)
  const { storyOpener } = useStory()
  const shared_state = useRef(shared.get())

  function close() {
    currentInteraction.set("close")
    xp_query.set(null)
  }

  function onKeyDownCapture(e) {
    if (e.key !== "Escape") return
    if (shared_state.current) return
    if (!last_interaction.current || ["restore", "escape"].includes(last_interaction.current)) return close()
    if (storyOpener.get()) return storyOpener.set("")
    if (last_interaction.current.indexOf("watch:") === 0) return currentInteraction.set("escape")
  }

  function onCurrentInteraction(v) {
    if (v) last_interaction.current = v
  }
  useMotionValueEvent(currentInteraction, "change", onCurrentInteraction)

  function onShared(v) {
    const delay = v ? 0 : 100
    setTimeout(() => (shared_state.current = v), delay)
  }
  useMotionValueEvent(shared, "change", onShared)

  useEffect(() => {
    window.addEventListener("keyup", onKeyDownCapture, { capture: true })

    return () => window.removeEventListener("keyup", onKeyDownCapture, { capture: true })
  }, [])

  return null
}

function ButtonEnter({ index, isHover, cta, onHovStart, onHovEnd, onClick, ...attrs }) {
  const console = useConsole()

  const { currentStep } = useExp()

  const ref = useRef()

  function onHov(h) {
    if (h === index) {
      ref.current.classList.add("hover")
    } else {
      ref.current.classList.remove("hover")
    }
  }
  useMotionValueEvent(isHover, "change", onHov)

  function onStep(s) {
    ref.current.disabled = s >= THE_INTRODUCTION
  }
  useMotionValueEvent(currentStep, "change", onStep)

  useEffect(() => {
    onStep(currentStep.get())
  }, [])

  return (
    <EnterBtn
      type='button'
      ref={ref}
      className='no-outline'
      onClick={onClick}
      aria-label={cta.aria_label}
      onHoverStart={onHovStart}
      onHoverEnd={onHovEnd}
      {...attrs}
    >
      {cta.label}
    </EnterBtn>
  )
}

function SplitScreenContainer({ introduction, gridTemplate, isHover }) {
  return (
    <Container>
      <BgCont>
        <Image sources={introduction.bg} sizes='100vw' />
      </BgCont>
      <Screen index={MODEL_A} {...introduction.content[MODEL_A]} gridTemplate={gridTemplate} isHover={isHover} />
      <Screen index={MODEL_B} {...introduction.content[MODEL_B]} gridTemplate={gridTemplate} isHover={isHover} />
    </Container>
  )
}

function SplitScreenContent() {
  const console = useConsole()

  const { scenario, heading, paragraph, introduction, enterCtas, currentStep, currentChapter, targetScenario, device, xp_start } = useExp()
  const { width } = useViewport()
  const straight = scenario.get() >= 0

  const height = useMotionValue(process.browser ? window.innerHeight : 0)

  const gridTemplate = useTransform([width, height, device], ([w, h, d]) => {
    const nw = VBOXW[d] / VBOXH[d] > w / h ? w : VBOXW[d] * (h / VBOXH[d])
    const nh = VBOXW[d] / VBOXH[d] > w / h ? VBOXH[d] * (w / VBOXW[d]) : h
    const ow = (w - nw) / 2
    const oh = (h - nh) / 2

    const x1 = ow + (XPTS[d][0] / VBOXW[d]) * nw
    const x2 = ow + (XPTS[d][1] / VBOXW[d]) * nw - x1
    const x3 = ow + (XPTS[d][2] / VBOXW[d]) * nw - x1 - x2
    const x4 = ow + (XPTS[d][3] / VBOXW[d]) * nw - x1 - x2 - x3

    const y1 = oh + (YPTS[d][0] / VBOXH[d]) * nh
    const y2 = oh + (YPTS[d][1] / VBOXH[d]) * nh - y1
    const y3 = oh + (YPTS[d][2] / VBOXH[d]) * nh - y1 - y2
    const y4 = d === 0 ? "1fr" : `${oh + (YPTS[d][3] / VBOXH[d]) * nh - y1 - y2 - y3}px auto`

    return `${y1}px ${y2}px ${y3}px ${y4} / ${x1}px ${x2}px ${x3}px ${x4}px auto`
  })

  const pointerEvents = useMotionValue("fill")

  function openXP(i) {
    pointerEvents.set("none")
    currentChapter.set(THE_INTRODUCTION)
    currentStep.set(THE_INTRODUCTION)
    scenario.set(i)
    targetScenario.set(i)
  }

  const isHover = useMotionValue(-1)

  function onClickModelA() {
    openXP(MODEL_A)
  }
  function onClickModelB() {
    openXP(MODEL_B)
  }
  function onHoverStartModelA() {
    isHover.set(0)
  }
  function onHoverStartModelB() {
    isHover.set(1)
  }
  function onHoverEndModel() {
    isHover.set(-1)
  }

  const onResize = () => {
    height.set(window.innerHeight)
  }

  useLayoutEffect(() => {
    global.addEventListener("resize", onResize, { passive: true })
    return () => global.removeEventListener("resize", onResize)
  }, [])

  return (
    <>
      <SvgHoneycomb />
      <BackG />
      <SplitScreenContainer introduction={introduction} gridTemplate={gridTemplate} isHover={isHover} />
      {!straight ? (
        <>
          <SVGButtons>
            <motion.svg onClick={onClickModelA} fill='none' style={{ pointerEvents }} onHoverStart={onHoverStartModelA} onHoverEnd={onHoverEndModel}>
              <use x='0' y='0' href={`#polyshape${MODEL_A}`} />
            </motion.svg>
            <motion.svg onClick={onClickModelB} fill='none' style={{ pointerEvents }} onHoverStart={onHoverStartModelB} onHoverEnd={onHoverEndModel}>
              <use x='0' y='0' href={`#polyshape${MODEL_B}`} />
            </motion.svg>
          </SVGButtons>
          <TextsCont>
            <Heading id='xp-watchname'>{heading}</Heading>
            <Paragraph>{paragraph}</Paragraph>
            {enterCtas.map((cta, i) => (
              <ButtonEnter
                key={`entercta-${i.toString()}`}
                index={i}
                cta={cta}
                onHovStart={i === 0 ? onHoverStartModelA : onHoverStartModelB}
                onHovEnd={onHoverEndModel}
                isHover={isHover}
                onClick={i === 0 ? onClickModelA : onClickModelB}
                aria-describedby='xp-watchname'
              />
            ))}
          </TextsCont>
        </>
      ) : null}
      <GlobalContainer />
      <XpStrip />
      <Foreground />
      <XplitWA />
      <KeyScape />
      {/*<XplitDebug />*/}
    </>
  )
}

export default function SplitScreen() {
  const console = useConsole()
  const scrollLock = useScrollLock()

  const { nav_link = {}, currentInteraction, seqlogic, shared } = useExp()
  const { clearData, triggerer } = useXplit()
  const ref = useRef()

  useLayoutEffect(() => {
    const r = ref.current
    scrollLock.lock(r)

    return () => scrollLock.unlock(r)
  }, [])

  const [contain, setContain] = useState(true)
  function onShared(v) {
    setContain(!v)
  }
  useMotionValueEvent(shared, "change", onShared)

  useLayoutEffect(() => {
    ref.current?.addEventListener("keydown", onClick, { capture: true, once: true })
    ref.current?.addEventListener("click", onClick, { capture: true, once: true })

    return () => {
      ref.current?.removeEventListener("keydown", onClick, { capture: true, once: true })
      ref.current?.removeEventListener("click", onClick, { capture: true, once: true })
    }

    function onClick(e) {
      ref.current.classList.remove("no-focus-outline")
    }
  }, [])

  return (
    <FocusScope contain={contain} restoreFocus autoFocus aria-modal='true'>
      <SwitcherProvider seqlogic={seqlogic}>
        <SectionStandAlone
          ref={ref}
          className={`dark-theme${triggerer.current ? "" : " no-focus-outline"}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ "--canvindex": CANVINDEX }}
          dir='ltr'
        >
          <SplitScreenContent />
        </SectionStandAlone>
      </SwitcherProvider>
    </FocusScope>
  )
}
