import { useEffect, useRef, useState } from "react"
import { motion } from "framer-motion"
import styled from "@emotion/styled"

import { useDictionary } from "contexts/Dictionary"

import { a11yOutline } from "css/a11y"
import getMediaQuery from "css/breakpoints"

import { useLightBox } from "./context"

const PageContainer = styled.div`
  z-index: 1;
  grid-row: 1;
  grid-column: doc;
  display: flex;
  gap: 12px;
  border: none;
  padding: 0;
  margin: 0;
  height: fit-content;

  align-self: end;
  justify-self: center;

  position: sticky;
  bottom: 2.5rem;
  margin-block-end: 2.5rem;

  // ${getMediaQuery("m")} {
  //   margin-block-end: 5rem;
  // }

  ${getMediaQuery("m")} {
    margin-block-end: 5rem;
  }
  html.prefers-contrast & {
    color: black !important;
    position: relative;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

const PageGroup = styled.div`
  display: flex;
  & input.focus-visible + label {
    ${a11yOutline}
  }
`

const PageRadio = styled(motion.button)`
  position: relative;
  width: 2.5rem;
  height: 0.25rem;
  border-radius: 5px;
  background: white;
  border: none;
  padding: 0;
  pointer-events: all;

  html.prefers-contrast & {
    background: black;
  }

  @media (hover: hover) and (pointer: fine) {
    &.inactive:hover {
      cursor: pointer;
    }
  }

  &.inactive::after {
    content: "";
    position: absolute;
    display: block;
    height: 30px;
    width: 120%;
    background: transparent;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

function Page({ id, index, num }) {
  const dictionary = useDictionary()

  const { currentIndex, targetSlide } = useLightBox()

  const ref = useRef()

  const [active, setActive] = useState(currentIndex.get() === index)

  function onSlideChange(i) {
    setActive(index === i)
  }
  useEffect(() => currentIndex.onChange(onSlideChange), [])

  const variants = {
    active: { width: "6.25rem" },
    inactive: { width: "2.5rem" },
  }

  function onKeyDown(e) {
    if (e.code !== "Enter") return
    targetSlide.set(index)
  }

  return (
    <PageGroup>
      <PageRadio
        ref={ref}
        aria-label={dictionary.ariaLabelGoToPhoto(index + 1, num)}
        key={`page-${id}`}
        onClick={() => targetSlide.set(index)}
        onKeyDown={onKeyDown}
        type='button'
        variants={variants}
        initial={active ? "active" : "inactive"}
        animate={active ? "active" : "inactive"}
        className={active ? "active" : "inactive"}
        transition={{ type: "tween", ease: "easeOut", duration: 0.2 }}
        aria-disabled={active ? "true" : null}
        tabIndex={active ? -1 : null}
        aria-curent={active ? true : null}
      />
    </PageGroup>
  )
}

export default function Pagination() {
  const { lightBoxes, targetSlide } = useLightBox()

  const ref = useRef()

  const onChange = e => targetSlide.set(Number(e.target.value))

  return (
    <PageContainer ref={ref} onChange={onChange}>
      {Array.from(lightBoxes).map(([id], i) => (
        <Page key={`page-${id}`} id={`page-${id}`} index={i} num={lightBoxes.size} />
      ))}
    </PageContainer>
  )
}
