import { useConsole } from "contexts/Console"
import { useLayoutEffect } from "react"
import { useMotionValueEvent } from "framer-motion"
import { useXplit } from "./context"
import { useLocation } from "react-router-dom"

export const QUERY_PARAM = "experience"
export const QUERY_MAP = new Map([
    ["welcome-screen", "-1"],
    ["40/chapter-intro", "0/0"],
    ["40/chapter-design", "0/111"],
    ["40/chapter-movement", "0/201"],
    ["40/chapter-final", "0/300"],
    ["36/chapter-intro", "1/0"],
    ["36/chapter-design", "1/111"],
    ["36/chapter-movement", "1/201"],
    ["36/chapter-final", "1/300"],
])
export const R_QUERY_MAP = new Map([
    ["-1", "welcome-screen"],
    ["0/0", "40/chapter-intro"],
    ["0/111", "40/chapter-design"],
    ["0/201", "40/chapter-movement"],
    ["0/300", "40/chapter-final"],
    ["1/0", "36/chapter-intro"],
    ["1/111", "36/chapter-design"],
    ["1/201", "36/chapter-movement"],
    ["1/300", "36/chapter-final"],
])

function getCurrentUrl() {
    return new URL(process.browser ? global.location.href : "https://www.rolex.com")
}

function updateCurrentUrl(v) {
    if (!process.browser) return
    const currentUrl = getCurrentUrl()
    const searchParams = new URLSearchParams(currentUrl.search)
    const param = searchParams.get(QUERY_PARAM)
    if (param === v) return
    if (v === null) searchParams.delete(QUERY_PARAM)
    else searchParams.set(QUERY_PARAM, v)
    currentUrl.search = searchParams.toString()
    //    alert("push")
    window.history.pushState({}, "", decodeURIComponent(currentUrl.href))
}

export default function XplitQuery() {
    const console = useConsole()
    const { data, loadData, clearData, xp_query, triggerer } = useXplit()
    const location = useLocation()
    const currentUrl = getCurrentUrl()

    function getCurrentXp() {
        const params = currentUrl.searchParams
        let param = params.get(QUERY_PARAM)
        console.log("getCurrentXp", param, QUERY_MAP.has(param))
        if (!QUERY_MAP.has(param)) param = null
        else param = QUERY_MAP.get(param)
        return param
    }

    function onData(v) {
        xp_query.set(!v ? null : xp_query.get() ?? "-1")
    }
    useMotionValueEvent(data, "change", onData)

    function onXpQuery(v) {
        if (v !== null)
            v = R_QUERY_MAP.get(v) ?? [...R_QUERY_MAP.values()][0]
        else {
            clearData()
            triggerer.current?.focus()
        }
        updateCurrentUrl(v)
    }
    useMotionValueEvent(xp_query, "change", onXpQuery)

    useLayoutEffect(() => {
        const xp = getCurrentXp()
        xp_query.set(xp)
        if (xp_query.get() && !data.get()) loadData()
    }, [location])

    return null
}
