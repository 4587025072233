import { useRef, useEffect, useLayoutEffect } from "react"
import { animate } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import useConstant from "hooks/useConstant"

import Video, { cssVideo, cssContainer, cssPoster, cssFallback } from "./VideoMedia"

import { useStory } from "../../context"
import { getSlugIndex, getNextSlug, getStoryIndex } from "../utils"
import { useProgress } from "../progressContext"
import { usePlayer } from "../context"

const Root = styled.div`
  width: 100%;
  height: 100%;

  grid-column: doc;
  grid-row: card;

  & .${cssVideo}, .${cssPoster}, .${cssFallback} {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & .${cssVideo} {
    width: 100%;
    height: 100%;
  }
  & .${cssContainer} {
    height: 100%;
  }
`

export default function VideoMedia({ slugId, indexOfStory, prefersReducedMotion, ...data }) {
  const console = useConsole()

  console.verbose("\n\n•••••• VideoMedia data", data)

  const { storyOpener } = useStory()
  const { slug, slugList, slugMap, isHolding, currentStoryIndex, isAnimDisabled, shouldReduceMotion } = usePlayer()
  const { progressMap, showProgress } = useProgress()

  const animProg = useConstant(() => progressMap.get(slugId))

  const vidRef = useRef()
  const raf = useRef()

  const loop = () => {
    update()
    raf.current = requestAnimationFrame(loop)
  }

  const update = () => {
    if (!vidRef.current || vidRef.current.paused) return
    if (!isAnimDisabled.get()) {
      animProg.set(vidRef.current.currentTime / vidRef.current.duration)
    } else {
      animProg.set(1)
    }
    if (vidRef.current.currentTime === vidRef.current.duration) {
      cancelAnimationFrame(raf.current)
    }
  }

  function play() {
    if (prefersReducedMotion) {
      vidRef.current.currentTime = 0
      animProg.set(1)
      return
    }
    loop()
    vidRef.current.play()
  }

  function stop() {
    vidRef.current.pause()
    cancelAnimationFrame(raf.current)
    if (!isAnimDisabled.get()) {
      animProg.set(vidRef.current.currentTime / vidRef.current.duration)
    } else {
      animProg.set(1)
    }
  }

  function onEnded() {
    const nextslug = getNextSlug(slugList, slugId)
    if (!isAnimDisabled.get()) {
      if (nextslug === slugId && showProgress) {
        storyOpener.set("")
      } else {
        slug.set(nextslug)
      }
    }
  }

  function onHoldingChange(bool) {
    if (!shouldReduceMotion) {
      if (slug.get() === slugId) {
        if (bool) {
          stop()
        } else {
          play()
        }
      }
    }
  }
  useEffect(() => isHolding.onChange(onHoldingChange))

  function onCurStoIndexChange(i) {
    if (i !== indexOfStory) {
      if (!shouldReduceMotion) {
        stop()
        vidRef.current.currentTime = 0
        animProg.set(0)
      }
    }
  }
  useEffect(() => currentStoryIndex.onChange(onCurStoIndexChange))

  function onSlugChange(s) {
    if (s === slugId) {
      if (!shouldReduceMotion) {
        animProg.set(0)
        vidRef.current.currentTime = 0
        play()
      }
    } else {
      if (!shouldReduceMotion) {
        stop()
      }
      if (indexOfStory === getStoryIndex(slugMap, s)) {
        if (getSlugIndex(slugList, slugId) < getSlugIndex(slugList, s)) {
          animate(animProg, 1, { duration: 0.1, ease: "linear" })
        } else {
          animate(animProg, 0, { duration: 0.1, ease: "linear" })
        }
      }
    }
  }
  useEffect(() => slug.onChange(onSlugChange))

  useLayoutEffect(function init() {
    if (!shouldReduceMotion) {
      if (slug.get() === slugId) {
        vidRef.current.currentTime = 0
        requestAnimationFrame(() => play())
      } else {
        vidRef.current.currentTime = 0
        if (getSlugIndex(slugList, slugId) < getSlugIndex(slugList, slug.get())) {
          animProg.set(1)
        }
      }
    } else {
      animProg.set(1)
    }

    return () => cancelAnimationFrame(raf.current)
  }, [])

  return (
    <Root onEnded={onEnded}>
      <Video ref={vidRef} {...data} noButton sizes='100vw' loop={false} />
    </Root>
  )
}
