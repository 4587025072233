import styled from "@emotion/styled"

import { useLocale } from "contexts/Locale"
import { useNavigation } from "contexts/Navigation"
import { useQuickViews } from "contexts/QuickViews"
import { useDictionary } from "contexts/Dictionary"

import { buttonReset } from "css/buttons"

import { Icon } from "components/icon/Icon"
import { load, mapFeature, mapFacet } from "./utils"
import { useConsole } from "contexts/Console"

const Btn = styled.button`
  ${buttonReset}
  display: inline-flex;

  @media (hover: hover) and (pointer: fine) {
    transition: color 0.3s;

    &:hover {
      color: rgb(var(--green));
    }
  }

  .dark-theme & {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: rgb(var(--grey));
      }
    }
  }
`

export function ISimpButton({ feature, listObj, list, ariaDescribedby, gridData }) {
  const console = useConsole()
  const navigation = useNavigation()
  const locale = useLocale()
  const dictionary = useDictionary()

  const { qvContent, track } = useQuickViews()

  console.verbose("\n•• ISimpButton", feature, listObj, list)
  console.verbose("•• gridData", gridData)

  const f = mapFeature(feature)

  console.verbose("\n•••••••• f", f)

  // bracelet_id
  // case_id

  const filtered = list.filter(({ techCode }) => !!mapFacet(techCode))

  const red = filtered.reduce((acc, { techCode, topicLabel }) => {
    const mappedTechCode = `${f}-${mapFacet(techCode)}`
    const assetPathBase = `/rolexcom/quick-views/${f}/${mappedTechCode}`

    acc.push({
      techCode: mappedTechCode,
      topicLabel,
      assetPath: {
        portrait: `${assetPathBase}-portrait`,
        landscape: assetPathBase,
      },
    })

    return acc
  }, [])

  const path =
    f === "family"
      ? navigation.localize("/collection/family.extra.json", locale.current.codes.www)
      : navigation.localize(`/term/${f}.extra.json`, locale.current.codes.www)

  async function onOpenQuickViews() {
    const data = await load(path).catch(err => console.verbose("••• err", err))

    const cleaned = red.map(({ techCode }) => data[1].find(x => x.tech_code === techCode))
    const str = JSON.stringify({ items: cleaned, start: 0, obj: red, feature: f })

    const techCode = red[0].techCode

    track({ event: "Click", techCode, step: f, index: 0 })

    qvContent.set(str)
  }

  async function onOpenQuickViewsCollection() {
    const data = await load(path).catch(err => console.verbose("••• err", err))
    const techCodes = new Set(list.map(item => item.techCode))

    function mapDataItems(data) {
      const cleaned = data.filter(item => techCodes.has(item.param))

      return cleaned.map(item => {
        const heading = item.heading?.title || item.heading
        const topicLabel = item.heading?.kicker
        const paragraph = item.heading?.paragraph || item.paragraph
        const tech_code = item.tech_code || ""

        return {
          ...item,
          heading,
          topicLabel,
          paragraph,
          tech_code,
        }
      })
    }

    function mapDataObj(obj, data) {
      const cleaned = data.filter(item => techCodes.has(item.param))

      return obj.map((item, index) => {
        const imageSources = cleaned[index]?.image_cld_alt?.sources || []
        const theme = cleaned[index]?.theme.theme || "light-theme"

        return {
          ...item,
          theme,
          assetPath: {
            landscape: imageSources[0]?.public_id || "",
            portrait: imageSources[1]?.public_id || "",
          },
        }
      })
    }

    const str = JSON.stringify({ items: mapDataItems(data[1]), start: 0, obj: mapDataObj(red, data[1]), feature: f })

    qvContent.set(str)
  }

  return (
    <Btn
      type='button'
      className='info-btn'
      onClick={f === "family" ? onOpenQuickViewsCollection : onOpenQuickViews}
      aria-describedby={ariaDescribedby}
      aria-label={dictionary.moreInformation()}
    >
      <Icon type='infoRound' size='16' />
    </Btn>
  )
}

export default function IButton({ feature, cur, step, listObj, ariaDescribedby }) {
  const console = useConsole()
  const navigation = useNavigation()
  const locale = useLocale()
  const dictionary = useDictionary()

  const { qvContent, track } = useQuickViews()

  const f = mapFeature(feature)
  const path = navigation.localize(`/term/${f}.extra.json`, locale.current.codes.www)
  const r = listObj.reduce((acc, o) => acc.concat([o[f]]), [])
  const wa = listObj.reduce((acc, o) => acc.concat([o.waProd]), [])

  console.verbose("\n•• IButton", listObj)

  async function onOpenQuickViews() {
    const data = await load(path).catch(err => console.verbose("••• err", err))

    const cleaned = r.map(({ techCode }) => data[1].find(x => x.tech_code === `${f}-${techCode}`))
    const start = cur?.get() ? cur.get() : 0
    const str = JSON.stringify({ items: cleaned, start: start, apply: !!step?.get(), obj: r, wa, feature: f })

    const techCode = listObj[start][f].techCode
    const product = listObj[start].waProd

    track({ event: "Click", product, techCode, step: f, index: start })

    qvContent.set(str)
  }

  return (
    <Btn type='button' className='info-btn' onClick={onOpenQuickViews} aria-describedby={ariaDescribedby} aria-label={dictionary.moreInformation()}>
      {/* <span> */}
      <Icon type='infoRound' size='16' />
      {/* </span> */}
    </Btn>
  )
}
