import { motion } from "framer-motion"
import styled from "@emotion/styled"

import { buttonReset, buttonContrastMode } from "css/buttons"
import getMediaQuery from "css/breakpoints"

import { Icon } from "components/icon/Icon"

const PrevCont = styled(motion.div)`
  position: absolute;
  inset-inline-start: var(--arrowmargin);
  inset-block-start: 50%;
  z-index: 7;

  display: none;

  ${getMediaQuery("m")} {
    display: block;
  }
`

const Butt = styled(motion.button)`
  ${buttonReset}
  ${buttonContrastMode}
  display: flex;
  color: #212121;
  align-items: center;
  justify-content: center;
  width: var(--btn-height);
  height: var(--btn-height);
  background: white;
  border-radius: 50%;
  cursor: pointer;
`

export default function Previous({ onClickCB }) {
  const variants = {
    hidden: { opacity: 0, transition: { duration: 0 } },
    visible: { opacity: 1, transition: { duration: 1.5, delay: 0.4, type: "tween", ease: "easeOut" } },
  }

  return (
    <PrevCont variants={variants} initial='hidden' animate='visible' exit='hidden'>
      <motion.div>
        <Butt type="button" onClick={onClickCB} aria-label="Previous interaction">
          <Icon type='movePrevious' />
        </Butt>
      </motion.div>
    </PrevCont>
  )
}
