import hash from "@emotion/hash"

export const cssStartVar = `--${hash("roller-start")}`
export const cssTxtStartVar = `--${hash("text-align")}`
export const cssWidthVar = `--${hash("text-width")}`

export const startCol = {
  "first-col": `c${hash("first-col")}`,
  "second-col": `c${hash("second-col")}`,
  "third-col": `c${hash("third-col")}`,
  "fourth-col": `c${hash("fourth-col")}`,
}

export const txtWidth = {
  s: `w${hash("s-width")}`,
  m: `w${hash("m-width")}`,
  l: `w${hash("l-width")}`,
}
export const txtAlign = {
  start: `a${hash("txt-start")}`,
  center: `a${hash("txt-center")}`,
  end: `a${hash("txt-end")}`,
}
