import { createContext, useContext } from "react"
import { useMotionValue, useMotionValueEvent } from "framer-motion"

import { useConsole } from "contexts/Console"

import { THE_WATCH, THE_WATCH_HUB, useExp } from "../expcontext"

export const Context = createContext()

export const PXPF = 15

export const HubProvider = ({ data, children }) => {
  const console = useConsole()

  const { device } = useExp()

  const gradientProg = useMotionValue(0)

  const seqloaders = data[THE_WATCH_HUB].reduce((acc, x) => acc.concat([x.seqloader]), [])
  const hubframes = [null, null]
  hubframes[device.get()] = data[THE_WATCH_HUB].reduce((acc, x) => acc.concat(x.seqloader[device.get()]), [])
  const max = hubframes[device.get()].length * PXPF

  const features = Array.from(Array(data[THE_WATCH_HUB].length)).map((_, i) => ({ ...data[THE_WATCH_HUB + i + 1] }))

  const kpts = data[THE_WATCH].nbfs.reduce((r, c, i) => r.concat([i ? c + r[i - 1] : c]), []).map((v, i) => [(v - data[THE_WATCH].nbfs[i]) * PXPF, v * PXPF])

  const kpt = data[THE_WATCH].nbfs
    .reduce((a, x, i, ar) => a.concat([i < ar.length - 1 ? x + a[i] : []]), [0])
    .flat()
    .map(v => v * PXPF)

  const heading = data[THE_WATCH].heading

  function onDevice(d) {
    hubframes[d] = data[THE_WATCH_HUB].reduce((acc, x) => acc.concat(x.seqloader[d]), [])
  }
  useMotionValueEvent(device, "change", onDevice)

  return <Context.Provider value={{ gradientProg, max, data, seqloaders, hubframes, features, kpts, kpt, heading }}>{children}</Context.Provider>
}

export const useHub = () => useContext(Context)
