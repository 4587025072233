import { useLayoutEffect } from "react"
import { motion } from "framer-motion"
import styled from "@emotion/styled"

import { buttonReset, buttonContrastMode } from "css/buttons"
import { bold } from "css/text"

import { useConsole } from "contexts/Console"

import { useStory } from "components/stories/context"
import { Icon } from "components/icon/Icon"
import { useExp } from "./../expcontext"
import getMediaQuery from "css/breakpoints"

const Chip = styled.div`
  position: relative;
  inset: 0;
  width: 44px;
  height: 44px;
  z-index: 6;
  display: flex;

  &.storychip-0 {
    grid-column: 2;
    grid-row: 5 / span 2;
    justify-content: start;
    align-self: center;
    justify-self: start;
  }
  &.storychip-1 {
    grid-column: 6;
    grid-row: 9;
    justify-content: center;
    justify-self: center;
    align-self: start;
  }
  &.storychip-2 {
    grid-column: 11;
    grid-row: 4 / span 2;
    justify-content: end;
    justify-self: end;
    align-self: center;
  }

  ${getMediaQuery("m")} {
    &.storychip-0 {
      grid-column: 3;
      grid-row: 4;
      justify-content: start;
      align-self: start;
      justify-self: start;
    }
    &.storychip-1 {
      grid-column: 6;
      grid-row: 10;
      justify-content: center;
      justify-self: center;
      align-self: center;
    }
    &.storychip-2 {
      justify-content: end;
      justify-self: end;
      align-self: end;
      grid-area: 6 / 10;
    }
  }
`

const StoryChip = styled(motion.button)`
  ${buttonReset}
  ${buttonContrastMode}
  ${bold}
  height: 44px;
  overflow: hidden;
  border-radius: 44px;
  position: absolute;
  display: flex;
  border: none;
  align-items: center;
  pointer-events: inherit;
  user-select: none;
  cursor: pointer;
  background: rgba(33 33 33 / 0.2);
  color: white;
  backdrop-filter: blur(8px);

  transition: background 0.3s;

  @media (hover: hover) {
    &:hover {
      background: rgba(33 33 33 / 0.35);
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  transform: translate3d(-50%, 0, 0);

  & svg {
    padding-inline-start: calc(2.1875rem - 0.5rem);
  }
`

const StoryLabel = styled(motion.span)`
  white-space: nowrap;
  margin-inline-start: 0.5rem;
  padding-inline-end: 2.1875rem;
`

export default function StoryLauncher({ index, label, story, ...props }) {
  const console = useConsole()

  // TODO initiator, wasKeyDown
  const { currentInteraction } = useExp()
  const { storyOpener, storyGroups, initiator } = useStory()

  storyGroups.set(story[0].uid, story)

  function onClick() {
    initiator.set("xplit")
    storyOpener.set(`${story[0].uid}:${story[0].story_id}-0`)
    currentInteraction.set(`movement:${index}`)
  }

  const variants = {
    visible: i => ({ width: "auto", scale: 1, opacity: 1, transition: { delay: i * 0.2, duration: 0.5 } }),
    hidden: { width: "44px", scale: 0.8, opacity: 0 },
  }

  useLayoutEffect(() => {
    storyOpener.set("")
  }, [])

  return (
    <Chip className={`storychip-${index}`}>
      <StoryChip type='button' onClick={onClick} custom={index} variants={variants} layout initial='hidden' animate='visible' exit='hidden' aria-label={label}>
        <Icon type='plus' />
        <StoryLabel variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}>{label}</StoryLabel>
      </StoryChip>
    </Chip>
  )
}
