import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useScroll, motion, useReducedMotion, useMotionValueEvent, useTransform, transform, useMotionTemplate, useMotionValue } from "framer-motion"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { useConsole } from "contexts/Console"

import { fullGrid } from "css/grid"

import Video, { cssVideo, cssContainer, cssPoster, cssFallback } from "components/media/VideoCLD"
import Image from "components/media/ImageCLD"

import { useValue } from "./context"
import TextBlock from "./texts/TextBlock"
import Roller from "components/roller/Roller"
import { useViewport } from "contexts/Viewport"
import { getIndex, getPropHeight } from "./common"
import getMediaQuery from "css/breakpoints"

const Li = styled(motion.li)`
  position: relative;
  justify-content: center;

  height: 200vh;
  margin-block-start: -100vh;
  --height: 100;

  &.ashlar-roller {
    height: 300vh;
    --height: 200;
  }

  z-index: 0;

  &:first-of-type {
    height: 100vh;
    margin-block-start: 0;

    &.ashlar-roller {
      height: 200vh;
    }
  }
  &:nth-of-type(2) {
    margin-block-start: 0;
  }
  &:last-of-type {
    height: 100vh;

    &.ashlar-roller {
      height: 200vh;
    }
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-decoration: none;

  ${fullGrid}
  grid-template-rows: [top-row] 1fr 1fr [bottom-row];

  &:hover {
    ${({ cta }) => (cta ? "cursor: pointer" : "")};
  }
`

const RollerWrapper = styled.div`
    grid-column: doc;
    grid-row: top-row / bottom-row;
    ${fullGrid}
    grid-template-rows: 100vh 100vh;

    @media (hover: hover) {
        cursor: pointer;
    }
`

const containerStyle = css`
  height: 100%;
  width: 100%;
`

const MediaContainer = styled(motion.div)`
  height: 100vh;
  width: 100%;
  grid-column: doc;
  grid-row: top-row / bottom-row;
  overflow: hidden;
  position: sticky;
  top: 0;
  pointer-events: none;
  margin-block-start: -100vh;
`

const VideoContainer = styled(motion.div)`
  ${containerStyle}

  & .${cssContainer} {
    z-index: -1;
    height: 100%;

    & .${cssVideo}, .${cssPoster}, .${cssFallback} {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const ImageContainer = styled(motion.div)`
  ${containerStyle}

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
  grid-column: doc;
  grid-row: top-row / bottom-row;
  background: #212121;
  transform: translate3d(0, 0, 0);
  pointer-events: none;
`

const LizOver = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: rgba(255 0 0 / 0.5);
`

const RollerTextBlockWrapper = styled(motion.div)`
  grid-column: doc;
  grid-row: 1;
  z-index: 1;

  > div {
    margin-block-start: 0!important;
  }
`

const RollerListWrapper = styled(motion.div)`
  grid-column: doc;
  grid-row: 1/-1;
  display: grid;
  z-index: 0;
//  height: 100%;
  > section {
//    transform: translateY(-25vh);
    padding-block: 0 10vh;
    align-self: end;

    & > div {
      z-index: 0;
    }
  }

  & .previous {
    grid-column: main-start !important;
  }

  & .next {
    grid-column: -3 !important;
  }


  ${getMediaQuery("xxxl")} {
    & ul + div {
        justify-self: center;
        width: 88vw;

        & .previous {
          grid-column: doc-start !important;
        }

        & .next {
          grid-column: doc-end !important;
          margin-inline-end: 50px;
        }
    }
  }

`

function VideoItem({ video, prog, index }) {
  const console = useConsole()

  const shouldReduceMotion = useReducedMotion()

  const videRef = useRef()

  const { input, _input, inStopVid, _inStopVid } = useValue()

  const vidPlay = useTransform([prog, _input], ([p, i]) => p >= i[index] && p <= i[index + 1])

  function onVidToPlay(bool) {
    if (shouldReduceMotion) return
    if (bool) {
      videRef.current.play()
    } else {
      videRef.current.pause()
    }
  }
  useMotionValueEvent(vidPlay, "change", onVidToPlay)

  return (
    <VideoContainer>
      <Video ref={videRef} autoPlay={index === 0} noButton autoPause={false} {...video} sizes='100vw' />
      <Overlay initial={{ opacity: 1 }} animate={{ opacity: 0, transition: { delay: 0.5, duration: 0.9 } }} transition={{ type: "tween", duration: 1 }} />
    </VideoContainer>
  )
}

function ImageItem({ image, index }) {
  const console = useConsole()

  return (
    <ImageContainer>
      <Image {...image} sizes='100vw' />
      {index === 0 ? (
        <Overlay initial={{ opacity: 1 }} animate={{ opacity: 0, transition: { delay: 0.5, duration: 0.9 } }} transition={{ type: "tween", duration: 1 }} />
      ) : null}
    </ImageContainer>
  )
}

function AshlarContent({ heading, chapo, paragraph_blocks, media, cta, index, overlay }) {
  const console = useConsole()

  const { prog } = useValue()

  const isOverlay = overlay?.active && overlay?.color && overlay?.opacity > 0

  function onClick(e) {
    if (e.target.tagName === "BUTTON" || e.target.tagName === "A") return
    const btn = e.currentTarget.getElementsByTagName("button")
    const lnk = e.currentTarget.getElementsByTagName("a")
    const target = lnk[0] || btn[0]
    target?.click()
  }

  const insetInput = useMotionValue(100)

  const inset = useMotionTemplate`inset(${insetInput}vh 0 0 0)`

  const insetVal = useTransform(inset, ins => (index !== 0 && index !== 1 ? ins : "none"))

  return (
    <Wrapper className='dark-theme' onClick={onClick}>
      <MediaContainer style={{ clipPath: insetVal }}>
        {isOverlay ? <LizOver style={{ background: `rgba(${overlay.color} / ${overlay.opacity})` }} /> : null}
        {media[0]?.video_cld || media[1]?.video_cld ? (
          <VideoItem video={media[0]?.video_cld ?? media[1]?.video_cld} index={index} prog={prog} />
        ) : media[0]?.image_cld || media[1]?.image_cld ? (
          <ImageItem image={media[0]?.image_cld ?? media[1]?.image_cld} index={index} />
        ) : null}
      </MediaContainer>
      <TextBlock heading={heading} chapo={chapo} paragraph_blocks={paragraph_blocks} cta={cta} index={index} insetInput={insetInput} />
    </Wrapper>
  )
}

function RollerOverlay({ index, overlay, lprog }) {
  const ref = useRef(null)
  //  const darken_factor = 3
  const background = useTransform(lprog, p => {
    //    console.log("lprog", p)
    const op = (transform(p, [0, .5], [overlay.opacity, .85])).toFixed(2)
    return `rgba(${overlay.color} / ${op})`
  })

  const filter = useTransform(lprog, p => {
    //    console.log("lprog", p)
    const f = (transform(p, [0, .5], [0, 10]))
    return f.toFixed(1)
  })
  const backdropFilter = useMotionTemplate`blur(${filter}px)`
  const WebkitBackdropFilter = useMotionTemplate`blur(${filter}px)`

  return <LizOver ref={ref} style={{ background, backdropFilter, WebkitBackdropFilter }} />
}

function RollerTextBlock(props) {
  const { index, lprog, onClick } = props

  const opacity = useTransform(lprog, p => {
    return transform(p, [0, .1], [1, 0])
  })

  return (
    <RollerTextBlockWrapper style={{ opacity }} onClick={onClick}>
      <TextBlock {...props} />
    </RollerTextBlockWrapper>
  )
}

function RollerList(props) {
  const { roller, lprog } = props
  const opacity = useTransform(lprog, p => {
    return transform(p, [0, .1, .2], [0, 0, 1])
  })

  return (
    <RollerListWrapper style={{ opacity }}>
      <Roller {...roller} nav={roller?.texts?.[0].heading?.title} />
    </RollerListWrapper>
  )
}

function AshlarRollerContent(props) {
  const console = useConsole()
  const { heading, chapo, paragraph_blocks, media, cta, index, overlay, roller, lprog } = props
  const { _input, prog, y } = useValue()
  const ref = useRef(null)

  const isOverlay = overlay?.active && overlay?.color && overlay?.opacity > 0

  function onClick(e) {
    if (e.target.tagName === "BUTTON" || e.target.tagName === "A") return
    const btn = e.currentTarget.getElementsByTagName("button")
    const lnk = e.currentTarget.getElementsByTagName("a")
    const target = lnk[0] || btn[0]
    target?.click()
  }

  const insetInput = useMotionValue(100)

  const inset = useMotionTemplate`inset(${insetInput}vh 0 0 0)`

  const insetVal = useTransform(inset, ins => (index !== 0 && index !== 1 ? ins : "none"))

  return (
    <Wrapper className='dark-theme'>
      <MediaContainer style={{ clipPath: insetVal }}>
        {isOverlay ? <RollerOverlay index={index} overlay={overlay} lprog={lprog} /> : null}
        {media[0]?.video_cld || media[1]?.video_cld ? (
          <VideoItem video={media[0]?.video_cld ?? media[1]?.video_cld} index={index} prog={prog} />
        ) : media[0]?.image_cld || media[1]?.image_cld ? (
          <ImageItem image={media[0]?.image_cld ?? media[1]?.image_cld} index={index} />
        ) : null}
      </MediaContainer>
      <RollerWrapper>
        <RollerTextBlock {...props} onClick={onClick} />
        <RollerList roller={roller} lprog={lprog} />
      </RollerWrapper>
    </Wrapper>
  )
}

export function AshlarRoller(props) {
  return <Ashlar {...props} />
}

export default function Ashlar({ heading, chapo, paragraph_blocks, media, cta, uid, overlay, roller, ...props }) {
  const console = useConsole()

  const [index, setIndex] = useState(null)

  const { pagesCount, updatePageNum, removePage, updatePageIndex, num, _input, prog, y } = useValue()

  const ref = useRef()
  const lprog = useMotionValue(0)

  useLayoutEffect(() => {
    const index = getIndex(ref.current)
    updatePageNum(uid, getPropHeight(ref.current), index)
    setIndex(index)

    return () => removePage(uid)
  }, [removePage, uid, updatePageNum, updatePageIndex])

  function onNumChange() {
    const index = getIndex(ref.current)
    updatePageIndex(uid, index)
    setIndex(index)
  }
  useEffect(() => num.onChange(onNumChange))

  useLayoutEffect(function scrollEffects() {
    let scrollY = 0

    const update = e => {
      //      scrollY = global.scrollY
      //      console.log("scrollYscrollY", scrollY)
      onY(global.scrollY)
      // const delta = scrollY - expansion.scroll.y
      /*      let shouldUpdate = false
      
            if (!freezers.size) {
              if (scrollY <= clientHeight) {
                shouldUpdate = !expansion.t
                if (shouldUpdate) expansion.t = 1
              } else {
                const target = +!(expansion.scroll.y < scrollY)
                shouldUpdate = Math.abs(scrollY - expansion.scroll.y) > 1 && expansion.t !== target
                if (shouldUpdate) expansion.t = target
              }
      
              if (shouldUpdate) {
                const event = new Event(ctx.events.update)
                Object.assign(event, expansion)
                et.dispatchEvent(event)
              }
            }
      
            expansion.scroll.y = scrollY*/
    }

    //    global.addEventListener("resize", onresize, { passive: true })
    global.addEventListener("scroll", update, { passive: true })
    update()
    //    onresize()
    return () => {
      //      global.removeEventListener("resize", onresize, { passive: true })
      global.removeEventListener("scroll", update, { passive: true })
    }
  })

  function onY(v) {
    if (!process.browser || !ref.current) return
    const main = document.documentElement.querySelector("main")
    if (!main) return
    const offtop = ref.current.offsetTop + main.offsetTop
    const { height } = ref.current.getBoundingClientRect()
    lprog.set(Math.min(1, Math.max(0, (v - offtop) / height)))
  }

  return (
    <Li ref={ref} style={{ zIndex: index === 0 ? pagesCount - index : index }} id={`ashlar--${index}`} data-scroll-pos="start" className={roller ? "ashlar-roller" : ""}>
      {roller ?
        <AshlarRollerContent heading={heading} chapo={chapo} paragraph_blocks={paragraph_blocks} media={media} cta={cta} index={index} overlay={overlay} roller={roller[0]} lprog={lprog} /> :
        <AshlarContent heading={heading} chapo={chapo} paragraph_blocks={paragraph_blocks} media={media} cta={cta} index={index} overlay={overlay} />
      }
    </Li>
  )
}
