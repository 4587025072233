import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { animate, motion, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"

import { THE_WATCH, useExp } from "./expcontext"
import { getVideo, videoReady } from "./utils"

const Vid = styled(motion.video)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  display: block;
`

function Source({ src, type, width, quality, path, policy, ...rest }) {
  return <source {...rest} src={src} type={type} />
}

function VideoScreen({ sources }, handle) {
  const console = useConsole()
  const env = useEnv()

  const vidRef = useRef()
  const rafId = useRef()
  const progStart = useRef()
  const startTime = useRef()

  const saveTime = useRef()

  const { currentStep, videoProg, device } = useExp()

  let portrait_index = sources?.reduce((a, v, i) => {
    if (v?.metadata?.v7_type?.[0] === "portrait") a = i
    return a
  }, -1)
  if (portrait_index < 0) {
    // no metadata found
    portrait_index = sources?.reduce((a, v, i) => {
      if (v.width / v.height <= 1) a = i
      return a
    }, -1)
  }
  if (portrait_index < 0) {
    // no ratio found
    portrait_index = 0
  }
  const landscape_index = sources.length < 2 ? portrait_index : 1 - portrait_index

  const resizeWidth = width => (width > 2880 ? 2880 : width)
  const widthLandscape = resizeWidth(sources?.[landscape_index]?.width)
  const widthPortrait = resizeWidth(sources?.[portrait_index]?.width)

  const { src: srcP, poster: postP } = getVideo({ src: sources?.[portrait_index]?.public_id }, env)({ width: widthPortrait, quality: "auto:eco" })
  const { src: srcL, poster: postL } = getVideo({ src: sources?.[landscape_index]?.public_id }, env)({ width: widthLandscape, quality: "auto:eco" })

  const srcs = [
    { src: srcP, poster: postP },
    { src: srcL, poster: postL },
  ]

  const [srcIndex, setSrcIndex] = useState(device.get())

  function onDevice(d) {
    saveTime.current = vidRef.current?.currentTime || 0
    setSrcIndex(d)
  }
  useMotionValueEvent(device, "change", onDevice)

  useImperativeHandle(handle, () => ctx)

  function onend() {
    currentStep.set(THE_WATCH)
  }

  const ctx = {
    play,
    stop,
    rewind,
    ready,
    duration,
  }

  function play() {
    vidRef?.current?.play()
    progStart.current = Date.now()
    startTime.current = vidRef.current?.currentTime || 0
    updateProgressBar()
  }
  function stop() {
    vidRef?.current?.pause()
    cancelAnimationFrame(rafId.current)
  }
  function rewind() {
    vidRef.current.currentTime = 0
    videoProg.set(0)
    cancelAnimationFrame(rafId.current)
  }
  function duration(v) {
    vidRef.current.playbackRate = vidRef.current.duration / v
  }
  function ready(mv, ev) {
    return videoReady(vidRef.current, ev, mv)
  }

  function updateProgressBar() {
    if (!vidRef.current?.duration) return requestAnimationFrame(updateProgressBar)
    const elapsedTime = (Date.now() - progStart.current) / 1000
    // const currentTime = elapsedTime + startTime.current
    const currentTime = vidRef.current.currentTime

    if (currentTime <= vidRef.current.duration) {
      videoProg.set(currentTime / vidRef.current.duration)
      rafId.current = requestAnimationFrame(updateProgressBar)
    }
  }

  return (
    <Vid
      key={srcs[srcIndex].src} // force re-render the component on source change
      ref={vidRef}
      poster={srcs[srcIndex].poster}
      controls={false}
      controlsList='nofullscreen nodownload'
      disablePictureInPicture
      disableRemotePlayback
      autoPlay={false}
      muted={true}
      playsInline
      onEnded={onend}
      preload='auto'
    >
      {srcs[srcIndex] && <Source src={srcs[srcIndex].src} type='video/mp4' />}
    </Vid>
  )
}

export default forwardRef(VideoScreen)
