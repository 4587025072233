import { startTransition, useId, useLayoutEffect, useRef, useState } from "react"
import FocusTrap from "focus-trap-react"
import EventTarget from "@ungap/event-target"
import { motion, useAnimationControls, useMotionValue } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"
import { cssVarHeight as cssVarHeaderHeight } from "contexts/Header"
import { useHeader } from "contexts/Header"
import { ctx__ONLY_FOR_MENU__ as ctx } from "contexts/Menu"
import { usePanelModal } from "contexts/PanelModal"

import getMediaQuery from "css/breakpoints"

import useConstant from "hooks/useConstant"

import exposeEventTarget from "utils/exposeEventTarget"

import Languages from "components/menu/Languages"
import LinkSections from "components/menu/LinkSections"

const Content = styled.div`
  display: grid;
  grid-template-rows: calc(100% - 4rem) 4rem;
  height: 100%;
`

const Root = styled(motion.nav)`
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;

  padding-block-start: var(${cssVarHeaderHeight});
  height: calc(var(--vh100) - var(${cssVarHeaderHeight}));
  ${getMediaQuery("m")} {
    height: auto;
    max-height: 100vh;
  }

  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  transform: translateY(calc(var(--y) - var(${cssVarHeaderHeight})));

  & label {
    cursor: pointer;
  }

  background: linear-gradient(90deg, #0b3e27, #197149);
`

export const statesLabel = {
  closed: 0,
  opened: 1,
  rollergrid: 6,
  closegrid: 7,
}

export default function MenuPane(props) {
  const console = useConsole()

  const header = useHeader()
  const id = useId()
  const dictionary = useDictionary()
  const { panelModalState } = usePanelModal()

  const rroot = useRef()
  const et = useConstant(() => new EventTarget())

  const [active, setActive] = useState(0)

  const anim = useAnimationControls()
  const currentState = useMotionValue(statesLabel.closed)
  const langIsOpen = useMotionValue(false)

  Object.assign(ctx, {
    ...exposeEventTarget(et),
    events: {
      activity: "activity",
    },
    get active() {
      return active
    },
    get currentState() {
      return currentState
    },
    get langIsOpen() {
      return langIsOpen
    },
    activate: () =>
      startTransition(() => {
        header.lock(id, async () => {
          return new Promise(resolve => {
            currentState.set(statesLabel.closed)
            anim.start("closed").then(() => {
              setActive(0)
              panelModalState.set(false)
              resolve()
            })
          })
        })
        setActive(1)
        panelModalState.set(true)
        currentState.set(statesLabel.opened)
        anim.start("opened")
      }),
    switch: n =>
      startTransition(() => {
        currentState.set(n)
        setActive(n)
      }),
  })

  useLayoutEffect(() => {
    const event = new Event("activity")
    Object.assign(event, { active: active })
    et.dispatchEvent(event)
  }, [active, et])

  useLayoutEffect(() => {
    const elements = [document.querySelector("main"), document.querySelector("footer")]
    if (active) elements.forEach(node => node?.setAttribute("aria-hidden", "true"))
    else elements.forEach(node => node?.removeAttribute("aria-hidden"))
  })

  function close() {
    header.forceUnlock()
  }

  const variants = {
    closed: { "--y": 0, transition: { type: "tween", duration: 0.36, delay: 0.1 }, transitionEnd: { visibility: "hidden" } },
    opened: { "--y": "100%", visibility: "visible", transition: { type: "tween", duration: 0.46 } },
  }

  console.verbose("MenuPane(%o)", { ...props, active })
  return (
    <div aria-label={dictionary.watchesNavigation()} role='dialog' aria-modal='true'>
      <Root ref={rroot} animate={anim} variants={variants} initial='closed'>
        <FocusTrap
          active={!!active}
          containerElements={[header.ref.current].filter(Boolean)}
          focusTrapOptions={{ allowOutsideClick: true, onDeactivate: close }}
        >
          <Content>
            <LinkSections links_section={props.links_section} watches={props.watches} />
            <Languages title={props.language_title} />
          </Content>
        </FocusTrap>
      </Root>
    </div>
  )
}
