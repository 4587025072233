import { forwardRef } from "react"
import { motion, useTransform } from "framer-motion"
import styled from "@emotion/styled"

import { useDictionary } from "contexts/Dictionary"

import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"

import Button from "components/button/Button"

import { useRoller } from "./context"

const ArrowsContainer = styled(motion.div)`
  ${fullGrid}
  grid-column: doc;
  grid-row: roller;
  z-index: 1;
  pointer-events: none;
  height: fit-content;
  transform: translateY(-50%);

  .popin-edito & {
    width: 100%;
  }
`

const Marrow = styled(motion.div)`
  justify-self: center;
  grid-row: 1;

  &.previous {
    justify-self: end;
    grid-column: doc-start;
    margin-inline-end: calc(var(--btn-height) / -2 - var(--grid-gap));
  }
  &.next {
    justify-self: start;
    grid-column: main-end;
    margin-inline-start: calc(var(--btn-height) / -2 - var(--grid-gap));
  }

  ${getMediaQuery("m")} {
    &.previous {
      margin-inline-end: calc(var(--btn-height) / -2 - var(--grid-gap) / 2);
    }
    &.next {
      margin-inline-start: calc(var(--btn-height) / -2 - var(--grid-gap) / 2);
    }
  }

  ${getMediaQuery("l")} {
    &.previous,
    &.next {
      justify-self: center;
      margin-inline: unset;
    }
  }
`

const Arrow = styled(Button)`
  pointer-events: all;
  &:dir(rtl) svg {
    transform: scale(-1);
  }
`

function Arrows({ scrollX }, ref) {
  const dictionary = useDictionary()

  const { dir, unit, assetCenter, max } = useRoller()

  const prevVisi = useTransform([scrollX, dir, unit], ([x, d, u]) => (x * d < u ? "hidden" : "visible"))
  const nextVisi = useTransform([scrollX, dir, max], ([x, d, m]) => (x * d >= m ? "hidden" : "visible"))

  const scroll = v => {
    ref.current.scrollBy({
      top: 0,
      left: v * dir.get(),
      behavior: "smooth",
    })
  }

  const onPrevious = () => scroll(-unit.get())
  const onNext = () => scroll(unit.get())

  return (
    <ArrowsContainer style={{ marginTop: assetCenter }} className='dark-theme'>
      <Marrow className='previous' style={{ visibility: prevVisi }}>
        <Arrow aria-label={dictionary.previous()} aria_hidden={true} onClick={onPrevious} tabIndex={-1} className='icon translucent-dark' icon='movePrevious' />
      </Marrow>
      <Marrow className='next' style={{ visibility: nextVisi }}>
        <Arrow aria-label={dictionary.next()} aria_hidden={true} onClick={onNext} tabIndex={-1} className='icon translucent-dark' icon='moveNext' />
      </Marrow>
    </ArrowsContainer>
  )
}

export default forwardRef(Arrows)
