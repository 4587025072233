import { createContext, useContext, useLayoutEffect } from "react"
import { useMotionValue } from "framer-motion"

import { isMobileOnly } from "react-device-detect"

export const Context = createContext()

export const RollerProvider = ({ children }) => {
  const unit = useMotionValue(0)
  const assetCenter = useMotionValue(0)
  const max = useMotionValue(0)
  const isTouchDevice = isMobileOnly

  const dir = useMotionValue(1)

  useLayoutEffect(() => {
    const callback = mutationList => {
      for (const mutation of mutationList) {
        if (mutation.type === "attributes" && mutation.attributeName === "dir") {
          dir.set(document.documentElement.dir === "ltr" ? 1 : -1)
        }
      }
    }
    const observer = new MutationObserver(callback)
    observer.observe(document.documentElement, { attributes: true, childList: false, subtree: false })
    dir.set(document.documentElement.dir === "ltr" ? 1 : -1)

    return () => observer.disconnect()
  }, [])

  return <Context.Provider value={{ dir, unit, assetCenter, max, isTouchDevice }}>{children}</Context.Provider>
}

export const useRoller = () => useContext(Context)
