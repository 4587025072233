import { motion } from "framer-motion"

import { useConsole } from "contexts/Console"

import Link from "components/link/Link"
import Button from "components/button/Button"
import PopinButton from "components/popin-edito/PopinButton"
import XplitCTA from "components/xplit/XplitCTA"
import { useStory } from "components/stories/context"
import VideoLauncher from "components/video-launcher/VideoLauncher"

const ctas = {
  link: LinkCTA,
  story_button: StoryCTA,
  popin: PopinCTA,
  xplit_button: XplitCTA,
  video_player_cld: VideoAmpCTA,
}

function LinkCTA({ describedBy, ...link }) {
  const console = useConsole()
  return (
    <Link {...link} aria-describedby={describedBy}>
      {link.label}
    </Link>
  )
}

function StoryCTA({ story, describedBy }) {
  const console = useConsole()

  const { storyOpener, initiator, storyGroups } = useStory()

  // console.verbose("••••••• StoryCTA", story[0].launcher, describedBy)

  const { label, aria_label } = story[0].launcher.button
  const { uid, story_id } = story[0]

  storyGroups.set(uid, story)

  function onPress() {
    initiator.set("ashlar wall")
    storyOpener.set(`${uid}:${story_id}-0`)
  }

  return (
    <Button icon='plus' className='filled translucent-dark' onPress={onPress} aria_label={aria_label} aria-describedby={describedBy}>
      {label}
    </Button>
  )
}

function PopinCTA({ describedBy, ...popin_button }) {
  const console = useConsole()

  return <PopinButton {...popin_button} icon='plus' describedBy={describedBy} />
}

function VideoAmpCTA({ style, describedBy, ...props }) {
  const console = useConsole()

  return <VideoLauncher style={style} icon='play' video_player_cld={props} />
}

const transition = {
  type: "tween",
  duration: 0.8,
  ease: "easeOut",
}

const animA = {
  show: { opacity: 1, transition },
  hide: { opacity: 0, transition },
}

export default function CTA({ cta, describedBy }) {
  const console = useConsole()

  const CTAComp = ctas[cta[0]]

  return (
    <motion.div variants={animA}>
      <CTAComp {...cta[1]} describedBy={describedBy} />
    </motion.div>
  )
}
