import { useState } from "react"
import { createPortal } from "react-dom"
import { useMotionValueEvent, AnimatePresence } from "framer-motion"
import { useConsole } from "contexts/Console"
import { ExpXplitProvider } from "./expcontext"
import { useXplit } from "./context"
import SplitScreen from "./SplitScreen"
import { usePanelModal } from "contexts/PanelModal"

export default function LauncherScreen() {
  const console = useConsole()

  const { data, xp_query } = useXplit()
  const [loaded, setLoaded] = useState(!!data.get())
  const { panelModalState } = usePanelModal()

  function onData(d) {
    setLoaded(!!d)
    panelModalState.set(!!d)
  }
  useMotionValueEvent(data, "change", onData)

  return (
    process.browser &&
    createPortal(
      <AnimatePresence>
        {loaded ? (
          <ExpXplitProvider key={xp_query.get()} data={JSON.parse(data.get())}>
            <SplitScreen />
          </ExpXplitProvider>
        ) : null}
      </AnimatePresence>,
      document.body
    )
  )
}
