export const paths = {
  arrowRight: "M14.8,7.5L9,13.4L7.6,12l3.4-3.4H0.2v-2H11L7.7,3L9,1.6L14.8,7.5z",
  arrowLeft: "m9.48 15-7.91-7.5 7.91-7.5 1.95 1.95-5.83 5.55 5.83 5.55z",
  dropDown: "m11 3.2-5.5 5.7-5.5-5.7",
  call: "M4.96450996,4.97562686 L4.88426045,5.0023767 C5.41925721,6.90161521 6.51600057,9.09510193 8.65598762,11.0745899 L8.81648665,10.9408408 C9.64573163,10.2185951 10.8762242,10.0580961 11.57172,10.6198427 L12.7754627,11.6898362 C13.4442086,12.224833 13.4174588,13.1878272 12.6417135,13.9100728 C12.2137161,14.4183197 9.19098438,17.4945511 4.67026175,10.3255945 C-0.117959271,2.78214014 2.7442734,0.69565277 4.00151579,0.187405847 L4.00151579,0.187405847 C4.02826563,0.187405847 4.02826563,0.160656009 4.05501547,0.160656009 C4.08176531,0.13390617 4.13526498,0.13390617 4.16201482,0.13390617 C4.18876466,0.13390617 4.18876466,0.13390617 4.2155145,0.107156332 C4.40276336,0.0536566562 4.50976272,0.0269068181 4.50976272,0.0269068181 L4.50976272,0.0269068181 C5.3390077,-0.106842372 6.00775365,0.267655361 6.24850219,0.936401313 C6.22175235,0.963151151 7.34524555,4.36038059 4.96450996,4.97562686 L4.96450996,4.97562686 Z",
  getDirection: "M0,8.8L15,0L7.4,15l-2-5.1L0,8.8z",
  share:
    "m15 12.3c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7c0 0 0 0 0-.1l-5.1-2.6c-.5.4-1.1.6-1.8.6-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7c.7 0 1.3.3 1.8.7l5-2.5c0-.1 0-.2 0-.2 0-1.5 1.2-2.7 2.7-2.7 1.6-.1 2.8 1.1 2.8 2.6s-1.2 2.7-2.7 2.7c-.7 0-1.3-.3-1.8-.7l-5 2.5v.2.3l4.9 2.6c.5-.5 1.2-.8 1.9-.8 1.5 0 2.7 1.3 2.7 2.8z",
  facebook: "m11.5 0v2.25h-2.93c-.31 0-.57.32-.57.63v1.62h3.25l-.61 2.25h-2.64v8.25h-2.25v-8.25h-2.25v-2.25h2.25v-1.75c0-1.59 1.17-2.75 2.75-2.75z",
  instagram:
    "m10.86 0h-6.72c-2.28 0-4.14 1.86-4.14 4.14v6.72c0 2.28 1.86 4.14 4.14 4.14h6.72c2.28 0 4.14-1.86 4.14-4.14v-6.72c0-2.28-1.86-4.14-4.14-4.14zm0 13.67h-6.72c-1.55 0-2.81-1.26-2.81-2.81v-6.72c0-1.55 1.26-2.81 2.81-2.81h6.72c1.55 0 2.81 1.26 2.81 2.81v6.72c0 1.55-1.26 2.81-2.81 2.81zm-3.36-10.13c-2.13 0-3.87 1.73-3.87 3.87 0 2.13 1.73 3.86 3.87 3.86 2.13 0 3.87-1.73 3.87-3.86 0-2.14-1.74-3.87-3.87-3.87zm0 6.4c-1.4 0-2.53-1.14-2.53-2.53 0-1.4 1.14-2.53 2.53-2.53s2.53 1.14 2.53 2.53-1.13 2.53-2.53 2.53zm4.72-7.25c.18.18.29.43.29.69s-.1.51-.29.69c-.18.18-.43.29-.69.29s-.51-.1-.69-.29c-.18-.18-.29-.43-.29-.69s.1-.51.29-.69c.18-.18.43-.29.69-.29.25.01.51.11.69.29z",
  threads:
    "m7.6,15h0c-2.2,0-4-.8-5.1-2.2-1-1.3-1.6-3.1-1.6-5.3h0c0-2.2.5-4,1.6-5.3C3.6.8,5.3,0,7.6,0h0c1.7,0,3.2.5,4.3,1.3,1,.8,1.8,2,2.2,3.4l-1.3.4c-.7-2.5-2.4-3.7-5.2-3.8-1.8,0-3.2.6-4.1,1.7-.8,1-1.3,2.5-1.3,4.5,0,1.9.4,3.4,1.3,4.5.9,1.1,2.3,1.7,4.1,1.7,1.6,0,2.7-.4,3.6-1.3,1-1,1-2.2.7-3-.2-.4-.5-.8-1-1.1-.1.8-.4,1.5-.8,2-.6.7-1.3,1.1-2.3,1.1-.8,0-1.5-.1-2-.5-.7-.4-1.1-1.1-1.1-1.9,0-.7.3-1.4.8-1.9.6-.5,1.3-.8,2.2-.8.7,0,1.3,0,1.9,0,0-.5-.2-.8-.5-1.1-.3-.4-.8-.6-1.5-.6h0c-.5,0-1.2.1-1.7.8l-1.1-.7c.6-.9,1.6-1.4,2.8-1.4h0c2,0,3.2,1.2,3.3,3.4,0,0,.1,0,.2,0,.9.4,1.6,1.1,2,1.9.5,1.1.5,3-1,4.5-1.2,1.1-2.6,1.6-4.5,1.7h0ZM8.2,7.7c-.2,0-.3,0-.5,0-1.1,0-1.9.6-1.8,1.3,0,.8.9,1.1,1.7,1.1.8,0,1.8-.3,1.9-2.3-.4,0-.9-.1-1.4-.1Z",
  location:
    "M12,4.8C12,2.1,9.7,0,7,0C4.2,0,2,2.2,2,4.8s0,0,0,0C2,6,2.3,7.1,2.9,8.1C3.7,9.3,7.1,15,7.1,15s3.2-5.3,4.1-6.8C11.7,7.1,12,6.1,12,4.8C12,4.9,12,4.9,12,4.8L12,4.8z M9.9,4.8c0,1.6-1.4,2.8-2.9,2.8c-1.7,0-2.9-1.3-2.9-2.8S5.3,2,7,2S9.9,3.3,9.9,4.8z",
  externalLink:
    "M0.1,0.1v14.8h14.8V9h-2v3.7v0.2V13h-0.1h-0.2H2.3H2.1H2v-0.1v-0.2V2.4V2.2V2.1h0.1h0.2H6v-2H0.1z M8.7,0.1v2h2.1h0.5h0.3l-0.2,0.2L11,2.6L5.8,7.8l1.4,1.4L12.4,4l0.4-0.4l0.2-0.2v0.3v0.5v2.1h2V0.1H8.7z",
  phone:
    "M4.8,5L4.8,5c0.5,1.9,1.6,4.1,3.7,6.1l0.2-0.1c0.8-0.7,2.1-0.9,2.8-0.3l1.2,1.1c0.7,0.5,0.7,1.5-0.1,2.2c-0.4,0.5-3.4,3.6-8-3.6C-0.2,2.8,2.6,0.7,3.9,0.2c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0C4.3,0,4.4,0,4.4,0s0,0,0,0C5.2-0.1,5.9,0.3,6.1,1C6.1,1,7.2,4.4,4.8,5z",
  x: "M8.9,6.4l5.4-6.3H13L8.3,5.5L4.6,0.1H0.2l5.7,8.3l-5.7,6.6h1.3l5-5.8l4,5.8h4.3L8.9,6.4L8.9,6.4z M7.1,8.4L6.5,7.6L2,1h2l3.7,5.3l0.6,0.8L13,14h-2L7.1,8.4L7.1,8.4z",
  youtube:
    "m12.19 1.88h-9.38c-1.54 0-2.81 1.26-2.81 2.81v5.62c0 1.55 1.27 2.81 2.81 2.81h9.37c1.55 0 2.81-1.27 2.81-2.81v-5.62c.01-1.55-1.26-2.81-2.8-2.81zm-6.57 9.37v-7.5l4.69 3.75z",
  pinterest:
    "m13.5 5.34c0 3.18-2.04 5.75-4.87 5.75-.95 0-1.85-.49-2.15-1.06 0 0-.47 1.76-.59 2.2-.19.71-.65 1.57-1.02 2.18-.03.05-.06.11-.1.16-.02.03-.04.05-.05.08-.12.15-.25.29-.39.35 0 0-.16-.13-.25-.58 0-.03-.02-.07-.02-.1-.01-.09-.02-.19-.03-.3 0 0 0 0 0-.01-.06-.7-.08-1.58.07-2.24.17-.72 1.1-4.59 1.1-4.59s-.27-.56-.27-1.38c0-1.29.76-2.24 1.7-2.24.8 0 1.19.59 1.19 1.3 0 .79-.51 1.98-.78 3.08-.22.92.47 1.67 1.39 1.67 1.67 0 2.95-1.73 2.95-4.23 0-2.21-1.62-3.76-3.92-3.76-2.67 0-4.24 1.97-4.24 4.01 0 .79.31 1.65.7 2.11.07.09.08.17.06.26-.07.29-.23.92-.26 1.05-.04.17-.13.2-.31.12-1.18-.54-1.91-2.22-1.91-3.58 0-2.91 2.15-5.59 6.21-5.59 3.26 0 5.79 2.28 5.79 5.34z",
  linkedin:
    "m15 8.91v5.74h-3.1v-5.36c0-1.34-.47-2.26-1.6-2.26-.86 0-1.39.62-1.62 1.22-.07.21-.11.51-.11.81v5.59h-3.1s.04-9.05 0-10h3.1v1.41c0 .01.01.01 0 .01v-.01c.48-.68 1.17-1.66 2.83-1.66 2.05 0 3.6 1.43 3.6 4.51zm-13.32-8.55c-1.02 0-1.68.72-1.68 1.66 0 .93.64 1.67 1.64 1.67h.02c1.03 0 1.68-.74 1.68-1.67-.03-.94-.65-1.66-1.66-1.66zm-1.44 14.28h2.86v-10h-2.86z",
  youku:
    "m7.5 0c-4.14 0-7.5 3.36-7.5 7.5s3.36 7.5 7.5 7.5 7.5-3.36 7.5-7.5-3.36-7.5-7.5-7.5zm0 13.06c-3.07 0-5.56-2.5-5.56-5.56s2.5-5.56 5.56-5.56 5.56 2.5 5.56 5.56-2.49 5.56-5.56 5.56zm-1.94-9.43 4.84 3.87-4.84 3.87z",
  wechat:
    "m10.16 5.34c.84 0 1.63.15 2.35.41.04-.24.07-.49.07-.74 0-2.76-2.82-5.01-6.29-5.01s-6.29 2.25-6.29 5.02c0 1.43.75 2.72 1.96 3.63l-.02-.01s.43.28.31.86-.22.99-.14 1.11.29-.02 1.19-.57c.41-.28.77-.29 1-.26.33-2.5 2.83-4.44 5.86-4.44zm-1.79-2.74c.48 0 .87.37.87.83s-.39.83-.87.83-.87-.37-.87-.83.39-.83.87-.83zm-5.04.83c0-.46.39-.83.87-.83s.87.37.87.83-.39.83-.87.83-.87-.37-.87-.83zm11.67 6.97c0-2.27-2.17-4.11-4.84-4.11s-4.84 1.84-4.84 4.11 2.17 4.11 4.84 4.11c.47 0 .91-.06 1.34-.16l-.01.01s.01 0 .02-.01c.06-.01.12-.03.18-.05.18-.03.46-.02.79.21.69.45.86.56.92.47.06-.1-.02-.43-.11-.91-.1-.48.24-.7.24-.7l-.01.01c.9-.76 1.48-1.81 1.48-2.98zm-6.44-.63c-.37 0-.67-.3-.67-.68s.3-.68.67-.68.67.3.67.68-.3.68-.67.68zm3.21 0c-.37 0-.67-.3-.67-.68s.3-.68.67-.68.67.3.67.68-.3.68-.67.68z",
  weibo:
    "m11.07 7.59c-.25-.09-.33-.09-.25-.44.25-.62.25-1.24 0-1.59-.49-.71-1.8-.71-3.28 0 0 0-.49.27-.33-.18.25-.8.16-1.51-.16-1.86-.82-.89-2.95 0-4.84 2.04-1.39 1.5-2.21 3.09-2.21 4.51 0 2.66 3.11 4.43 6.15 4.43 4.02 0 6.64-2.75 6.64-4.7.08-1.24-.9-1.95-1.72-2.21zm-4.84 5.67c-2.46.27-4.51-.97-4.67-2.66-.17-1.77 1.64-3.37 4.1-3.63 2.46-.27 4.51.89 4.67 2.66s-1.64 3.36-4.1 3.63zm8.77-7.89c0-2.66-2.05-4.87-4.51-4.87-.33 0-.49.27-.49.53 0 .35.25.53.49.53 1.89 0 3.44 1.68 3.44 3.72 0 .35.25.53.49.53.42.18.58-.08.58-.44zm-2.05-.09c-.25-1.24-1.15-2.3-2.38-2.57-.24-.08-.57.18-.65.45s.16.62.41.71c.74.18 1.39.8 1.56 1.68.08.27.33.53.66.44.24-.08.48-.35.4-.71zm-7.95 3.46c-.98.18-1.64 1.06-1.48 1.95s1.07 1.42 2.05 1.24 1.64-1.06 1.48-1.95c-.16-.97-1.07-1.51-2.05-1.24z",
  qq: "m7.94.01c-.81-.07-1.36.51-1.43 1.29s.36 1.46 1.17 1.53 5.97.83 7.17 6.17c.05-.26.09-.52.11-.78.39-4.16-2.75-7.84-7.02-8.21zm-2.77 7.95c-.2.53-.23 1.04-.07 1.13.11.06.28-.08.44-.35.06.3.22.56.45.78-.24.09-.39.25-.39.44 0 .3.42.54.93.54.46 0 .85-.2.92-.46h.11c.07.26.45.46.92.46.51 0 .93-.24.93-.54 0-.18-.15-.34-.39-.44.22-.22.38-.49.44-.78.16.27.33.41.44.35.16-.09.13-.6-.07-1.13-.16-.41-.37-.72-.53-.78 0-.03 0-.05 0-.08 0-.16-.04-.31-.11-.43 0-.01 0-.02 0-.03 0-.07-.02-.14-.04-.2-.03-1.08-.65-1.94-1.65-1.94s-1.62.86-1.66 1.94c-.03.06-.04.12-.04.2v.03c-.07.12-.11.27-.11.43v.08c-.15.06-.36.37-.52.78zm8.53 3.54c.45-.67.19-1.44-.46-1.88s-1.45-.37-1.9.3-3.58 4.85-8.84 3.36c.2.17.41.32.63.47 3.47 2.32 8.21 1.31 10.57-2.25zm-12.98-.55c.34.74 1.12.93 1.84.61.71-.32 1.09-1.04.75-1.77-.34-.74-2.23-5.61 1.82-9.29-.25.08-.49.18-.74.29-3.81 1.71-5.45 6.27-3.67 10.16z",
  douban:
    "m15 1.25v1.25h-15v-1.25zm-4.38 11.25h4.38v1.25h-15v-1.25h4.37l-1.24-3.75h-.63-1.25v-1.25-2.5-1.25h1.25 10 1.25v5h-1.25-.63zm1.88-5v-2.5h-10v2.5zm-1.88 1.25h-6.24l1.25 3.75h3.75z",
  language:
    "M14.47,8.4c-.07-.72-.14-1-.65-.72s-1.44.61-1.91-1a2.54,2.54,0,0,1,.29-1.75c.15-.34.42-.91.84-.95s.77.25,1,0h0c-.13-.24-.26-.46-.4-.68l-.09-.06s-1.39-.42-1.42.22.21.74-.43.57-.18-.57,0-.61.07-.21,0-.3-.27-.22-.24-.39a2,2,0,0,1,.19-.42c0-.11.17-.32.11-.44s-.54-.41-.7,0-.45-.36-.54-.49h0A.64.64,0,0,1,10.5,1a.53.53,0,0,1,.17-.31A6.92,6.92,0,0,0,5.87.25C5.76.4,5.65.6,5.76.68A1.08,1.08,0,0,0,6,.83c.15.07.48.17.52.34s-.2.32-.82.44S6,2.76,6.22,2.76s.43-.82.6-.84h0a1.5,1.5,0,0,1,1,.51c.36.36.31,1.15-.1.93s-1.44.41-1.61.77a1.85,1.85,0,0,1-.35.48c-.14.13-.27.26-.4.4s0,.31,0,.44,0,.24-.11.29-.26-.13-.29-.24S4.5,5,4.07,5.31s0,1.37.36,2,1.49.51,2,.53a1.53,1.53,0,0,1,1.15.6c.17.27.7,0,.8.29s.34.65,1.15.79,1.06.7.51,1.37-1,1.21-1.18,1.42a2.14,2.14,0,0,1-.58.5,5.74,5.74,0,0,1-.66.43.82.82,0,0,0-.43.41,2.94,2.94,0,0,0-.13.67v.1a3.88,3.88,0,0,1-.65-2.88l-.07,0h0A2.88,2.88,0,0,1,5.17,10.4a2.7,2.7,0,0,1-.29-.62A1.31,1.31,0,0,1,5,8.87c0-.13.12-.26.18-.38s.26-.37,0-.45S4.9,8,4.75,8,4.24,7.53,4,7.32a5.32,5.32,0,0,0-.7-.64A2,2,0,0,1,2.5,5.42a5.4,5.4,0,0,1,.14-1.55,2.61,2.61,0,0,1,.48-1.32A5.48,5.48,0,0,1,3.68,2a1.43,1.43,0,0,0,.61-1,1.1,1.1,0,0,1,0-.18h0A7.54,7.54,0,0,0,.52,7.5,7.36,7.36,0,0,0,7.73,15a7.17,7.17,0,0,0,6.51-4.28.55.55,0,0,1,0-.18c0-.45.15-.9.19-1.35a3.48,3.48,0,0,0,0-.79Z",
  play: "M1,15V0L14,7.5,1,15Z",
  pause: "m3 2h3v11h-3zm6 0v11h3v-11z",
  less: "M13.5,8.5h-12v-2h12V8.5z",
  download:
    "m13 9.33333333v3.66666667h-.7333333-.7333334-8.06666663-1.46666667v-1.4666667-2.19999997h1.46666667v2.19999997h8.06666663v-2.19999997zm-6.96666667-.36666666 1.46666667 1.46666663 1.46666667-1.46666663 1.46666663-1.46666667h-2.19999997v-5.5h-.36666666-.73333334-.36666666v5.5h-2.2z",
  day: "M7.5,11.2c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8C11.3,9.5,9.6,11.2,7.5,11.2L7.5,11.2zM8.1,14.3v-1.6c-0.1-0.3-0.3-0.4-0.6-0.4c-0.3,0-0.5,0.2-0.6,0.4v1.6c-0.1,0.2,0,0.5,0.2,0.6c0.2,0.1,0.5,0.1,0.7,0S8.1,14.5,8.1,14.3z M8.1,2.2V0.6C8.1,0.3,7.8,0,7.5,0S6.9,0.3,6.9,0.6v1.6C7,2.5,7.2,2.6,7.5,2.6C7.7,2.6,8,2.5,8.1,2.2L8.1,2.2z M2.8,7.4c0-0.3-0.3-0.6-0.6-0.6H0.6C0.3,6.9,0,7.1,0,7.4C0,7.7,0.3,8,0.6,8h1.6C2.5,8,2.8,7.7,2.8,7.4z M15,7.4c0-0.3-0.3-0.6-0.6-0.6h-1.6c-0.2-0.1-0.5,0-0.6,0.2c-0.1,0.2-0.1,0.5,0,0.7C12.3,7.9,12.5,8,12.8,8h1.6C14.7,8,15,7.7,15,7.4L15,7.4z M4.2,4.1c0.2-0.2,0.2-0.6,0-0.8L3,2.2C2.8,2.1,2.5,2.1,2.3,2.3C2.1,2.5,2.1,2.7,2.2,3l1.2,1.1C3.6,4.3,3.9,4.3,4.2,4.1L4.2,4.1z M12.8,12.7c0.2-0.2,0.2-0.6,0-0.8l-1.2-1.1c-0.2-0.1-0.5-0.1-0.7,0.1c-0.2,0.2-0.2,0.5-0.1,0.7l1.2,1.1c0.1,0.1,0.2,0.2,0.4,0.2C12.6,12.9,12.7,12.8,12.8,12.7L12.8,12.7z M11.7,4.1L12.8,3c0.2-0.1,0.3-0.4,0.3-0.6c0-0.2-0.2-0.4-0.5-0.5c-0.2,0-0.5,0.1-0.6,0.3l-1.2,1.1c-0.1,0.2-0.2,0.4-0.1,0.6c0.1,0.2,0.3,0.3,0.5,0.3C11.4,4.3,11.6,4.2,11.7,4.1z M3,12.7l1.2-1.1c0.1-0.2,0.1-0.5-0.1-0.7c-0.2-0.2-0.5-0.2-0.7-0.1l-1.2,1.1c-0.1,0.2-0.2,0.4-0.1,0.6s0.3,0.3,0.5,0.4C2.8,12.9,2.9,12.8,3,12.7L3,12.7z",
  night: "M5.2,0C2.1,1.1,0.1,4,0,7.2c0,3.8,2.7,7,6.4,7.6s7.3-1.5,8.6-5.1c0-0.1-4.2,1.7-7.8-1.9S5.3,0,5.2,0z",
  whatsapp:
    "M11,9.1c0,0.2,0,0.4-0.1,0.5c-0.3,0.5-0.8,0.8-1.3,0.9s-0.9,0-1.4-0.2C7.8,10.1,7.4,9.9,7,9.7C6.3,9.2,5.6,8.6,5.1,7.9C5,7.7,4.8,7.5,4.7,7.2c-0.2-0.3-0.3-0.7-0.4-1C4.2,6.1,4.2,5.9,4.2,5.8c0-0.5,0.2-1,0.6-1.3C5,4.3,5.4,4.2,5.7,4.3C6,4.5,6.1,4.9,6.2,5.2C6.3,5.4,6.4,5.7,6.4,6c0,0.2-0.2,0.5-0.4,0.6C5.9,6.8,5.9,6.9,6,7c0.5,0.9,1.3,1.6,2.2,1.9C8.4,9,8.5,9,8.5,8.9c0.2-0.2,0.4-0.6,0.6-0.7c0.4-0.2,0.7,0,1.1,0.2C10.5,8.6,10.9,8.8,11,9.1z M14.4,7.5c0,3.8-3.1,6.9-6.9,6.9c-1.1,0-2.3-0.3-3.3-0.8l-3.7,0.9l1-3.7c-0.6-1-0.9-2.2-0.9-3.3c0-3.8,3.1-6.9,6.9-6.9S14.4,3.7,14.4,7.5zM13.1,7.4c0-3.1-2.5-5.6-5.6-5.6S1.9,4.3,1.9,7.4c0,1,0.3,2,0.8,2.9l0.1,0.2l-0.6,2.1l2.2-0.5l0.2,0.1c1,0.5,1.9,0.8,2.9,0.8C10.6,13,13.1,10.5,13.1,7.4z",
  wishlist:
    "M14.98,5.65c-.24,2.59-3.18,6.01-7.48,8.6C3.2,11.66,.26,8.24,.02,5.65-.22,3.06,1.75,.75,4.18,.75s3.32,1.76,3.32,1.76c0,0,.88-1.76,3.32-1.76s4.4,2.31,4.16,4.9Z",
  plus: "M13.5,8.5h-5v5h-2v-5h-5v-2h5v-5h2v5h5V8.5z",
  replay:
    "m15 7.5c0 3.9-3.1 7-7 7-2 0-3.8-.9-5.1-2.2l1.2-1.6c.9 1.1 2.3 1.8 3.9 1.8 2.8 0 5-2.2 5-5s-2.2-5-5-5c-2.6 0-4.7 2-4.9 4.5h.9l-1 1-1 1-1-1-1-1h1c0-.2 0-.4.1-.7 0-.2.1-.4.1-.6 0 0 0 0 0-.1 0-.2.1-.4.2-.5v-.1c.1-.2.1-.3.2-.5 0-.1 0-.1.1-.1.1-.2.2-.3.3-.4 0-.1 0-.2.1-.2.1-.1.2-.2.2-.3.1-.1.1-.1.2-.2 0-.2.1-.3.2-.4s.1-.2.2-.2c.1-.1.2-.2.2-.2.1-.1.2-.2.3-.2.1-.1.1-.2.2-.3.1 0 .2-.1.3-.2.1 0 .2-.1.3-.1.1-.1.2-.1.4-.2.1 0 .1-.1.2-.1 0-.1.2-.2.3-.3.1 0 .1-.1.2-.1.2 0 .3-.1.4-.1s.1-.1.2-.1.3-.1.4-.1.1 0 .2-.1c.2 0 .3-.1.5-.1h.2.7c4 0 7.1 3.1 7.1 7z",
  close: "m13.27 0-5.77 5.77-5.77-5.77-1.73 1.73 5.77 5.77-5.77 5.77 1.73 1.73 5.77-5.77 5.77 5.77 1.73-1.73-5.77-5.77 5.77-5.77z",
  arrowUp: "M12.1,11.6L7,6.2l-5.1,5.3L0,9.8l7-7.3l7,7.3L12.1,11.6z",
  chevron: "M11.7,7.5l-1.4,1.4l-5.7,5.7l-1.4-1.4l5.7-5.7L3.3,1.9l1.4-1.4l5.7,5.7l0,0L11.7,7.5z",
  // moveNext: "m5.52 0 7.91 7.5-7.91 7.5-1.95-1.95 5.83-5.55-5.83-5.55z",
  moveNext: "M3.5,13.1l5.8-5.6L3.5,1.9l2-1.9l7.9,7.5L5.5,15L3.5,13.1z",
  movePrevious: "M11.4,1.9L5.6,7.5l5.8,5.6L9.5,15L1.6,7.5L9.5,0L11.4,1.9z",
  heart:
    "m7.5 14.8-6.2-6.2c-1.7-1.7-1.7-4.5 0-6.2.8-.8 1.9-1.3 3.1-1.3 1.2 0 2.2.5 3.1 1.3v.1c.8-.8 1.9-1.3 3.1-1.3s2.2.5 3.1 1.3c1.7 1.7 1.7 4.5 0 6.2zm-3.1-11.6c-.6 0-1.2.2-1.6.7-.9.9-.9 2.4 0 3.3l4.7 4.8 4.7-4.8c.9-.9.9-2.4 0-3.3-.8-.9-2.4-.9-3.2 0l-1.5 1.5-1.5-1.5c-.4-.5-1-.7-1.6-.7z",
  heartFull:
    "m13.7 2c-.8-.8-1.9-1.3-3-1.3-1.1 0-2.2.4-3 1.3l-.2.1-.2-.1c-.8-.8-1.9-1.3-3-1.3-1.1 0-2.2.4-3 1.3-1.7 1.7-1.7 4.4 0 6.1l6.2 6.2 6.2-6.3c1.6-1.6 1.6-4.4 0-6",
  qrcode: "M2,5H0V2V0h2h3v2H2V5z M5,15v-2H2v-3H0v3v2h2H5z M15,15v-2v-3h-2v3h-3v2h3H15z M13,2v3h2V2V0h-2h-3v2H13z",
  info: "m6.08 8.59.85.36-.17.34c-.03.08-.11.21-.19.38-.1.19-.24.39-.41.59-.18.2-.4.37-.66.51-.27.15-.6.23-.97.23-.41 0-.74-.09-1-.27-.21-.14-.45-.44-.45-1 0-.21.04-.44.11-.68.06-.21.13-.42.19-.6l1.41-4.05h-1.33l.26-.93h3.2l-1.97 5.72c-.05.15-.1.3-.15.46-.03.13-.05.24-.05.35 0 .05.01.07.02.09.01 0 .02.01.05.01.08 0 .18-.04.28-.11.13-.1.26-.21.37-.34.12-.13.22-.26.3-.4.08-.15.13-.26.17-.34zm1.32-8.28c-.21-.2-.47-.31-.77-.31s-.56.11-.76.31-.31.48-.31.78.11.56.31.77.47.31.76.31.56-.11.77-.31c.2-.21.31-.48.31-.77 0-.3-.11-.57-.31-.78z",
  infoRound:
    "M7.5,15C3.4,15,0,11.6,0,7.5S3.4,0,7.5,0s7.5,3.4,7.5,7.5-3.4,7.5-7.5,7.5ZM7.5.6C3.7.6.6,3.7.6,7.5s3.1,6.9,6.9,6.9,6.9-3.1,6.9-6.9S11.3.6,7.5.6ZM7.5,6.4h0c.3,0,.5.2.5.5v3.2c0,.3-.2.5-.5.5h0c-.3,0-.5-.2-.5-.5v-3.2c0-.3.2-.5.5-.5ZM7.5,4.3h0c.3,0,.5.2.5.5h0c0,.3-.2.5-.5.5h0c-.3,0-.5-.2-.5-.5h0c0-.3.2-.5.5-.5Z",
  reset:
    "M11.4,12.1c-2.5,2.1-6.3,1.7-8.5-0.8l1.2-1c1.6,1.9,4.4,2.1,6.3,0.6c1.9-1.6,2.1-4.4,0.5-6.3S6.5,2.5,4.7,4L4.3,4.4l1.1,1.3h-4l0.6-4l1.3,1.5l0.4-0.3c2.5-2.1,6.3-1.8,8.5,0.8C14.3,6.1,13.9,9.9,11.4,12.1z",
  restart: "M15,12.5L8,8.1v4.4L1,8.1v4.4H0v-10h1v4.4l7-4.4v4.4l7-4.4V12.5z",
  check: "M5.4,11.9L1.6,7.5l1.5-1.3l2.5,2.9L12,3.7l1.3,1.5L5.4,11.9z",
  filters:
    "M10.4,10.7h3.6V9.2h-3.6V10.7z M10.4,12.5h1.4v-5h-1.4V12.5z M1.1,10.7h8.5V9.2H1.1V10.7z M5.4,5.8h8.6V4.3H5.4V5.8z M5.4,7.5h1.4v-5H5.4V7.5z M1.1,5.8h3.5V4.3H1.1V5.8z",
  crown:
    "M2.1,5.3c0.1,0,0.1,0,0.2,0L4.6,13h0c0.3,0.9,1.5,1.5,3,1.5c1.5,0,2.8-0.7,3-1.5h0l2.3-7.7c0.1,0,0.1,0,0.2,0c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8c0,0.3,0.2,0.6,0.5,0.7l-2.6,5.7l0.4-7.6c0,0,0,0,0,0c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.2,0.7,0.6,0.8l-1.8,7.4L7.7,2.4C8.1,2.4,8.4,2,8.4,1.6c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.3,0.7,0.7,0.8l-0.8,8.2L4.9,3.2c0.3-0.1,0.6-0.4,0.6-0.8c0-0.4-0.4-0.8-0.8-0.8C4.2,1.6,3.9,2,3.9,2.4c0,0.4,0.4,0.8,0.8,0.8c0,0,0,0,0,0l0.4,7.6L2.5,5.2C2.7,5,2.9,4.8,2.9,4.5c0-0.4-0.4-0.8-0.8-0.8C1.7,3.7,1.3,4,1.3,4.5C1.3,4.9,1.7,5.3,2.1,5.3z M7.6,12.3c1.4,0,2.5,0.4,2.5,0.8c0,0.4-1.1,0.8-2.5,0.8c-1.4,0-2.5-0.4-2.5-0.8C5.1,12.7,6.2,12.3,7.6,12.3z",
  list: "M1.5,6.5h12v2h-12V6.5z M1.5,2.5v2h12v-2H1.5z M1.5,12.5h6v-2h-6V12.5z",
  mail: "M0,1.5h15v0.1L7.6,7.3L0,1.5z M7.6,9.5L0,3.8v9.7h15V3.9L7.6,9.5z",
  soundOn:
    "m7.8 10.5-.8-.9c1.1-1.2 1.1-3 0-4.2l.8-.9c1.6 1.7 1.6 4.3 0 6zm-7.8-4.9v3.8h2.2l3.8 3.1v-10l-3.8 3.1zm12-5.1-1 .9c3.5 3.4 3.5 8.8 0 12.2l1 .9c4-3.9 4-10.1 0-14zm-2.1 2-.9.9c2.3 2.3 2.3 5.9 0 8.2l.9.9c2.8-2.8 2.8-7.2 0-10z",
  soundOff: "m11.8 7.5 2.1 2.1-.9.9-2.1-2.1-2.1 2.1-.9-.9 2.1-2.1-2.1-2.1.9-.9 2.1 2.1 2.1-2.1.9.9zm-11.8-1.9v3.8h2.2l3.8 3.1v-10l-3.8 3.1z",
  arrowBack: "M14.8,7.5L9,13.4L7.6,12L11,8.6H0.2v-2H11L7.7,3L9,1.6L14.8,7.5z",
  search:
    "m14.8 13.4-4-4c.7-1 1.1-2.1 1.1-3.4 0-3.2-2.6-5.8-5.8-5.8-3.3 0-5.9 2.6-5.9 5.8s2.6 5.8 5.8 5.8c1.3 0 2.4-.4 3.4-1.1l4 4zm-8.8-3.5c-2.1 0-3.9-1.7-3.9-3.9 0-2.1 1.8-3.9 3.9-3.9s3.9 1.8 3.9 3.9c0 2.2-1.7 3.9-3.9 3.9z",
  index: "M0,7h17v3H0v-3ZM0,2v3h17v-3H0ZM0,15h9v-3H0v3Z",
}

export const points = {
  direction: "0.5 7.5 7.5 7.5 7.5 14.5 8.27 14.5 14.5 0.5 0.5 6.73",
  backArrow: "14.7,6.6 3.5,6.6 7.1,3 5.9,1.8 0.1,7.5 5.9,13.2 7.1,12 3.5,8.4 14.7,8.4",
}
