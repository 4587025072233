import { forwardRef, useEffect, useLayoutEffect, useRef, useState, useMemo } from "react"
import { motion, useInView, useMotionValue, useScroll } from "framer-motion"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"
import { spacing } from "css/spacing"

import generateModularBlocks from "utils/generateModularBlocks"

import { LightBoxProvider } from "components/lightbox/context"
import ModalBoxRoller from "components/lightbox/ModalBoxRoller"

import Arrows from "./Arrows"
import { RollerProvider, useRoller } from "./context"
import { cssStartVar, startCol } from "./cssvars"
import TextBlock from "./TextBlock"

const startVar = css`
  ${cssStartVar}: 0;

  &.${startCol["first-col"]} {
    ${cssStartVar}: 0;
  }

  ${getMediaQuery("m")} {
    &.${startCol["third-col"]} {
      ${cssStartVar}: 1;
    }
    &.${startCol["fourth-col"]} {
      ${cssStartVar}: 2;
    }
  }

  ${getMediaQuery("l")} {
    &.${startCol["second-col"]} {
      ${cssStartVar}: 1;
    }
    &.${startCol["third-col"]} {
      ${cssStartVar}: 2;
    }

    &.${startCol["fourth-col"]} {
      ${cssStartVar}: 3;
    }
  }
`

const Container = styled(motion.section)`
  ${startVar};
  --dir: 1;
  &:dir(rtl) {
    --dir: -1;
  }

  ${fullGrid}

  grid-template-rows: [texts] auto [roller] auto;

  overflow: hidden;
  padding-block: 10vh;

  ${spacing}
  h2,
  h3,
  p {
    html.prefers-contrast & {
      color: black !important;
      position: relative;

      ::after {
        background: white;
        content: "";
        height: calc(100% + 30px);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 20px);
        z-index: -1;
      }
    }
  }
`

const Nav = styled.nav`
  grid-column: doc;
  grid-row: roller;
  ${fullGrid}
`

const List = styled.ul`
  grid-column: doc;
  grid-row: roller;
  display: grid;
  grid-auto-flow: column;
  gap: var(--grid-gap);

  overflow-x: auto;
  overflow-y: hidden;

  padding-inline-start: calc(var(--outer-margin) + var(--grid-gap) * var(${cssStartVar}) + var(--grid-col-unit) * var(${cssStartVar}));

  & li:last-of-type {
    padding-inline-end: var(--outer-margin);
  }

  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.s-col-roller {
    --col-nb: 5;
    ${getMediaQuery("l")} {
      --col-nb: 3;
    }
  }
  &.m-col-roller {
    --col-nb: 6;
    ${getMediaQuery("l")} {
      --col-nb: 4;
    }
  }
  &.l-col-roller {
    --col-nb: 6;
    ${getMediaQuery("m")} {
      --col-nb: 7;
    }
    ${getMediaQuery("l")} {
      --col-nb: 5;
    }
  }
  &.xl-col-roller {
    --col-nb: 6;
    ${getMediaQuery("m")} {
      --col-nb: 8;
    }
    ${getMediaQuery("l")} {
      --col-nb: 6;
    }
  }

  grid-auto-columns: calc(var(--grid-col-unit) * var(--col-nb) + var(--grid-gap) * (var(--col-nb) - 1));

  & figure img {
    width: 100%;
    height: auto;
  }

  & li {
    scroll-snap-align: start;
    scroll-margin-inline-start: calc(var(--outer-margin) + var(--grid-gap) * var(${cssStartVar}) + var(--grid-col-unit) * var(${cssStartVar}));
  }

  // to prevent cards focus outline to be cut :
  padding-block-start: 10px;
  margin-block-start: -10px;
  padding-block-end: 10px;
  margin-block-end: -10px;
  //////////////////////////////////////////////
`

function Strip({ className, items, labelledBy }, ref) {
  const console = useConsole()

  const modularBlocks = useMemo(() => generateModularBlocks(items, { console }), [])

  const [showArrows, setShowArrows] = useState(false)

  const isInView = useInView(ref)

  const { max, unit, isTouchDevice, assetCenter } = useRoller()
  const { scrollX } = useScroll({ container: ref })

  function updateUnit() {
    max.set(ref.current.scrollWidth - ref.current.clientWidth - unit.get() / 2)
    setShowArrows(max.get() > 0)
  }

  const onWchange = () => updateUnit()
  useEffect(() => unit.onChange(onWchange))

  useLayoutEffect(() => {
    const r = ref.current

    if (r.children.length) {
      unit.set(r.children[0]?.offsetWidth)
      assetCenter.set(r.children[0].getElementsByTagName("figure")[0].children[0].offsetHeight / 2)
    }

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.contentBoxSize && r.children.length) {
          unit.set(r.children[0].offsetWidth)
          assetCenter.set(r.children[0].getElementsByTagName("figure")[0].children[0].offsetHeight / 2)
        }
      }
    })

    resizeObserver.observe(r)

    return () => resizeObserver.unobserve(r)
  }, [])

  useLayoutEffect(() => {
    updateUnit()
  }, [])

  useEffect(() => {
    !isInView &&
      ref.current.scrollTo({
        top: 0,
        left: 0,
      })
  }, [isInView])

  return (
    <>
      <List ref={ref} className={className} aria-labelledby={labelledBy}>
        {modularBlocks}
      </List>
      {showArrows && !isTouchDevice ? <Arrows ref={ref} scrollX={scrollX} /> : null}
    </>
  )
}

const StripWithRef = forwardRef(Strip)

function RollerContent({ column_width, items, className, texts, title_gradient, chapo_gradient, background, start_column, text_layout, inview, nav, ...props }) {
  const console = useConsole()
  const imageAlts = items.map(item => item.image_card?.alt).filter(Boolean)
  const ref = useRef()

  const isInView = useInView(ref, { once: true })

  const bgcol = !!background ? background.colors.map(({ color, opacity }) => `rgb(${color} / ${opacity})`) : []
  const backgroundColor = useMotionValue(bgcol.length === 1 ? bgcol[0] : bgcol.length > 1 ? `linear-gradient(${bgcol})` : null)
  const startClass = start_column ? ` ${startCol[start_column]}` : ` ${startCol["first-col"]}`

  const labelledBy = texts?.length ? texts.find(o => "heading" in o)?.heading?._metadata.uid : null

  return (
    <>
      <RollerProvider>
        <Container className={`${className}${startClass}${isInView ? " show" : ""}`} style={{ background: backgroundColor }}>
          {texts?.length ? <TextBlock texts={texts} title_gradient={title_gradient} chapo_gradient={chapo_gradient} text_layout={text_layout} inview={inview} /> : null}
          {nav ? (
            <Nav aria-label={nav}>
              <StripWithRef ref={ref} className={column_width} items={items} labelledBy={labelledBy} />
            </Nav>
          ) : (
            <StripWithRef ref={ref} className={column_width} items={items} labelledBy={labelledBy} />
          )}
        </Container>
      </RollerProvider>
      <ModalBoxRoller texts={texts} {...props} imageAlts={imageAlts} />
    </>
  )
}

export default function Roller({ ...props }) {
  const console = useConsole()
  console.log("RollerRoller", props)

  return (
    <LightBoxProvider>
      <RollerContent {...props} />
    </LightBoxProvider>
  )
}
