import { motion } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"

import { isDarkCN, isInnerCN, horizontalAlign, entries } from "components/figcaption/FigCaption"

const Caption = styled(motion.figcaption)`
  z-index: 1;
  color: rgb(var(--light-black));

  grid-column: 1;
  grid-row: 1;

  display: grid;
  /* position: absolute; */

  &.${isDarkCN} {
    color: white;
  }

  align-self: center;
  justify-self: center;
`

const Div = styled.div`
  width: 50%;
  height: fit-content;
  align-self: end;
  margin-block-end: 20px;
  
  .${horizontalAlign.start} & {
    text-align: start;
    justify-self: start;
    margin-inline-start: 50px;
  }
  .${horizontalAlign.center} & {
    text-align: center;
    justify-self: center;
  }
  .${horizontalAlign.end} & {
    text-align: end;
    justify-self: end;
    margin-inline-end: 50px;
  }

  ${getMediaQuery("s")} {
    margin-block-end: calc(2.5rem + 20px);
  }
`

export default function FigCaption({ caption, is_inner, is_dark_theme, horizontal_position, style }) {
  const console = useConsole()

  const childs = caption.map((props, i) => {
    const key = Object.keys(props)[0]
    const Entry = entries[key]
    return <Entry key={`${key}-${i}`} {...Object.values(props)[0]} />
  })

  const classCSS = `${horizontalAlign[horizontal_position]}${is_inner ? ` ${isInnerCN}` : ""}${is_dark_theme ? ` ${isDarkCN}` : ""}`

  console.verbose("\n•••••• FigCaption", is_dark_theme, is_inner, caption, horizontal_position)
  return (
    <Caption className={classCSS} style={style}>
      <Div>{childs}</Div>
    </Caption>
  )
}
