"use client"

import { useUniformContext } from "@uniformdev/context-react"

import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"
import { useNavigation } from "contexts/Navigation"
import { Provider } from "contexts/Page"
import { useSuspender } from "contexts/Suspender"
import { useUser } from "contexts/User"
import { useEnv } from "contexts/Env"
import { updateSearchList } from "components/search/utils"

import { handleVisitor } from "utils/handleVisitor"
import generateComponents, { parseModularBlock } from "utils/generateComponents"

import { SearchProvider } from "../search/context"
import { pushToDataLayer, storeCustomEvent } from "components/analytics/DigitalDataLayer"
import Meta from "components/page/Meta"
import { getPriceDatas, getMultiPriceData } from "components/price/utils"

export const suspenders = {}

//TODO make sure it's enough and doesn't loop forever :)
const feedMainComponentWithLayout = (components, props, { console }) => {
  let main = components
  while (true) {
    const keys = Object.keys(main).filter(key => key !== "_metadata")

    if (keys.length === 0) {
      return null
    }

    if (keys.length === 1 || (keys.length > 1 && !main._content_type_uid)) {
      main = main[keys[0]]
      continue
    }

    if (main._content_type_uid) {
      Object.assign(main, { ...props, controlsLayout: true })
      break
    }
  }

  console.verbose("Page():feedMainComponentWithLayout(%o)", { main, props })
}

export default function Page({ children, header, footer, uid, nofooter, initialState, ...__unsafe_page_props__ }) {
  /** page props may not be available if json takes more than 300ms to load, only access props through the later useSuspender return value  */
  const locale = useLocale()
  const console = useConsole()
  const navigation = useNavigation()
  const ufContext = useUniformContext()
  const env = useEnv()
  const { countryCode } = useUser()

  const [page] = useSuspender(
    ["page", locale.current.codes.www, uid],
    () => {
      if (Array.isArray(__unsafe_page_props__.components)) return { uid, ...__unsafe_page_props__ }
      return navigation.model(new URL(global.location.pathname.replace(new RegExp(".html$"), ""), global.location.origin))
    },
    true
  )

  const csrAttrs = page.content_in_english ? { lang: "en", dir: "ltr" } : {}
  const rmc = page.page_type === "model page" ? navigation.slug.split("/").pop() : null

  const { components, controlslayout, preload, ...ctx } = page
  const subNav = ctx?.sub_navigation_p13n?.at?.(0) || ctx?.sub_navigation?.at?.(0) || null

  // Adding share in ctx to make it more accessible outside footer
  ctx.share = footer.variations?.[0]?.footer?.component?.[0]?.share

  // Adding slug in footer to make it accessible to breadcrumb
  footer = Object.assign({}, footer, { slugForBreadcrumb: navigation.slug }, { breadcrumb: ctx?.breadcrumb ?? [] })

  header = Object.assign({}, header, { children: subNav, nofooter, page_type: page.page_type, page_slug: navigation.slug })
  if (controlslayout) feedMainComponentWithLayout(components, { header, footer, nofooter, sub_navigation: ctx?.sub_navigation?.at?.(0) }, { console })

  const hasCallback = !!["Model", "Retailer", "StoreLoc", "Watches", "Wishlist"].filter(component => (preload ?? []).includes(component)).length
  let pageviewCallback

  async function getModelData(productID) {
    return Promise.resolve(
      new URL(`/api/catalog/watches/${productID}?language=${locale.current.codes.www}`, process.browser ? env.services : process.env.SERVICE_CATALOG_URL)
    )
      .then(url => new Request(url))
      .then(request => fetch(request))
      .then(response => response.json())
      .catch(err => err)
  }
  async function getMultiModelData(rmcList) {
    return Promise.resolve(new URL("/api/catalog/watches", process.browser ? env.services : process.env.SERVICE_CATALOG_URL))
      .then(url => {
        url.searchParams.append("language", locale.current.codes.www)
        url.searchParams.append("rmc", `(${rmcList})`)
        return new Request(url)
      })
      .then(request => fetch(request))
      .then(response => response.json())
      .catch(err => err)
  }

  async function pageview(nopageview) {
    if (process.browser) {
      const { href } = global.location

      //make sure we only fire one time per page?
      if (global.digitalDataLayerLastPageViewURL === href) return
      const from = global.digitalDataLayerLastPageViewURL || href
      global.digitalDataLayerLastPageViewURL = href

      const pathNameToArray = navigation.slug.replace(/^\//, "").split("/")

      const initialPageName = navigation.homepage ? "home" : navigation.slug.replace(/\//g, ":").replace(/^:/, "")

      const getPageName = () => {
        if (initialPageName === "404") return "error page"
        return initialPageName
      }
      const pageName = getPageName()

      const getPageType = () => {
        if (initialPageName === "404") return "error page"
        return ctx?.page_type
      }
      const pageType = getPageType()
      global.digitalDataLayerPageType = pageType
      //      if (pageType === "wishlist page") return

      const getPageSiteSection = () => {
        if (initialPageName === "404") return "error page"
        return pathNameToArray[0] ? pathNameToArray[0] : navigation.homepage ? "homepage" : ""
      }
      const pageSiteSection = getPageSiteSection()

      const getPageSiteSubsection = () => {
        if (initialPageName === "404") return ""
        return pathNameToArray[1] ? pathNameToArray[1] : ""
      }
      const pageSiteSubsection = getPageSiteSubsection()

      try {
        ufContext.context.update({
          url: new URL(href),
          cookies: (document.cookie ?? "")
            .split(";")
            .map(part => part.trim())
            .reduce((acc, part) => {
              const delimiterIndex = part.indexOf("=")
              const key = part.slice(0, delimiterIndex)
              const value = part.slice(delimiterIndex, -1)
              acc[key] = value
              return acc
            }, {}),
        })
      } catch (err) {
        console.error(err)
      }

      /** error page (/404) has specific behavior that impact the previousURL */
      let firstPreviousUrl = ""
      let currentFrom
      try {
        firstPreviousUrl = pageType === "error page" && global.__catch_for ? new URL(global.__catch_for, global.location.origin).href : ""
        currentFrom = pageType === "error page" && global.__catch_for ? global.__catch_for : from
      } catch { }

      const data = {
        page: {
          pageName,
          pageURL: href,
          pageSiteSection,
          pageSiteSubsection,
          pageType,
          pagePreviousURL: global.digitalDataLayer.length === 0 ? firstPreviousUrl : currentFrom,
        },
      }

      if (pageType === "family page") {
        const slug = navigation.slug
        handleVisitor({ type: "family", familySlug: slug })
      }

      if (pageType === "search results page" && global.location.search) {
        updateSearchList()
      }
      if (pageName.includes("rolex-family")) {
        const pageNameLength = pageName.split(":").length
        console.log("🚀 ~ pageview ~ pageNameLength:", pageNameLength)
        if (pageNameLength < 3) {
          const slug = navigation.slug
          data.page = {
            customEvents: ["rolexFamilyPageViews"],
            ...data.page,
          }

          data.rolexFamilyInfo = {
            ctaName: slug.split("/").pop() === "rolex-family" ? "all" : slug.split("/").pop(),
          }
        }
      }
      if (pageType === "model page") {
        const productID = new RegExp("[^:]+$").exec(pageName)[0] //get rmc from pageName
        const modelData = await getModelData(productID)
        const { price, currency } = await getPriceDatas({ rmc, countryCode, locale, env })
        const isNewModel = modelData?.newmodelselection

        data.page.pageType = isNewModel ? `new ${pageType}` : pageType

        data.products = [
          {
            productCategory: modelData.familyNode.code,
            productModel: modelData.nameCode,
            productID: productID,
            productQuantity: "1",
            productPrice: price,
            currency: currency,
          },
        ]
        const ymalInfo = localStorage?.getItem("ymalInfo")
        if (ymalInfo) {
          data.ymalInfo = JSON.parse(ymalInfo)
          localStorage.removeItem("ymalInfo")
        }
      }

      if (pageType === "store locator page" || pageType === "service locator page") {
        const retailerInfo = localStorage?.getItem("retailerInfo")

        if (retailerInfo) {
          data.retailerInfo = JSON.parse(retailerInfo)
          localStorage.removeItem("retailerInfo")
        }
      }

      if (pageType === "wishlist page") {
        const products = localStorage?.getItem("wishlistProducts")
        if (products) {
          const dataproducts = JSON.parse(products)
          const rmcs = JSON.parse(products).reduce((acc, { productID }) => acc.concat([productID]), [])
          const prices = await getMultiPriceData({ rmcs, countryCode, locale, env })
          const modelsData = await getMultiModelData(rmcs.join(","))
          const currency = prices.popin.currency

          data.products = dataproducts.map(prod => {
            const priceData = prices.priceList.find(p => p.rmc === prod.productID)
            const wData = modelsData?.results?.find(p => p.rmc === prod.productID)
            return {
              productCategory: wData.familyCode,
              productModel: wData.titleCode,
              productID: prod.productID,
              productQuantity: "1",
              productPrice: priceData.price,
              currency: currency,
            }
          })

          localStorage.removeItem("wishlistProducts")
        }
        storeCustomEvent({ eventName: "wishlistViews" })
      }

      if (sessionStorage.getItem("__ddlAddOn")) {
        try {
          const [customEvent, addOn] = JSON.parse(sessionStorage.getItem("__ddlAddOn"))
          data.page.customEvents ??= []
          data.page.customEvents.push(customEvent)
          Object.assign(data, addOn)
        } finally {
          sessionStorage.removeItem("__ddlAddOn")
        }
      }

      if (!nopageview) {
        pushToDataLayer(data)
      }
    }
  }

  if (!hasCallback) pageview()
  else
    pageviewCallback = nopageview => {
      pageviewCallback = null
      pageview(nopageview)
    }

  console.verbose("Page(%o) => %o", ctx, { header, footer, navigation, components, controlslayout, ufContext })

  return (
    <Provider value={ctx}>
      <SearchProvider>
        <Meta initialState={initialState} ctx={ctx} />
        {controlslayout ? (
          generateComponents(parseModularBlock(components, { console }), { console, pageviewCallback })
        ) : (
          <>
            {generateComponents(header, { console })}
            <main {...csrAttrs}>{generateComponents(parseModularBlock(components, { console }), { console, pageviewCallback })}</main>
            {!nofooter && generateComponents(footer, { console })}
          </>
        )}
      </SearchProvider>
    </Provider>
  )
}
