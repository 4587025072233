import { useEffect, useLayoutEffect, useRef } from "react"
import styled from "@emotion/styled"
import { motion, useMotionTemplate, useMotionValue, useMotionValueEvent, useTransform } from "framer-motion"

import { useViewport } from "contexts/Viewport"

import { THE_END, THE_INTRODUCTION, useExp } from "./expcontext"
import { useConsole } from "contexts/Console"
import { HIDDEN_PHASE, useSwitcher } from "./switchcontext"

const Can = styled(motion.canvas)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  inset: 0;
  z-index: var(--canvindex);
`

const FORMATS = [
  { w: 800, h: 1420 },
  { w: 1920, h: 1080 },
]

export default function Canvas() {
  const console = useConsole()
  const { orientation, isMobile } = useViewport()

  const ref = useRef()
  const { canCtx, revealProg, frameToDraw, targetChapter, currentStep, device } = useExp()
  const { resetPhase } = useSwitcher()

  const inset = useMotionTemplate`inset(0 0 0 ${revealProg}px)`
  const display = useMotionValue(getDisplay(currentStep.get()))

  function updateSize({ w, h }) {
    ref.current.width = w
    ref.current.height = h
  }

  useLayoutEffect(() => {
    canCtx.current = ref.current.getContext("2d")
    updateSize(FORMATS[device.get()])
  }, [])

  function onFrameToDraw(img) {
    canCtx.current.clearRect(0, 0, FORMATS[device.get()].w, FORMATS[device.get()].h)
    canCtx.current.drawImage(img, img.getAttribute("x"), img.getAttribute("y"), img.width, img.height)
  }
  useMotionValueEvent(frameToDraw, "change", onFrameToDraw)

  function onOrientChange(o) {
    // ref.current.width = sources.get(o).width
    // ref.current.height = sources.get(o).height
  }
  useEffect(() => orientation.onChange(onOrientChange))

  function getDisplay(s) {
    const hidden = s === THE_INTRODUCTION || s >= THE_END
    return hidden ? "none" : "block"
  }

  function onOnStepChange(s) {
    display.set(getDisplay(s))
  }
  useMotionValueEvent(currentStep, "change", onOnStepChange)

  function onPhaseChange(p) {
    if (p === HIDDEN_PHASE) {
      if (targetChapter.get() === THE_INTRODUCTION || targetChapter.get() === THE_END) {
        display.set("none")
      }
    }
  }
  useMotionValueEvent(resetPhase, "change", onPhaseChange)

  function onDevice(d) {
    updateSize(FORMATS[d])
  }
  useMotionValueEvent(device, "change", onDevice)

  return <Can ref={ref} style={{ clipPath: inset, display }} />
}
