import { useCallback, useRef } from "react"
import styled from "@emotion/styled"
import useSSR from "hooks/useSSR"
import { useButton } from "@react-aria/button"
import { VisuallyHidden } from "@react-aria/visually-hidden"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"
import { useEnv } from "contexts/Env"
import { useNavigation } from "contexts/Navigation"
import { useLocale } from "contexts/Locale"
import { useUser } from "contexts/User"
import { bold, legend100 } from "css/text"

import { waTriggerEvent } from "components/analytics/DigitalDataLayer"
import { Icon } from "components/icon/Icon"

import { getPriceDatas } from "components/price/utils"

const Root = styled.div`
  position: relative;
  padding-block: 20px;
  padding-inline: var(--outer-margin);
  overflow-x: auto;
  scrollbar-width: none;
  white-space: nowrap;
  background: ${({ inModal }) => (inModal ? "none" : "white")};

  ${({ inModal }) => inModal && "align-items: center; display: flex; flex-direction: column;"}

  &::-webkit-scrollbar {
    display: none;
  }

  a,
  button,
  span {
    all: unset;
    ${legend100}
    ${bold}
    align-items: center;
    display: inline-flex;
    gap: ${({ inModal }) => (inModal ? "2ex" : "1ex")};
    margin-inline-start: -0.5rem;
    min-height: 2.25rem;
    padding: 0.5rem;
    transition: color 0.3s;
    user-select: none;

    svg {
      height: ${({ inModal }) => (inModal ? "1.25rem" : "1rem")};
      width: ${({ inModal }) => (inModal ? "1.25rem" : "1rem")};
    }
  }

  ul {
    display: inline-flex;
    gap: 0.5rem;
    margin-inline-start: 0.5rem;

    a {
      all: unset;
      color: ${({ inModal }) => (inModal ? "white" : "rgb(var(--green))")};
      cursor: pointer;
      line-height: 0;
      padding: 0.5rem;
      vertical-align: middle;

      @media (hover: hover) {
        :hover {
          color: ${({ inModal }) => (inModal ? "rgb(var(--green))" : "inherit")};
        }
      }
    }
  }

  svg {
    fill: currentColor;
    transition: color 0.3s;
  }
`

// Component for each share item
function Item({ label, text, url: share_url, wa = {} }) {
  const { position = "pre-footer", sharetype = null } = wa
  const env = useEnv()
  const locale = useLocale()
  const { countryCode } = useUser()
  const dictionary = useDictionary()
  const currentUrl = process.browser && (share_url || window?.location.href)
  const title = process.browser && encodeURIComponent(document.title)

  // Generate aria label for accessibility
  const ariaLabel = text !== "" ? `${text.replace("%s", label)} - ${dictionary.openInPopin()}` : `${label} - ${dictionary.openInPopin()}`

  // Function to open share dialog
  const share = url => {
    process.browser && window?.open(url, "share", "height=500, width=500")
  }

  // Generate sharing URLs for each network
  const sharingUrl = env.sharing.reduce((acc, item) => {
    return { ...acc, [item.network]: `${item.url.replace("%u", currentUrl)}&text=${title}` }
  }, {})

  // Get share link name for analytics
  const shareLinkName = sharingUrl[label.toLowerCase()].split("/").slice(2)[0]

  // Share on family and model pages
  const url = currentUrl ? new URL(currentUrl) : null
  const isWatchesPage = url?.pathname.includes("/watches/") && !url?.pathname.includes("/new-watches") && !url?.pathname.includes("/configure")
  const isRetailerPage = url?.pathname.includes("/rolex-dealers/")
  const retailerType = url?.pathname.includes("rswi") ? "store" : "retailer"
  const productFamily = isWatchesPage ? url.pathname.split("/watches/")[1].split("/")[0] : ""
  const productRMC =
    isWatchesPage && /^m[a-z0-9]+\-[a-z0-9]+$/.test(url.pathname.split("/watches/")[1].split("/")[1]) ? url.pathname.split("/watches/")[1].split("/")[1] : ""

  let shareType = "generic-share"
  if (isRetailerPage) shareType = "retailer-share"
  if (productFamily) shareType = productRMC ? "model-share" : "family-share"
  if (url?.pathname.includes("find-rolex")) shareType = "generic-share"

  const data = {
    eventName: "share",
    eventType: "Click",
    shareInfo: {
      type: sharetype ?? shareType,
      shareLinkName,
      position: position,
      platform: label,
    },
  }

  // No products node on all models page and find rolex page
  if (productRMC)
    data.products = [
      {
        productCategory: productFamily,
        productID: productRMC,
        productQuantity: "1",
      },
    ]

  if (isRetailerPage) {
    const [split] = (url?.pathname?.split("/") || []).reverse()
    const did = split?.split("-")
    data.retailerInfo = {
      id: did?.[retailerType === "store" ? 0 : did.length - 1]?.toUpperCase(),
      type: retailerType,
      previewsComponent: "retailer page",
    }
  }

  async function triggerWa() {
    if (data.products) {
      const { price, currency } = await getPriceDatas({ rmc: productRMC, countryCode, locale, env })
      data.products[0].currency = currency
      data.products[0].productPrice = price
    }
    waTriggerEvent(data)
  }

  // Trigger analytics event and open share dialog on click
  return (
    <a
      onClick={() => {
        triggerWa()
        share(sharingUrl[label.toLowerCase()])
      }}
      aria-label={ariaLabel}
      href={sharingUrl[label.toLowerCase()]}
      rel='noopener noreferrer'
      target='_blank'
      title={ariaLabel}
    >
      <Icon type={label.toLowerCase()} size='20' />
      <VisuallyHidden>{label}</VisuallyHidden>
    </a>
  )
}

// Fallback component for when native sharing is not available
function ShareFallback({ label, text, networks: items, aria_label, inModal, url, wa }) {
  return (
    <Root inModal={inModal}>
      <span>{label}</span>
      <ul aria-label={aria_label}>
        {items.map((item, index) => (
          <li key={index}>
            <Item {...item} text={text} url={url} wa={wa} />
          </li>
        ))}
      </ul>
    </Root>
  )
}

// Component for native sharing
function ShareNative(props) {
  const console = useConsole()
  const { label, wa = {} } = props
  const { position = "pre-footer" } = wa
  const rbutton = useRef()
  const navigator = process.browser && global?.navigator

  const data = {
    eventName: "share",
    eventType: "Click",
    shareInfo: {
      type: "mobile-share",
      shareLinkName: "no linkName",
      position: position,
      platform: "no plateform",
    },
  }

  const { buttonProps } = useButton(
    {
      onPress: () => onClick(),
    },
    rbutton
  )

  const onClick = useCallback(() => {
    try {
      navigator?.share({
        title: document?.title,
        url: window?.location.href,
      })
      waTriggerEvent(data)
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <Root>
      <button {...buttonProps} ref={rbutton}>
        <Icon type='share' />
        <span>{label}</span>
      </button>
    </Root>
  )
}

// Main Share component
export default function Share(props) {
  const console = useConsole()
  const ssr = useSSR()
  const navigation = useNavigation()
  const locale = useLocale()
  const env = useEnv()
  const isNativeAllowed = process.browser && global?.navigator?.canShare && global?.matchMedia("(hover: none) and (pointer: coarse)").matches

  console.verbose("Share(%o)", props)

  const languageCode = locale?.current?.codes?.www
  const toInclude = languageCode === "zh-hans" || languageCode === "zh-hant" || env.cn

  const networks = toInclude ? props.networks : props.networks.filter(({ label }) => label.toLowerCase() !== "qq" && label.toLowerCase() !== "douban")

  // Don't render on 404 page
  if (navigation.slug === "/404") return

  // Render native sharing component if available
  if (isNativeAllowed) return <ShareNative {...props} networks={networks} />

  // Otherwise, render fallback component
  return !ssr && <ShareFallback {...props} networks={networks} />
}
