import { useConsole } from "contexts/Console"
import Button from "components/button/Button"
import { useXplit } from "./context"
import LauncherScreen from "./LauncherScreen"

export default function XplitCTA({ children, describedBy, label, icon, ...xplit_button }) {
  const console = useConsole()
  const { loadData, triggerer } = useXplit()

  console.verbose("••••• xplit_button", xplit_button)

  function onPress(e) {
    triggerer.current = e.target
    loadData()
  }

  return (
    <>
      <LauncherScreen />
      <Button {...xplit_button} icon={icon ?? 'plus'} describedBy={describedBy} onPress={onPress}>
        {children ?? label}
      </Button>
    </>
  )
}
