import { createContext, useContext } from "react"
import { useMotionValue } from "framer-motion"

import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"

export const Context = createContext()

export const FocusFeatureProvider = ({ children }) => {
  const console = useConsole()
  const env = useEnv()

  const curFrameNum = useMotionValue(-1)

  return <Context.Provider value={{ curFrameNum }}>{children}</Context.Provider>
}

export const useFocusFeature = () => useContext(Context)
