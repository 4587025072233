import { forwardRef, useRef } from "react"
import { useButton } from "@react-aria/button"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import * as button from "css/buttons"
import { legend100, normal } from "css/text"
import getMediaQuery from "css/breakpoints"

import { Icon } from "components/icon/Icon"

export const ButtonStyled = styled.button`
  /* Types */
  &.text {
    ${button.buttonText}
  }

  ${getMediaQuery("s", { max: true })} {
    &.launcherButton {
      position: absolute;
      bottom: 2.5rem;
      right: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      [dir="rtl"] & {
        transform: translate(50%, -50%);
      }
    }
  }

  &.filled {
    ${button.buttonFilled}
  }
  &.icon {
    ${button.buttonIcon}
  }
  &.inline {
    ${button.buttonInline}
  }

  /* Styles */
  &.green {
    ${button.green}
  }
  &.opaque-green {
    ${button.opaqueGreen}
  }
  &.opaque-white {
    ${button.opaqueWhite}
  }
  &.opaque-white-cfg {
    ${button.opaqueWhiteCfg}
  }
  &.opaque-black {
    ${button.opaqueBlack}
  }
  &.translucent-dark {
    ${button.translucentDark}
  }
  &.translucent-light {
    ${button.translucentLight}
  }

  /* Sizes */
  &.small {
    ${legend100}
    padding-inline: 1.5625rem;
  }
  &.smallnormal {
    ${legend100}
    ${normal}
  }
`

function Button(props, ref) {
  const { aria_label, aria_describedby, aria_hidden, children, className: c, disabled, icon, iconColor, style, tabIndex, ...rest } = props

  const console = useConsole()
  const rbutton = useRef()
  const { buttonProps, isPressed } = useButton(
    {
      isDisabled: disabled,
      ...rest,
    },
    ref || rbutton
  )

  const DEFAULT = "filled opaque-green"
  const className = [c ?? style ?? DEFAULT, icon === "chevron" && "reverseIcon", disabled && "disabled"].filter(Boolean).join(" ")

  const isInline = className => className?.includes("inline")

  console.verbose("Button(%o)", props)
  return (
    <ButtonStyled
      {...buttonProps}
      aria-label={aria_label}
      aria_describedby={aria_describedby}
      aria-hidden={aria_hidden}
      className={className}
      ref={ref || rbutton}
      iconColor={iconColor}
      style={{ opacity: isPressed ? 0.5 : 1 }}
      tabIndex={tabIndex}
    >
      {!isInline(className) && icon !== "none" && <Icon type={icon ?? "plus"} fill={iconColor} />}
      {children}
      {isInline(className) && icon !== "none" && <Icon type={icon ?? "plus"} fill={iconColor} />}
    </ButtonStyled>
  )
}

export default forwardRef(Button)
