import { forwardRef } from "react"
import styled from "@emotion/styled"

import getMediaQuery from "css/breakpoints"

import { useDictionary } from "contexts/Dictionary"

import Button from "components/button/Button"
import { useStory } from "components/stories/context"

const CloseButton = styled(Button)`
  grid-column: col 6 / span 1;
  grid-row: close;
  justify-self: end;
  align-self: center;
  position: absolute;
  pointer-events: all;

  margin-inline-end: calc(var(--height) / -2);

  ${getMediaQuery("m")} {
    grid-column: main-end;
    margin-inline-end: unset;
    margin-inline-start: calc((var(--grid-gap) / -2) - var(--height) / 2);
    justify-self: start;
  }
  ${getMediaQuery("l")} {
    grid-column: main-end;
    margin-inline-start: 0;
    justify-self: center;
  }
`

function Close({ }, ref) {
  const dictionary = useDictionary()

  const { storyOpener } = useStory()

  function closeStories() {
    storyOpener.set("")
  }

  return (
    <CloseButton ref={ref} onPress={closeStories} className='icon translucent-dark dark-theme' icon='close' aria_label={dictionary.ariaLabelCloseStories()} />
  )
}

export default forwardRef(Close)
