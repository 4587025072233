import { useState } from "react"
import { AnimatePresence, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import Canvas from "./Canvas"
import { useExp } from "./expcontext"
import Drawer from "./the-watch/Drawer"
import HubFeature from "./the-watch/HubFeature"
import Movement from "./the-movement/Movement"
import Pagination from "./ui/Pagination"
import TheEnd from "./the-end/TheEnd"
import ImgBg from "./ui/ImgBg"
import Foreground from "./ui/Foreground"

export default function GlobalContainer() {
  const console = useConsole()

  const { scenario, theWatchSequences } = useExp()

  const [scenarSelected, setScenarSelected] = useState(scenario.get())

  function onScenarioChange(s) {
    setScenarSelected(s)
  }
  useMotionValueEvent(scenario, "change", onScenarioChange)

  return scenarSelected >= 0 ? (
    <>
      <Drawer scenarSelected={scenarSelected} />
      <TheEnd scenarSelected={scenarSelected} />
      <Canvas />
      <HubFeature data={theWatchSequences[scenarSelected]} />
      <Movement scenarSelected={scenarSelected} />
      <Pagination scenarSelected={scenarSelected} />
    </>
  ) : null
}
